import { baseGet, basePost } from "./baseRequests";

// TODO: throw error instead of logging
// handle the error in the pages
export const payeeServices = {
  create: async ({ companyName, address, telephoneNumber, tinNumber }) => {
    const data = {
      company_name: companyName,
      address,
      telephone_number: telephoneNumber,
      tin_number: tinNumber,
    };

    try {
      const resp = await basePost({ url: "payees/", data });
      return resp.data;
    } catch (error) {
      console.log(error);
    }
  },
  createAgent: async ({ payeeId, companyName, address, countryOfOrigin }) => {
    const data = {
      name: companyName,
      address,
      country_of_origin: countryOfOrigin,
    };

    const resp = await basePost({ url: `payees/${payeeId}/agents/`, data });
    return resp.data;
  },
  createBank: async ({
    agent,
    agentId,
    swiftCode,
    countryOfOrigin,
    bankDetails,
  }) => {
    const data = {
      agent,
      swift_code: swiftCode,
      country_of_origin: countryOfOrigin,
      details: bankDetails,
    };

    agentId; // TODO: will be used in the url in the future

    const resp = await basePost({ url: "payee-agent-banks/", data });
    return resp.data;
  },
  getAgentListByPayeeId: async ({ id }) => {
    try {
      const resp = await baseGet({ url: `payees/${id}/agents/` });
      return resp.data;
    } catch (error) {
      console.log(error);
    }
  },
  // TODO: do this when bank endpoint is already nested
  // getBankListByAgentId: async (id) => {
  //   try {
  //     const resp = await baseGet({ url: `payees`})
  //   } catch (error) {

  //   }
  // },
  getDetails: async ({ id }) => {
    try {
      const resp = await baseGet({ url: `payees/${id}/` });
      return resp.data;
    } catch (error) {
      console.log(error);
    }
  },
  getList: async ({ params }) => {
    const resp = await baseGet({ url: "payees/", params });

    return resp.data;
  },
  getActivityLogs: async ({ id }) => {
    const resp = await baseGet({ url: `payees/${id}/logs/` });

    return resp.data;
  },
  getExternalLogs: async ({ id }) => {
    const resp = await baseGet({ url: `payees/${id}/external-logs/` });

    return resp.data;
  },
};
