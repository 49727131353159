<template>
  <LayoutComponent
    :user-name="userName"
    :user-groups="userGroups"
  >
    <div class="container">
      <PageTitle title="Export - Edit" />
      <ExportAirForm
        v-if="type === 'Air'"
        :client="client"
        :is-edit="true"
        :selectedTransaction="transaction"
        mode="export"
      />
      <ExportSeaForm
        v-else
        :client="client"
        :is-edit="true"
        :selected-transaction="transaction"
        :type="type"
        mode="export"
      />
    </div>
  </LayoutComponent>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRoute, useRouter } from 'vue-router';

import ExportAirForm from "@/components/ExportAirForm.vue";
import ExportSeaForm from "@/components/ExportSeaForm.vue";
import LayoutComponent from "@/components/LayoutComponent.vue";
import PageTitle from "@/components/PageTitle.vue";

import { rawGet, transportServices, userServices } from "@/services";
import { userAccess } from "@/utils/constants";
import { getUserId } from "@/utils/helpers";

const route = useRoute();
const router = useRouter();

const type = ref("");
const transaction = ref(null);
const client = ref(null);
const userName = ref(null);
const userGroups = ref([])

onMounted(async () => {
  const userId = getUserId()
  const resp = await userServices.getDetails(userId)
  userName.value = resp.last_name

  userGroups.value = resp.groups
  if (!resp.groups.some(role => userAccess.hasAccessToExportModify.includes(role))) {
    router.go(-1)
  }
  type.value = route.query?.type || "Air";
  const id = route.params.id;

  if (type.value === "Air") {
    transaction.value = await transportServices.getAirDetails({ id })
  } else {
    transaction.value = await transportServices.getSeaDetails({ id })
  }
  const consignee = await rawGet({ url: transaction.value.consignee });
  client.value = consignee;
  console.log(type.value)
})
</script>

<style scoped>
</style>
