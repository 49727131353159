<template>
  <div class="import-modal container">
    <div class="d-flex justify-content-between pb-5">
      <fas icon="fa-solid fa-xmark" class="detail-modal-fas" @click="toggleDetailModal" />
      <div class="modal-buttons mt-2">
        <button
          v-show="props.hasAccessToModify"
          type="button"
          class="btn btn-light me-3"
          @click="clickEdit"
        >
          Edit
        </button>
        <button
          v-show="props.hasAccessToModify"
          type="button"
          class="btn btn-light"
          @click="toggleDeleteModal"
        >
          Delete
        </button>
      </div>
    </div>
    <div class="mx-5">
      <h3 class="mt-4">Activity Logs</h3>
      <hr />
      <table class="table table-secondary detail-tables">
        <thead>
          <tr>
            <th scope="col">Date</th>
            <th scope="col">Event</th>
            <th scope="col">User</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="log in props.activityLogs" :key="log.url">
            <td scope="row">{{ dateFormatter(log.timestamp) }}</td>
            <td>{{ log.action_type?.toUpperCase() }}</td>
            <td>{{ nameFormatter(log.user) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import { defineEmits, defineProps } from "vue";

import { dateFormatter, nameFormatter } from "@/utils/helpers";

const props = defineProps([
  "activityLogs",
  "externalLogs",
  "hasAccessToModify",
  "type",
]);
const emit = defineEmits([
  "clickEdit",
  "toggleDeleteModal",
  "toggleDetailModal",
]);

const clickEdit = () => {
  emit("clickEdit");
};

const toggleDeleteModal = () => {
  emit("toggleDeleteModal");
};

const toggleDetailModal = () => {
  emit("toggleDetailModal");
};
</script>

<style scoped>
.import-modal {
  background-color: #adadad;
  position: fixed;
  overflow-y: scroll;
  top: 0;
  right: 0;
  height: 100%;
  width: 50%;
}

.detail-modal-fas {
  font-size: 40px;
  padding-left: 5px;
  padding-top: 5px;
  cursor: pointer;
}

.modal-buttons .btn {
  height: 40px;
  padding-left: 20px;
  padding-right: 20px;
}

.detail-tables th {
  background-color: #adadad;
}
</style>
