import { baseDownload, baseGet, basePost } from "./baseRequests";

export const transportServices = {
  // creating
  createAir: async ({ data }) => {
    const resp = await basePost({ url: "air-transport-transactions/", data });
    return resp.data;
  },
  createSea: async ({ data }) => {
    const resp = await basePost({ url: "sea-transport-transactions/", data });
    return resp.data;
  },
  // files
  downloadAirFile: async ({ documentID, id }) => {
    try {
      const resp = await baseDownload({
        url: `air-transport-transactions/${id}/documents/${documentID}/download/`,
      });
      return resp.data;
    } catch (error) {
      console.log(error);
    }
  },
  downloadSeaFile: async ({ documentID, id }) => {
    try {
      const resp = await baseDownload({
        url: `sea-transport-transactions/${id}/documents/${documentID}/download/`,
      });
      return resp.data;
    } catch (error) {
      console.log(error);
    }
  },
  getAirFiles: async ({ id }) => {
    try {
      const resp = await baseGet({
        url: `air-transport-transactions/${id}/documents/`,
        params: {
          is_active: true,
        },
      });
      return resp.data;
    } catch (error) {
      console.log(error);
    }
  },
  getSeaFiles: async ({ id }) => {
    try {
      const resp = await baseGet({
        url: `sea-transport-transactions/${id}/documents/`,
        params: {
          is_active: true,
        },
      });
      return resp.data;
    } catch (error) {
      console.log(error);
    }
  },
  // lists
  getAirList: async ({ params }) => {
    try {
      const resp = await baseGet({
        url: "air-transport-transactions/",
        params,
      });
      return resp.data;
    } catch (error) {
      console.log(error);
    }
  },
  getSeaList: async ({ params }) => {
    try {
      const resp = await baseGet({
        url: "sea-transport-transactions/",
        params,
      });

      return resp.data;
    } catch (error) {
      console.log(error);
    }
  },
  // logging
  getAirActivityLogs: async ({ id, params }) => {
    try {
      const resp = await baseGet({
        url: `air-transport-transactions/${id}/logs/`,
        params,
      });

      return resp.data;
    } catch (error) {
      console.log(error);
    }
  },
  getAirExternalLogs: async ({ id, params }) => {
    try {
      const resp = await baseGet({
        url: `air-transport-transactions/${id}/external-logs/`,
        params,
      });

      return resp.data;
    } catch (error) {
      console.log(error);
    }
  },
  getSeaActivityLogs: async ({ id, params }) => {
    try {
      const resp = await baseGet({
        url: `sea-transport-transactions/${id}/logs/`,
        params,
      });

      return resp.data;
    } catch (error) {
      console.log(error);
    }
  },
  getSeaExternalLogs: async ({ id, params }) => {
    try {
      const resp = await baseGet({
        url: `sea-transport-transactions/${id}/external-logs/`,
        params,
      });

      return resp.data;
    } catch (error) {
      console.log(error);
    }
  },
  // details
  getAirDetails: async ({ id }) => {
    try {
      const resp = await baseGet({ url: `air-transport-transactions/${id}/` });
      return resp.data;
    } catch (error) {
      console.log(error);
    }
  },
  getSeaDetails: async ({ id }) => {
    try {
      const resp = await baseGet({ url: `sea-transport-transactions/${id}/` });
      return resp.data;
    } catch (error) {
      console.log(error);
    }
  },
  // submitting
  submitAir: async ({ id }) => {
    try {
      const resp = await basePost({
        url: `air-transport-transactions/${id}/submit/`,
      });
      return resp.data;
    } catch (error) {
      console.log(error);
    }
  },
  submitSea: async ({ id }) => {
    try {
      const resp = await basePost({
        url: `sea-transport-transactions/${id}/submit/`,
      });
      return resp.data;
    } catch (error) {
      console.log(error);
    }
  },
  // others
  getAirFreightTypes: async () => {
    try {
      const resp = await baseGet({ url: "air-transport-freight-types/" });
      return resp.data.results;
    } catch (error) {
      console.log(error);
    }
  },
  getAirPackagingTypes: async () => {
    try {
      const resp = await baseGet({ url: "air-transport-packaging-types/" });
      return resp.data.results;
    } catch (error) {
      console.log(error);
    }
  },
  getCategories: async () => {
    try {
      const resp = await baseGet({ url: "transport-transaction-categories/" });
      return resp.data.results;
    } catch (error) {
      console.log(error);
    }
  },
  getContainerSizes: async () => {
    try {
      const resp = await baseGet({ url: "sea-transport-container-sizes/" });
      return resp.data.results;
    } catch (error) {
      console.log(error);
    }
  },
  getContainerTypes: async () => {
    try {
      const resp = await baseGet({ url: "sea-transport-container-types/" });
      return resp.data.results;
    } catch (error) {
      console.log(error);
    }
  },
  getIncoterms: async () => {
    try {
      const resp = await baseGet({ url: "incoterms/" });
      return resp.data.results;
    } catch (error) {
      console.log(error);
    }
  },
  getModeOfShipments: async () => {
    try {
      const resp = await baseGet({ url: "sea-transport-modes-of-shipment/" });
      return resp.data.results;
    } catch (error) {
      console.log(error);
    }
  },
};
