<template>
  <div>
    <ErrorMessage
      v-show="hasError"
      @dismiss-alert="handleOnDismissalert"
    >
      {{ errorMessage }}
    </ErrorMessage>
    <form>
      <div class="mb-3">
        <label for="firstName" class="form-label">First Name</label>
        <input
          type="text"
          class="form-control"
          id="firstName"
          aria-label="First Name"
          v-model="user.first_name"
        >
        <label for="lastName" class="form-label">Last Name</label>
        <input
          type="text"
          class="form-control"
          id="lastName"
          aria-label="Last Name"
          v-model="user.last_name"
        >
        <label for="email" class="form-label">Email</label>
        <input
          type="text"
          class="form-control case-not-forced text-lowercase"
          id="email"
          aria-label="Email"
          v-model="user.email"
        >
        <label for="phoneNumber" class="form-label">Phone Number</label>
        <input
          type="text"
          class="form-control"
          id="phoneNumber"
          aria-label="Phone Number"
          v-model="user.phone_number"
        >
        <label
          v-if="mode === 'create'"
          for="password"
          class="form-label"
        >
          Password
        </label>
        <input
          v-if="mode === 'create'"
          type="password"
          class="form-control case-not-forced"
          id="password"
          aria-label="Password"
          v-model="user.password"
        >
        <label
          v-if="mode === 'create'"
          for="confirmPassword"
          class="form-label"
        >
          Confirm Password
        </label>
        <input
          v-if="mode === 'create'"
          type="password"
          class="form-control case-not-forced"
          id="confirmPassword"
          aria-label="Confirm Password"
          v-model="user.confirm_password"
        >
        <label for="roles" class="form-label">Roles</label>
        <select
          v-model="user.groups"
          class="form-select"
          id="roles"
          aria-label="Roles"
        >
          <option
            v-for="group in props.userGroups"
            :value="group.name"
            :key="group.url"
          >
            {{ group.name }}
          </option>
        </select>
        <label for="isActive" class="form-label">Is Active</label>
        <select
          v-model="user.is_active"
          class="form-select"
          id="isActive"
          aria-label="Is Active"
        >
          <option
            v-for="option in isAciveOptions"
            :value="option.value"
            :key="option.value"
          >
            {{ option.label }}
          </option>
        </select>
      </div>
      <button
        type="button"
        class="btn button-primary"
        @click="handleOnSubmit"
      >
        Submit
      </button>
    </form>
  </div>
</template>

<script setup>
import { defineProps, ref, watch } from "vue";
import { useRouter } from 'vue-router';

import ErrorMessage from "@/components/ErrorMessage.vue";

import { rawPatch, userServices } from "@/services";

const router = useRouter();
const props = defineProps(["isAdmin", "mode", "userDetails", "userGroups"]);

const isAciveOptions = [
  {
    label: "YES",
    value: true,
  },
  {
    label: "NO",
    value: false,
  },
]

const user = ref({
  email: "",
  first_name: "",
  last_name: "",
  phone_number: "",
  groups: "",
  password: "",
  confirm_password: "",
  is_active: true,
})
const hasError = ref(false);
const errorMessage = ref("")


const handleOnDismissalert = () => {
  hasError.value = false;
  errorMessage.value = "";
}

const handleOnSubmit = async () => {
  try {
    let data = structuredClone({
      email: user.value.email?.toLowerCase() || "",
      first_name: user.value.first_name?.toUpperCase() || "",
      last_name: user.value.last_name?.toUpperCase() || "",
      phone_number: user.value.phone_number?.toUpperCase() || "",
      is_active: user.value.is_active,
      groups: [user.value.groups]
    })
    if (props.mode === "create") {
      data = {
        ...data,
        password: user.value.password,
        confirm_password: user.value.confirm_password,
      }
      await userServices.create(data)
    } else {
      await rawPatch({
        url: props.userDetails.url,
        data,
      })
    }

    let url = "/users"
    if (props.isAdmin) {
      url = "/admin/users"
    }
    router.push(url)
    
  } catch (error) {
    hasError.value = true

    if (error.details.data.email) {
      errorMessage.value = error.details.data.email[0]
    } else if (error.details.data.password) {
      errorMessage.value = error.details.data.password.join("; ")
    } else {
      errorMessage.value = "An unexpected error occurred please try again"
    }
  }
}

watch(
  () => props.userDetails,
  (userDetails) => {
    user.value = {
      email: userDetails.email,
      first_name: userDetails.first_name,
      last_name: userDetails.last_name,
      phone_number: userDetails.phone_number,
      groups: userDetails.groups,
      password: "",
      confirm_password: "",
      is_active: userDetails.is_active,
    }
  }
)
</script>

<style scoped>
input:not(.case-not-forced) {
  text-transform: uppercase;
}
</style>