import { createApp } from "vue";
import { createRouter, createWebHistory } from "vue-router";
import "bootstrap/dist/css/bootstrap.css";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

import App from "./App.vue";
import routes from "./routes";
import FontAwesomeIcon from "./fontAwesomeLibrary";

const router = createRouter({
  history: createWebHistory(),
  routes,
});

createApp(App)
  .use(router)
  .component("fas", FontAwesomeIcon)
  .component("VueDatePicker", VueDatePicker)
  .mount("#app");

import "bootstrap/dist/js/bootstrap.js";
