<template>
  <div class="d-flex align-items-center mb-3">
    <button
      class="btn button-primary me-3 custom-back"
      @click="onClickBack"
    >
      &#60; Back
    </button>
    <h2 class="mb-0">{{ props.title }}</h2>
  </div>
</template>

<script setup>
import { defineProps } from "vue";
import { useRoute, useRouter } from 'vue-router';

const props = defineProps(["title"]);
const route = useRoute();
const router = useRouter();

const onClickBack = () => {
  const currentUrl = route.path.slice(1, route.path.length)
  const index = currentUrl.indexOf("/")

  if (index === -1) {
    router.push({ path: "/" })
  } else {
    router.push(`/${currentUrl.slice(0, index)}`)
  }
}
</script>

<style scoped>
.custom-back {
  height: 30px;
  font-size: .7em;
}
</style>
