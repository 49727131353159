import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faPenToSquare,
  faTrash,
  faUser,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

// add font awesome icons to library here
library.add(faPenToSquare, faTrash, faUser, faXmark);

export default FontAwesomeIcon;
