import axios from "axios";
import { useRouter } from "vue-router";

const baseUrl = process.env.VUE_APP_API_URL;
const authUrl = process.env.VUE_APP_OAUTH_URL;

const baseRequest = async ({ method, url, params, data, responseType }) => {
  const cookie = document.cookie.split(";").map((c) => c.trim());
  const router = useRouter();
  try {
    // Get bearer token from cookies
    // Otherwise throw an error to try using refresh token
    const tempBearer = cookie.filter((c) => c.includes("BearerToken"));
    let bearerToken;
    if (tempBearer.length) {
      bearerToken = tempBearer[0].slice(12, tempBearer[0].length);
    } else {
      throw {
        response: {
          status: 401,
        },
      };
    }

    // Make the request
    return await axios({
      method,
      url,
      params,
      data,
      headers: {
        Authorization: `Bearer ${bearerToken}`,
      },
      responseType,
    });
  } catch (error) {
    // Get refresh token from cookies
    // Otherwise redirect to /login
    const tempRefresh = cookie.filter((c) => c.includes("RefreshToken"));
    let refreshToken;

    // If response is 401, token is invalid
    if (error.response.status === 401) {
      if (tempRefresh.length) {
        refreshToken = tempRefresh[0].slice(13, tempRefresh[0].length);
      } else {
        router.push("/login");
        return;
      }
    } else {
      const newError = new Error();
      Object.assign(newError, { details: error.response });
      throw newError;
    }

    // Get a new token using the refresh token
    try {
      const data = {
        client_id: process.env.VUE_APP_CLIENT_ID,
        grant_type: "refresh_token",
        refresh_token: refreshToken,
      };

      const tokenResp = await baseAuth({
        url: "token/",
        data,
      });
      // delete previous cookies
      document.cookie =
        "BearerToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie =
        "RefreshToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie = "User=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie = `BearerToken=${tokenResp.data.access_token}; path=/;`;
      document.cookie = `RefreshToken=${tokenResp.data.refresh_token}; path=/;`;
      document.cookie = `User=${tokenResp.data.user}; path=/;`;

      // Make new request using the new token
      return await axios({
        method,
        url,
        params,
        data,
        headers: {
          Authorization: `Bearer ${tokenResp.data.access_token}`,
        },
      });
    } catch (error) {
      // Failed to get new access token using refresh token
      // Reset tokens
      document.cookie =
        "BearerToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie =
        "RefreshToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      document.cookie = "User=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
      // redirect to /login
      router.push("/login");
      throw new Error("Invalid tokens");
    }
  }
};

export const baseDelete = ({ url }) => {
  return baseRequest({
    method: "delete",
    url: baseUrl + url,
  });
};

export const baseDownload = ({ url }) => {
  return baseRequest({
    method: "get",
    url: baseUrl + url,
    responseType: "blob",
  });
};

export const baseGet = ({ url, params }) => {
  return baseRequest({
    method: "get",
    url: baseUrl + url,
    params: params ? params : {},
  });
};

export const basePost = ({ url, data }) => {
  return baseRequest({
    method: "post",
    url: baseUrl + url,
    data,
  });
};

export const baseUpdate = ({ url, data }) => {
  return baseRequest({
    method: "patch",
    url: baseUrl + url,
    data,
  });
};

export const baseAuth = ({ url, data }) => {
  return axios({
    method: "post",
    url: authUrl + url,
    data,
  });
};

export const rawGet = async ({ url }) => {
  const resp = await baseRequest({
    method: "get",
    url,
  });

  return resp.data;
};

export const rawPatch = async ({ url, data }) => {
  const resp = await baseRequest({
    method: "patch",
    data,
    url,
  });

  return resp.data;
};
