<template>
  <LayoutComponent
    :user-name="userName"
    :user-groups="currentUserGroups"
  >
    <div class="container">
      <PageTitle title="User Access - Create" />
      <UserForm
        is-admin="true"
        mode="create"
        :user-details="userDetails"
        :user-groups="userGroups"
      />
    </div>
  </LayoutComponent>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";

import LayoutComponent from "@/components/LayoutComponent.vue";
import PageTitle from "@/components/PageTitle.vue";
import UserForm from "@/components/UserForm.vue";

import { userServices } from "@/services";
import { fetchAll, getUserId } from "@/utils/helpers";

const router = useRouter();

const userGroups = ref([]);
const userDetails = ref({
  email: "",
  first_name: "",
  last_name: "",
  phone_number: "",
  groups: "",
});
const userName = ref(null);
const currentUserGroups = ref([])

onMounted(async () => {
  const userId = getUserId()
  const resp = await userServices.getDetails(userId)
  userName.value = resp.last_name

  currentUserGroups.value = resp.groups
  if (!resp.groups.includes("Admin")) {
    router.go(-1)
  }

  const groupsResp = await userServices.getGroupList();

  let tempGroups = groupsResp.results
  if (groupsResp.next) {
    const nextRes = await fetchAll(groupsResp.next)
    tempGroups = [...tempGroups, ...nextRes]
  }
  userGroups.value = tempGroups
})
</script>

<style scoped>
.bl-search {
  display: inline-block;
}

.date-search {
  display: inline-block;
  width: 30%;
  margin: 0 10px;
}

.link {
  color: #457b9d;
  text-decoration: underline;
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}
</style>
