import { baseDelete, baseGet, basePost } from "./baseRequests";

export const clientServices = {
  create: async ({ data }) => {
    const resp = await basePost({ url: "clients/", data });
    return resp.data;
  },
  createClientRate: async ({
    client,
    description,
    modeOfShipment,
    rate,
    unit,
    taxable,
  }) => {
    const index = client.indexOf("clients/") + 8;
    const id = client.slice(index, -1);

    const resp = await basePost({
      url: `clients/${id}/rates/`,
      data: {
        client,
        description: description,
        mode_of_shipment: modeOfShipment,
        rate,
        unit,
        is_taxable: taxable,
      },
    });
    return resp.data;
  },
  deleteClientRate: async ({ clientId, id }) => {
    await baseDelete({
      url: `clients/${clientId}/rates/${id}/`,
    });
  },
  getDetails: async ({ id }) => {
    const resp = await baseGet({ url: `clients/${id}/` });
    return resp.data;
  },
  getList: async ({ params }) => {
    const resp = await baseGet({ url: "clients/", params });

    return resp.data;
  },
  getModeOfShipments: async () => {
    const resp = await baseGet({ url: "client-mode-of-shipments/" });
    return resp.data.results;
  },
  getRates: async ({ id }) => {
    const resp = await baseGet({ url: `clients/${id}/rates/` });
    return resp.data;
  },
  getRateUnits: async () => {
    const resp = await baseGet({ url: "client-rate-units/" });
    return resp.data.results;
  },
  getActivityLogs: async ({ id }) => {
    const resp = await baseGet({ url: `clients/${id}/logs/` });

    return resp.data;
  },
  getExternalLogs: async ({ id }) => {
    const resp = await baseGet({ url: `clients/${id}/external-logs/` });

    return resp.data;
  },
};
