import AmendmentApprovalsDashboardPage from "@/views/AmendmentApprovalsDashboardPage.vue";
import BillingDashboardPage from "@/views/BillingDashboardPage.vue";
import BillingEditPage from "@/views/BillingEditPage.vue";
import BillingViewPage from "@/views/BillingViewPage.vue";
import DependentBrokerageDashboardPage from "@/views/DependentBrokerageDashboardPage.vue";
import DependentBrokerageCreatePage from "@/views/DependentBrokerageCreatePage.vue";
import DependentBrokerageViewPage from "@/views/DependentBrokerageViewPage.vue";
import ClientCreatePage from "@/views/ClientCreatePage.vue";
import ClientEditPage from "@/views/ClientEditPage.vue";
import DeleteApprovalsDashboardPage from "@/views/DeleteApprovalsDashboardPage.vue";
import ExportCreatePage from "@/views/ExportCreatePage.vue";
import ExportDashboardPage from "@/views/ExportDashboardPage.vue";
import ExportEditPage from "@/views/ExportEditPage.vue";
import ExportViewPage from "@/views/ExportViewPage.vue";
import ImportCreatePage from "@/views/ImportCreatePage.vue";
import ImportDashboardPage from "@/views/ImportDashboardPage.vue";
import ImportEditPage from "@/views/ImportEditPage.vue";
import ImportViewPage from "@/views/ImportViewPage.vue";
import IndependentBrokerageDashboardPage from "@/views/IndependentBrokerageDashboardPage.vue";
import IndependentBrokerageCreatePage from "@/views/IndependentBrokerageCreatePage.vue";
import IndependentBrokerageEditPage from "@/views/IndependentBrokerageEditPage.vue";
import IndependentBrokerageViewPage from "@/views/IndependentBrokerageViewPage.vue";
import LandingPage from "@/views/LandingPage.vue";
import LoginPage from "@/views/LoginPage.vue";
import MembersDashboardPage from "@/views/MembersDashboardPage.vue";
import PayeeCreatePage from "@/views/PayeeCreatePage.vue";
import PayeeEditPage from "@/views/PayeeEditPage.vue";
import UserCreatePage from "@/views/UserCreatePage.vue";
import UserEditPage from "@/views/UserEditPage.vue";
import UserDashboardPage from "@/views/UserDashboardPage.vue";

const routes = [
  { path: "/", component: LandingPage },
  { path: "/admin/users", component: UserDashboardPage },
  { path: "/admin/users/create", component: UserCreatePage },
  { path: "/admin/users/:id/edit", component: UserEditPage },
  { path: "/approvals/amendments", component: AmendmentApprovalsDashboardPage },
  { path: "/approvals/deletions", component: DeleteApprovalsDashboardPage },
  { path: "/billings", component: BillingDashboardPage },
  { path: "/billings/:id/edit", component: BillingEditPage },
  { path: "/billings/:id/view", component: BillingViewPage },
  { path: "/brokerages/dependent", component: DependentBrokerageDashboardPage },
  {
    path: "/brokerages/dependent/create",
    component: DependentBrokerageCreatePage,
  },
  {
    path: "/brokerages/dependent/:id/view",
    component: DependentBrokerageViewPage,
  },
  {
    path: "/brokerages/independent",
    component: IndependentBrokerageDashboardPage,
  },
  {
    path: "/brokerages/independent/create",
    component: IndependentBrokerageCreatePage,
  },
  {
    path: "/brokerages/independent/:id/edit",
    component: IndependentBrokerageEditPage,
  },
  {
    path: "/brokerages/independent/:id/view",
    component: IndependentBrokerageViewPage,
  },
  { path: "/exports", component: ExportDashboardPage },
  { path: "/exports/create", component: ExportCreatePage },
  { path: "/exports/:id/edit", component: ExportEditPage },
  { path: "/exports/:id/view", component: ExportViewPage },
  { path: "/imports", component: ImportDashboardPage },
  { path: "/imports/create", component: ImportCreatePage },
  { path: "/imports/:id/edit", component: ImportEditPage },
  { path: "/imports/:id/view", component: ImportViewPage },
  { path: "/login", component: LoginPage },
  { path: "/members", component: MembersDashboardPage },
  { path: "/members/clients/create", component: ClientCreatePage },
  { path: "/members/clients/:id/edit", component: ClientEditPage },
  { path: "/members/payees/create", component: PayeeCreatePage },
  { path: "/members/payees/:id/edit", component: PayeeEditPage },
];

export default routes;
