<template>
  <LayoutComponent
    :user-name="userName"
    :user-groups="userGroups"
  >
    <div class="container">
      <PageTitle title="Import - Create" />
      <div v-if="!initialDataSubmitted">
        <form>
          <template v-if="initialData.type !== 'Air'">
            <div v-for="tp in types" class="form-check" :key="tp">
              <input class="form-check-input" type="radio" name="flexRadioDefault" :id="tp" @change="onChangeSelected(tp)">
              <label class="form-check-label" :for="tp">{{ tp }}</label>
            </div>
          </template>
          <label for="client" class="form-label">Client</label>
          <select
            class="form-select"
            id="client"
            aria-label="Client"
            v-model="initialData.client"
          >
            <option v-for="client in clients" :key="client.url" :value="client">{{ client.company_name }}</option>
          </select>
        </form>
        <button type="button" class="btn btn-success mt-3" @click="handleOnClickNext" :disabled="isButtonDisabled">Next</button>
      </div>
      <div v-else>
        <ImportAirForm 
          v-if="initialData.type === 'Air'"
          :type="initialData.type"
          :client="initialData.client"
          :selectedTransaction = "{}"
          mode="import"
        />
        <ImportSeaForm
          v-else
          :type="initialData.type"
          :client="initialData.client"
          :selectedTransaction = "{}"
          mode="import"
        />
      </div>
    </div>
  </LayoutComponent>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

import ImportAirForm from "@/components/ImportAirForm.vue";
import ImportSeaForm from "@/components/ImportSeaForm.vue";
import LayoutComponent from "@/components/LayoutComponent.vue";
import PageTitle from "@/components/PageTitle.vue";

import { clientServices, userServices } from "@/services";
import { userAccess } from "@/utils/constants";
import { fetchAll, getUserId } from "@/utils/helpers";

const types = ["LCL", "FCL", "Consol"];
const router = useRouter();
const route = useRoute();

const clients = ref([]);
const initialDataSubmitted = ref(false);
const initialData = ref({
  type: "Air",
  client: {},
})
const userName = ref(null);
const userGroups = ref([])

const isButtonDisabled = computed(() => (
  initialData.value.type === '' || JSON.stringify(initialData.value.client) === '{}'
))

const onChangeSelected = (type) => {
  initialData.value.type = type;
}

const handleOnClickNext = () => {
  initialDataSubmitted.value = true;
}

onMounted(async () => {
  const userId = getUserId()
  const resp = await userServices.getDetails(userId)
  userName.value = resp.last_name

  userGroups.value = resp.groups
  if (!resp.groups.some(role => userAccess.hasAccessToImportModify.includes(role))) {
    router.go(-1)
  }
  const type = route.query.type

  if (type !== "Air") {
    initialData.value.type = ""
  }
  const clientsResp = await clientServices.getList({ params: { is_active: true } });
  
  let tempClients = clientsResp.results
  if (clientsResp.next) {
    const nextRes = await fetchAll(clientsResp.next)
    tempClients = [...tempClients, ...nextRes]
  }
  clients.value = tempClients
})
</script>

<style scoped>
</style>
