<template>
  <div class="col-auto col-md-3 col-xl-2 px-sm-2 px-0 sb-container bg-green-primary">
    <div
      class="d-flex flex-column align-items-center align-items-sm-start px-3 pt-2 text-white min-vh-100"
    >
      <a
        href="/"
        class="d-flex align-items-center pb-3 mb-md-0 me-md-auto text-white text-decoration-none sb-logo"
      >
        <span v-if="showIcon">K</span>
        <span class="d-none d-sm-inline">KRGBX</span>
      </a>
      <ul
        class="nav nav-pills flex-column mb-sm-auto mb-0 align-items-center align-items-sm-start sb-list w-100"
        id="menu"
      >
        <li class="nav-item w-100">
          <a href="/members" class="nav-link align-middle px-0">
            <span v-if="showIcon">M</span>
            <span class="ms-1 d-none d-sm-inline">Members</span>
          </a>
          <a
            v-show="hasAccessToImport"
            href="/imports"
            class="nav-link align-middle px-0"
          >
            <span v-if="showIcon">I</span>
            <span class="ms-1 d-none d-sm-inline">Imports</span>
          </a>
          <a
            v-show="hasAccessToExport"
            href="/exports"
            class="nav-link align-middle px-0"
          >
            <span v-if="showIcon">E</span>
            <span class="ms-1 d-none d-sm-inline">Exports</span>
          </a>
          <a
            v-show="hasAccessToBilling"
            href="/billings"
            class="nav-link align-middle px-0"
          >
            <span v-if="showIcon">B</span>
            <span class="ms-1 d-none d-sm-inline">Billings</span>
          </a>
        </li>
        <li v-show="hasAccessToBrokerage">
          <a
            href="#brokerageMenu"
            data-bs-toggle="collapse"
            class="nav-link px-0 align-middle"
            >
              <span v-if="showIcon">A</span>
              <span class="ms-1 d-none d-sm-inline">Brokerages</span> 
          </a>
          <ul
            class="collapse show nav flex-column ms-1"
            id="brokerageMenu"
            data-bs-parent="#brokerageMenu"
          >
            <li class="w-100">
              <a href="/brokerages/dependent" class="nav-link px-0">
                <span v-if="showIcon">DB</span>
                <span class="ms-1 d-none d-sm-inline">Dependent Brokerages</span>
              </a>
            </li>
            <li class="w-100">
              <a href="/brokerages/independent" class="nav-link px-0">
                <span v-if="showIcon">IB</span>
                <span class="ms-1 d-none d-sm-inline">Independent Brokerages</span>
              </a>
            </li>
          </ul>
        </li>
        <li v-show="hasAccesToAdmin">
          <a
            href="#adminMenu"
            data-bs-toggle="collapse"
            class="nav-link px-0 align-middle"
            >
              <span v-if="showIcon">A</span>
              <span class="ms-1 d-none d-sm-inline">Admin</span> 
          </a>
          <ul
            class="collapse show nav flex-column ms-1"
            id="adminMenu"
            data-bs-parent="#adminMenu"
          >
            <li class="w-100">
              <a href="/approvals/amendments" class="nav-link px-0">
                <span v-if="showIcon">Ar</span>
                <span class="ms-1 d-none d-sm-inline">Amendment Requests</span>
              </a>
            </li>
            <li class="w-100">
              <a href="/approvals/deletions" class="nav-link px-0">
                <span v-if="showIcon">Dr</span>
                <span class="ms-1 d-none d-sm-inline">Delete Requests</span>
              </a>
            </li>
            <li class="w-100">
              <a href="/admin/users" class="nav-link px-0">
                <span v-if="showIcon">Ua</span>
                <span class="ms-1 d-none d-sm-inline">User Access</span>
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
// source https://dev.to/codeply/bootstrap-5-sidebar-examples-38pb
import { computed, defineProps, nextTick, onMounted, ref } from "vue";

import { userAccess } from "@/utils/constants";

const showIcon = ref(false);
const props = defineProps(["userGroups"])

const hasAccesToAdmin = computed(() => {
  return props.userGroups.includes('Admin')
})

const hasAccessToImport = computed(() => {
  return props.userGroups.some(role => userAccess.hasAccessToImport.includes(role))
})

const hasAccessToExport = computed(() => {
  return props.userGroups.some(role => userAccess.hasAccessToExport.includes(role))
})

const hasAccessToBilling = computed(() => {
  return props.userGroups.some(role => userAccess.hasAccessToBilling.includes(role))
})

const hasAccessToBrokerage = computed(() => {
  return props.userGroups.some(role => userAccess.hasAccessToBrokerage.includes(role))
})

const onResize = () => {
  if (window.innerWidth < 576) {
    showIcon.value = true;
  } else {
    showIcon.value = false;
  }
};

onMounted(() => {
  nextTick(() => {
    window.addEventListener("resize", onResize);
  });
});
</script>

<style scoped>
.sb-container .sb-logo {
  font-size: 38px;
}

.sb-container .sb-list a {
  color: white;
}

.sb-container .sb-list a:hover {
  color: #79ffbf;
}
</style>
