<template>
  <LayoutComponent
    :user-name="userName"
    :user-groups="userGroups"
  >
    <div class="container">
      <PageTitle title="Billing - Dashboard" />
      <div class="py-3">
        <span class="fs-4 pe-3">Search</span>
        <div class="form-floating w-25 bl-search">
          <input
            type="text"
            class="form-control"
            id="BLSearch"
            placeholder="BL No."
            v-model="stringSearch"
          />
          <label for="BLSearch">Search Bills</label>
        </div>
        <VueDatePicker
          v-model="dateSearch"
          range
          id="clientDateSearch"
          aria-label="Filter Client By Date"
          class="date-search"
          :enable-time-picker="false"
        />
        <button
          type="button"
          class="btn btn-success"
          @click="onClickSearch"
        >
          Search
        </button>
      </div>
      <button type="button" class="btn button-primary">Download</button>
      <table class="table table-light table-striped table-bordered mt-4">
        <thead>
          <tr>
            <th scope="col">Company Name</th>
            <th scope="col">Salesman</th>
            <th scope="col">Vessel / Flight Number</th>
            <th scope="col">MBL / MAWB</th>
            <th scope="col">HBL / HAWB</th>
            <th scope="col">Reference Number</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="billingDetail in billingDetailList"
            :key="billingDetail.url"
            @click="onClickBilling(billingDetail)"
          >
            <th scope="row" class="link">{{ billingDetail.billed_to }}</th>
            <td>{{ billingDetail.salesman }}</td>
            <td>{{ billingDetail.transaction.vessel_number || billingDetail.transaction.flight_number }}</td>
            <td>{{ billingDetail.transaction.master_bill_of_lading || billingDetail.transaction.master_airway_bill_number }}</td>
            <td>{{ billingDetail.transaction.house_bill_of_lading || billingDetail.transaction.house_airway_bill_number }}</td>
            <td>{{ billingDetail.soa_reference_number }}</td>
            <td>{{ statusFormatter(billingDetail.status) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <DetailModal
      v-if="showDetailModal"
      :activityLogs="activityLogs"
      :externalLogs="externalLogs"
      :billing="selectedBilling"
      @click-edit="onClickEdit"
      @click-view="onClickView"
      @toggle-detail-modal="toggleDetailModal"
    />
    <AmendmentModal
      v-show="showAmendmentModal"
      @cancel-modal="cancelAmendmentModal"
      @confirm-modal="confirmAmendmentModal"
    />
  </LayoutComponent>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { amendmentServices, billServices, rawGet, userServices } from "@/services";

import AmendmentModal from "@/components/AmendmentModal.vue";
import DetailModal from "@/components/BillingDashboard/DetailModal.vue";
import LayoutComponent from "@/components/LayoutComponent.vue";
import PageTitle from "@/components/PageTitle.vue";

import { userAccess } from "@/utils/constants";
import { fetchAll, getIdFromURL, getUserId, statusFormatter } from "@/utils/helpers";

const router = useRouter();
const showAmendmentModal = ref(false);
const showDetailModal = ref(false);
const billingDetailList = ref([]);
const selectedBilling = ref(null);
const stringSearch = ref("");
const dateSearch = ref(null);
const activityLogs = ref([]);
const externalLogs = ref([]);
const userName = ref(null);
const userGroups = ref([])

// detail modal
const onClickBilling = async (billing) => {
  const id = getIdFromURL(billing.url)
  const activityResp = await billServices.getActivityLogs({ id });
  let tempActivity = activityResp.results

  if (activityResp.next) {
    const nextRes = await fetchAll(activityResp.next)
    tempActivity = [...tempActivity, ...nextRes]
  }
  activityLogs.value = tempActivity

  const externalResp = await billServices.getExternalLogs({ id });
  let tempExternal = externalResp.results
  if (externalResp.next) {
    const nextRes = await fetchAll(externalResp.next)
    tempExternal = [...tempExternal, ...nextRes]
  }
  externalLogs.value = tempExternal

  if (activityLogs.value.length) {
    for (let index = 0; index < activityLogs.value.length; index++) {
      const log = activityLogs.value[index];

      log.user = await rawGet({ url: log.user })
    }
  }

  const created_by = billing.created_by ?
    await rawGet({ url: billing.created_by })
    : null
  const submitted_by = billing.submitted_by ?
    await rawGet({ url: billing.submitted_by })
    : null
  const updated_by = billing.updated_by ?
    await rawGet({ url: billing.updated_by })
    : null
  selectedBilling.value = {
    ...billing,
    created_by,
    submitted_by,
    updated_by
  }
  toggleDetailModal()
}

const onClickView = () => {
  const id = getIdFromURL(selectedBilling.value?.url)
  router.push(`/billings/${id}/view`);
};

const toggleDetailModal = () => {
  showDetailModal.value = !showDetailModal.value;
};

// amendments
const cancelAmendmentModal = () => {
  showAmendmentModal.value = !showAmendmentModal.value;
}

const confirmAmendmentModal = async () => {
  await amendmentServices.create({
    url: selectedBilling.value?.url,
  })
  showAmendmentModal.value = !showAmendmentModal.value
}

const onClickEdit = () => {
  const id = getIdFromURL(selectedBilling.value?.url)
  const status = selectedBilling.value?.status
  if (status === "in_progress" || status === "for_amendment") {
    router.push(`/billings/${id}/edit`);
  } else if (status === "submitted") {
    showAmendmentModal.value = !showAmendmentModal.value;
  }
  router.push(`/billings/${id}/edit`);
};

// fetching/searching lists
const fetchList = async (params) => {
  const billResp = await billServices.getList({ params });
  for (let index = 0; index < billResp.results.length; index++) {
    const tempTransaction = billResp.results[index].transaction
    const transactionResp = await rawGet({ url: tempTransaction });

    if (tempTransaction.includes("brokerage")) {
      billResp.results[index].transaction =
        transactionResp.transport_transaction
    } else {
      billResp.results[index].transaction = transactionResp
    }
  }
  billingDetailList.value = billResp.results || [];
}

const onClickSearch = async () => {
  await fetchList({
    ...(stringSearch.value && { search: stringSearch.value }),
  })
}

onMounted(async () => {
  const userId = getUserId()
  const resp = await userServices.getDetails(userId)
  userName.value = resp.last_name

  userGroups.value = resp.groups
  if (!resp.groups.some(role => userAccess.hasAccessToBilling.includes(role))) {
    router.go(-1)
  }

  await fetchList()
})
</script>

<style scoped>
.bl-search {
  display: inline-block;
}

.date-search {
  display: inline-block;
  width: 30%;
  margin: 0 10px;
}

.link {
  color: #457b9d;
  text-decoration: underline;
  cursor: pointer;
}
</style>
