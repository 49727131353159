import { rawGet } from "@/services";
import { shortHandMonths } from "./constants";

/**
 * Given a url, gets the id and returns it
 * @param {string} url string where we will get the string from
 * @returns string e.g. '1', '4'
 */
export const getIdFromURL = (url) => {
  const trimmedURL = url.slice(0, -1);
  const index = trimmedURL.lastIndexOf("/");

  return trimmedURL.slice(index + 1);
};

export const dateFormatter = (date, isParam) => {
  if (!date) return "-";
  const tempDate = new Date(date);
  const month = tempDate.getMonth();
  const day = tempDate.getDate();
  const formattedDate = String(day).padStart(2, "0");
  const year = tempDate.getFullYear();

  if (isParam) {
    const formattedMonth = String(month + 1).padStart(2, "0");

    return `${year}-${formattedMonth}-${formattedDate}`;
  }

  const formattedMonth = shortHandMonths[month];

  return `${formattedMonth}-${formattedDate}-${year}`;
};

export const roundNumber = (number) => {
  return Math.round((Number(number) + Number.EPSILON) * 100) / 100;
};

export const downloadFile = (data, filename, extention) => {
  const href = URL.createObjectURL(data);

  const link = document.createElement("a");
  link.href = href;
  link.setAttribute("download", `${filename}.${extention}`);
  document.body.appendChild(link);
  link.click();

  document.body.removeChild(link);
  URL.revokeObjectURL(href);
};

export const nameFormatter = (user) => {
  if (!user) {
    return "-";
  }
  return `${user?.first_name} ${user?.last_name}`;
};

export const fetchAll = async (url) => {
  const results = [];
  const data = await rawGet({ url });
  // data = {count: 0, previous: "url", next: "url", results: [objects]}
  results.push(...data.results);

  if (data.next) {
    const nextResult = await fetchAll(data.next);
    results.push(...nextResult);
  }

  return results;
};

export const createPageList = (itemCount) => {
  const pageCount = Math.ceil(itemCount / 10);
  const arrayWithZero = Array.from(Array(pageCount + 1).keys());

  return arrayWithZero.slice(1, arrayWithZero.length);
};

export const statusFormatter = (str) => {
  if (str) return str.toUpperCase();
  return "";
};

export const getUserId = () => {
  const cookie = document.cookie.split(";").map((c) => c.trim());
  const tempUser = cookie.filter((c) => c.includes("User"));

  return tempUser[0]?.slice(5, tempUser[0].length) || [];
};
