<template>
  <LayoutComponent
    :user-name="userName"
    :user-groups="userGroups"
  >
    <div class="container">
      <PageTitle title="Independent Brokerage - Dashboard" />
      <h4 class="pt-5">Air Brokerages</h4>
      <div class="card card-body">
        <div class="d-flex flex-row justify-content-around pe-2">
          <div class="w-30">
            <label for="searchFields">Search Fields</label>
            <select
              class="form-select"
              id="searchFields"
              aria-label="Search Fields"
              v-model="airStringField"
            >
              <option
                v-for="field in airFields"
                :value="field"
                :key="field.value"
              >
                {{ field.label }}
              </option>
            </select>
          </div>
          <div class="w-30">
            <label for="airStringSearch">Search Details</label>
            <input
              type="text"
              class="form-control"
              id="airStringSearch"
              :placeholder="airStringField.label"
              v-model="airStringSearch"
            />
          </div>
          <div class="w-30 d-flex flex-column">
            <label for="airDateSearch" class="ms-2">Date Completed</label>
            <VueDatePicker
              v-model="airDateSearch"
              range
              id="airDateSearch"
              aria-label="Filter Client By Date"
              class="date-search w-100"
              :enable-time-picker="false"
            />
          </div>
        </div>
        <button
          type="button"
          class="btn ms-auto mt-2 button-primary"
          @click="onClickSearchAir(airPage)"
        >
          Search
        </button>
      </div>
      <button
        v-show="hasAccessToModify"
        class="btn my-3 me-3 button-primary"
        type="button"
        @click="onClickCreate"
      >
        Create
      </button>
      <button
        class="btn button-primary"
        type="button"
      >
        Download
      </button>
      <table class="table table-light table-striped table-bordered mt-4">
        <thead>
          <tr>
            <th scope="col">Company Name</th>
            <th scope="col">Flight Number</th>
            <th scope="col">MAWB</th>
            <th scope="col">HAWB</th>
            <th scope="col">Reference Number</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="brokerage in airBrokerageList"
            :key="brokerage.id"
            @click="handleClickTransaction(brokerage)"
          >
            <th scope="row" class="link">
              {{ brokerage.consignee.company_name }}
            </th>
            <td>{{ brokerage.flight_number }}</td>
            <td>
              {{ brokerage.master_airway_bill_number }}
            </td>
            <td>
              {{ brokerage.house_airway_bill_number }}
            </td>
            <td>{{ brokerage.registry_number }}</td>
            <td>{{ statusFormatter(brokerage.status) }}</td>
          </tr>
        </tbody>
      </table>
      <nav aria-label="Air Brokerage Navigation">
        <ul class="pagination">
          <li :class="getArrowClass('air-prev')">
            <button
              class="page-link"
              href="#"
              aria-label="Previous"
              @click="onClickSearchAir(airPage - 1)"
            >
              <span aria-hidden="true">&laquo;</span>
            </button>
          </li>
          <li
            v-for="page in airPageList"
            :key="page"
            :class="getPageButtonClass(airPage, page)"
          >
            <button
              class="page-link"
              href="#"
              @click="handleOnClickPageAir(page)"
            >
              {{ page }}
            </button>
          </li>
          <li :class="getArrowClass('air-next')">
            <button
              class="page-link"
              href="#"
              aria-label="Next"
              @click="onClickSearchAir(airPage + 1)"
            >
              <span aria-hidden="true">&raquo;</span>
            </button>
          </li>
        </ul>
      </nav>
      
      <h4 class="pt-5">Sea Brokerages</h4>
      <div class="card card-body">
        <div class="d-flex flex-row justify-content-around pe-2">
          <div class="w-30">
            <label for="searchFields">Search Fields</label>
            <select
              class="form-select"
              id="searchFields"
              aria-label="Search Fields"
              v-model="seaStringField"
            >
              <option
                v-for="field in seaFields"
                :value="field"
                :key="field.value"
              >
                {{ field.label }}
              </option>
            </select>
          </div>
          <div class="w-30">
            <label for="seaStringSearch">Search Details</label>
            <input
              type="text"
              class="form-control"
              id="seaStringSearch"
              :placeholder="seaStringField.label"
              v-model="seaStringSearch"
            />
          </div>
          <div class="w-30 d-flex flex-column">
            <label for="seaDateSearch" class="ms-2">Date Completed</label>
            <VueDatePicker
              v-model="seaDateSearch"
              range
              id="seaDateSearch"
              aria-label="Filter Client By Date"
              class="date-search w-100"
              :enable-time-picker="false"
            />
          </div>
        </div>
        <button
          type="button"
          class="btn ms-auto mt-2 button-primary"
          @click="onClickSearchSea(seaPage)"
        >
          Search
        </button>
      </div>
      <button
        v-show="hasAccessToModify"
        class="btn my-3 me-3 button-primary"
        type="button"
        @click="onClickCreate"
      >
        Create
      </button>
      <button
        class="btn button-primary"
        type="button"
      >
        Download
      </button>
      <table class="table table-light table-striped table-bordered mt-4">
        <thead>
          <tr>
            <th scope="col">Company Name</th>
            <th scope="col">Vessel Number</th>
            <th scope="col">MBL</th>
            <th scope="col">HBL</th>
            <th scope="col">Reference Number</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="brokerage in seaBrokerageList"
            :key="brokerage.id"
            @click="handleClickTransaction(brokerage)"
          >
            <th scope="row" class="link">
              {{ brokerage.consignee.company_name }}
            </th>
            <td>
              {{ brokerage.vessel_number }}
            </td>
            <td>
              {{ brokerage.master_bill_of_lading }}
            </td>
            <td>
              {{ brokerage.house_bill_of_lading }}
            </td>
            <td>
              {{ brokerage.registry_number }}
            </td>
            <td>{{ statusFormatter(brokerage.status) }}</td>
          </tr>
        </tbody>
      </table>
      <nav aria-label="Sea Brokerage Navigation">
        <ul class="pagination">
          <li :class="getArrowClass('sea-prev')">
            <button
              class="page-link"
              href="#"
              aria-label="Previous"
              @click="onClickSearchSea(seaPage - 1)"
            >
              <span aria-hidden="true">&laquo;</span>
            </button>
          </li>
          <li
            v-for="page in seaPageList"
            :key="page"
            :class="getPageButtonClass(seaPage, page)"
          >
            <button
              class="page-link"
              href="#"
              @click="handleOnClickPageSea(page)"
            >
              {{ page }}
            </button>
          </li>
          <li :class="getArrowClass('sea-next')">
            <button
              class="page-link"
              href="#"
              aria-label="Next"
              @click="onClickSearchSea(seaPage + 1)"
            >
              <span aria-hidden="true">&raquo;</span>
            </button>
          </li>
        </ul>
      </nav>
    </div>
    <DetailModal
      v-if="showDetailModal"
      :activity-logs="activityLogs"
      :external-logs="externalLogs"
      :has-access-to-modify="hasAccessToModify"
      mode="independent-brokerage"
      :transaction="transaction"
      @click-edit="onClickEdit"
      @click-delete="onClickDelete"
      @click-view="onClickView"
      @toggle-detail-modal="toggleDetailModal"
    />
    <AmendmentModal
      v-show="showAmendmentModal"
      @cancel-modal="cancelAmendmentModal"
      @confirm-modal="confirmAmendmentModal"
    />
    <DeleteModal
      v-show="showDeleteModal"
      @cancel-modal="cancelDeleteModal"
      @confirm-modal="confirmDeleteModal"
    />
  </LayoutComponent>
</template>

<script setup>
import { ref, onMounted } from "vue";

import { useRouter } from "vue-router";

import AmendmentModal from "@/components/AmendmentModal.vue";
import DeleteModal from "@/components/DeleteModal.vue";
import DetailModal from "@/components/BrokerageDashboard/DetailModal.vue";
import LayoutComponent from "@/components/LayoutComponent.vue";
import PageTitle from "@/components/PageTitle.vue";

import {
  amendmentServices,
  brokerageServices,
  clientServices,
  deleteServices,
  rawGet,
userServices,
} from "@/services";
import { userAccess } from "@/utils/constants";
import {
  createPageList,
  dateFormatter,
  fetchAll,
  getIdFromURL,
  getUserId,
  statusFormatter,
} from "@/utils/helpers";

const airFields = [
  { label: "Company Name", value: "client_name" },
  { label: "Flight Number", value: "flight_number" },
  { label: "MAWB", value: "master_airway_bill_number" },
  { label: "HAWB", value: "house_airway_bill_number" },
  { label: "Reference Number", value: "reference_number" },
  { label: "Status", value: "status" },
]
const seaFields = [
  { label: "Company Name", value: "client_name" },
  { label: "Vessel Number", value: "vessel_number" },
  { label: "MBL", value: "master_bill_of_lading" },
  { label: "HBL", value: "house_bill_of_lading" },
  { label: "Reference Number", value: "reference_number" },
  { label: "Status", value: "status" },
]
const router = useRouter();

const showAmendmentModal = ref(false);
const showDetailModal = ref(false);
const showDeleteModal = ref(false);
const transaction = ref(null);
const externalLogs = ref([]);
const activityLogs = ref([]);
const userName = ref(null);
const userGroups = ref([])
const hasAccessToModify = ref(false)

const airBrokerageList = ref([]);
const airStringField = ref({ label: "Company Name", value: "client_name" });
const airStringSearch = ref("");
const airDateSearch = ref(null);
const airPage = ref(1);
const airPageList = ref([]);
const airHasPrev = ref(false);
const airHasNext = ref(false);

const seaBrokerageList = ref([]);
const seaStringField = ref({ label: "Company Name", value: "client_name" });
const seaStringSearch = ref("");
const seaDateSearch = ref(null);
const seaPage = ref(1);
const seaPageList = ref([]);
const seaHasPrev = ref(false);
const seaHasNext = ref(false);

const onClickCreate = () => {
  router.push("/brokerages/independent/create");
};

// detail
const onClickView = async () => {
  const id = getIdFromURL(transaction.value.url)
  const category = await rawGet({ url: transaction.value?.category })
  router.push(`/brokerages/independent/${id}/view?type=${category.category}`);
}

const handleClickTransaction = async (brokerage) => {
  const id = getIdFromURL(brokerage.url)
  let externalResp
  let activityResp
  if (brokerage.url.includes('air')) {
    externalResp = await brokerageServices.getIndependentAirExternalLogs({
      id,
      params: { is_active: true },
    })
    activityResp = await brokerageServices.getIndependentAirActivityLogs({
      id,
      params: { is_active: true },
    });
  } else {
    externalResp = await brokerageServices.getIndependentSeaExternalLogs({
      id,
      params: { is_active: true },
    });
    activityResp = await brokerageServices.getIndependentSeaActivityLogs({
      id,
      params: { is_active: true },
    });
  }

  let tempExternal = externalResp?.results || []
  if (externalResp.next) {
    const next = await fetchAll(externalResp.next)
    tempExternal = [...tempExternal, ...next]
  }
  let tempActivity = activityResp?.results || []
  if (activityResp.next) {
    const next = await fetchAll(activityResp.next)
    tempActivity = [...tempActivity, ...next]
  }
  externalLogs.value = tempExternal
  activityLogs.value = tempActivity

  if (activityLogs.value.length) {
    for (let index = 0; index < activityLogs.value.length; index++) {
      const log = activityLogs.value[index];

      log.user = await rawGet({ url: log.user })
    }
  }

  const created_by = brokerage.created_by ?
    await rawGet({ url: brokerage.created_by })
    : null
  const submitted_by = brokerage.submitted_by ?
    await rawGet({ url: brokerage.submitted_by })
    : null
  const updated_by = brokerage.updated_by ?
    await rawGet({ url: brokerage.updated_by })
    : null
  transaction.value = {
    ...brokerage,
    created_by,
    submitted_by,
    updated_by,
  };
  showDetailModal.value = !showDetailModal.value;
};

const toggleDetailModal = () => {
  showDetailModal.value = !showDetailModal.value;
};

// amendment
const cancelAmendmentModal = () => {
  showAmendmentModal.value = !showAmendmentModal.value;
}

const confirmAmendmentModal = async () => {
  await amendmentServices.create({
    url: transaction.value.url,
  })
  showAmendmentModal.value = !showAmendmentModal.value
}

const onClickEdit = () => {
  const id = getIdFromURL(transaction.value.url)
  const status = transaction.value?.status
  
  if (status === "in_progress" || status === "for_amendment") {
    router.push(`/brokerages/independent/${id}/edit`);
  } else if (status === "submitted") {
    showAmendmentModal.value = !showAmendmentModal.value;
  }
};

// delete
const cancelDeleteModal = () => {
  showDeleteModal.value = !showDeleteModal.value;
};

const confirmDeleteModal = async () => {
  await deleteServices.create({
    url: transaction.value.url,
  })
  showDeleteModal.value = !showDeleteModal.value
  const promiseArr = []
  if (transaction.value.url.includes("air")) {
    promiseArr.push(onClickSearchAir(1))
  } else {
    promiseArr.push(onClickSearchSea(1))
  }
  await Promise.all(promiseArr);
}

const onClickDelete = () => {
  showDeleteModal.value = !showDeleteModal.value;
};

// fetch/search list
const fetchAirList = async (page, paramsArg) => {
  const params = {
    ...paramsArg,
    ...(page !== 1 && ({
      limit: 10,
      offset: 10 * (page - 1),
      page: page,
    }))
  }
  const resp = await brokerageServices.getIndependentAirList({ params });
  const data = resp.results

  for (let index = 0; index < data.length; index++) {
    const id = getIdFromURL(data[index].consignee);
    data[index].consignee = await clientServices.getDetails({ id });
  }

  airBrokerageList.value = data || [];
  airPage.value = page
  airPageList.value = createPageList(resp.count)
  airHasPrev.value = !!resp.previous
  airHasNext.value = !!resp.next
}

const fetchSeaList = async (page, paramsArg) => {
  const params = {
    ...paramsArg,
    ...(page !== 1 && ({
      limit: 10,
      offset: 10 * (page - 1),
      page: page,
    }))
  }
  const resp = await brokerageServices.getIndependentSeaList({ params });
  const data = resp.results

  for (let index = 0; index < data.length; index++) {
    const id = getIdFromURL(data[index].consignee);
    data[index].consignee = await clientServices.getDetails({ id });
  }

  seaBrokerageList.value = data || [];
  seaPage.value = page
  seaPageList.value = createPageList(resp.count)
  seaHasPrev.value = !!resp.previous
  seaHasNext.value = !!resp.next
}

const onClickSearchAir = async (page) => {
  let datesFilter

  if (airDateSearch.value) {
    datesFilter = {
      datetime_completed__gte: dateFormatter(airDateSearch.value[0], true),
      datetime_completed__lte: dateFormatter(airDateSearch.value[1], true),
    }
  }
  await fetchAirList(
    page,  
    {
      ...(airStringSearch.value && { [airStringField.value.value]: airStringSearch.value }),
      ...(airDateSearch.value && datesFilter),
    }
  )
}

const onClickSearchSea = async (page) => {
  let datesFilter

  if (seaDateSearch.value) {
    datesFilter = {
      datetime_completed__gte: dateFormatter(seaDateSearch.value[0], true),
      datetime_completed__lte: dateFormatter(seaDateSearch.value[1], true),
    }
  }
  await fetchSeaList(
    page,  
    {
      ...(seaStringSearch.value && { [seaStringField.value.value]: seaStringSearch.value }),
      ...(seaDateSearch.value && datesFilter),
    }
  )
}

// pagination
const getPageButtonClass = (currentPage, page) => {
  return currentPage === page ? "page-item active" : "page-item"
}

const getArrowClass = (arrow) => {
  if (arrow === "air-prev") {
    return airHasPrev.value ? "page-item" : "page-item disabled"
  } else if (arrow === "air-next") {
    return airHasNext.value ? "page-item" : "page-item disabled"
  }else if (arrow === "sea-prev") {
    return seaHasPrev.value ? "page-item" : "page-item disabled"
  } else if (arrow === "sea-next") {
    return seaHasNext.value ? "page-item" : "page-item disabled"
  }
}

const handleOnClickPageAir = (page) => {
  if (page === airPage.value) return
  onClickSearchAir(page)
}

const handleOnClickPageSea = (page) => {
  if (page === seaPage.value) return
  onClickSearchSea(page)
}

onMounted(async () => {
  const userId = getUserId()
  const resp = await userServices.getDetails(userId)
  userName.value = resp.last_name

  userGroups.value = resp.groups
  if (!resp.groups.some(role => userAccess.hasAccessToBrokerage.includes(role))) {
    router.go(-1)
  }
  hasAccessToModify.value = resp.groups.some(role => userAccess.hasAccessToBrokerageModify.includes(role))

  const airReq = fetchAirList(1);
  const seaReq = fetchSeaList(1);

  await Promise.all([airReq, seaReq])
})
</script>

<style scoped>
.bl-search {
  display: inline-block;
}

.date-search {
  display: inline-block;
  width: 30%;
  margin: 0 10px;
}

.link {
  color: #457b9d;
  text-decoration: underline;
  cursor: pointer;
}
</style>
