<template>
  <LayoutComponent
    :user-name="userName"
    :user-groups="userGroups"
  >
    <div class="container">
      <PageTitle title="Client - Edit" />
      <ClientForm :rates="rates" mode="edit" :client="client" />
    </div>
  </LayoutComponent>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from 'vue-router';

import ClientForm from "@/components/ClientForm.vue";
import LayoutComponent from "@/components/LayoutComponent.vue";
import PageTitle from "@/components/PageTitle.vue";

import { clientServices, userServices } from "@/services";
import { userAccess } from "@/utils/constants";
import { fetchAll, getUserId } from "@/utils/helpers";


const route = useRoute();
const router = useRouter();

const rates = ref([]);
const client = ref({});
const userName = ref(null);
const userGroups = ref([])

onMounted(async () => {
  const userId = getUserId()
  const resp = await userServices.getDetails(userId)
  userName.value = resp.last_name

  userGroups.value = resp.groups
  if (!resp.groups.some(role => userAccess.hasAccessToMemberModify.includes(role))) {
    router.go(-1)
  }
  const id = route.params.id;
  client.value = await clientServices.getDetails({ id });

  const tempRates = await clientServices.getRates({ id })
  let results = [...tempRates.results]
  if (tempRates.next) {
    const nextResults = await fetchAll(tempRates.next)
    results = [...results, ...nextResults]
  }
  rates.value = results.map(rate => ({
    ...rate,
    modeOfShipment: rate.mode_of_shipment,
    description: rate.description,
    taxable: rate.is_taxable,
    rate: Number(rate.rate),
    edited: false,
  }))
})
</script>

<style scoped>
</style>
