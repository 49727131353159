import { baseGet, basePost } from "./baseRequests";

export const brokerageServices = {
  // create
  createAir: async ({ transportTransaction, internalCode }) => {
    try {
      const data = {
        transport_transaction: transportTransaction,
        internal_code: internalCode,
      };
      const resp = await basePost({ url: "air-brokerage-transactions/", data });
      return resp.data;
    } catch (error) {
      console.log(error);
    }
  },
  createSea: async ({ transportTransaction, internalCode }) => {
    const data = {
      transport_transaction: transportTransaction,
      internal_code: internalCode,
    };
    try {
      const resp = await basePost({ url: "sea-brokerage-transactions/", data });
      return resp.data;
    } catch (error) {
      console.log(error);
    }
  },
  createIndependentAir: async ({ data }) => {
    try {
      const resp = await basePost({
        url: "independent-air-brokerage-transactions/",
        data,
      });
      return resp.data;
    } catch (error) {
      console.log(error);
    }
  },
  createIndependentSea: async ({ data }) => {
    try {
      const resp = await basePost({
        url: "independent-sea-brokerage-transactions/",
        data,
      });
      return resp.data;
    } catch (error) {
      console.log(error);
    }
  },
  // details
  getAirDetails: async ({ id }) => {
    try {
      const resp = await baseGet({ url: `air-brokerage-transactions/${id}/` });

      return resp.data;
    } catch (error) {
      console.log(error);
    }
  },
  getSeaDetails: async ({ id }) => {
    try {
      const resp = await baseGet({ url: `sea-brokerage-transactions/${id}/` });

      return resp.data;
    } catch (error) {
      console.log(error);
    }
  },
  getIndependentAirDetails: async ({ id }) => {
    try {
      const resp = await baseGet({
        url: `independent-air-brokerage-transactions/${id}/`,
      });

      return resp.data;
    } catch (error) {
      console.log(error);
    }
  },
  getIndependentSeaDetails: async ({ id }) => {
    try {
      const resp = await baseGet({
        url: `independent-sea-brokerage-transactions/${id}/`,
      });

      return resp.data;
    } catch (error) {
      console.log(error);
    }
  },
  // logging
  getAirActivityLogs: async ({ id, params }) => {
    try {
      const resp = await baseGet({
        url: `air-brokerage-transactions/${id}/logs/`,
        params,
      });

      return resp.data;
    } catch (error) {
      console.log(error);
    }
  },
  getIndependentAirActivityLogs: async ({ id, params }) => {
    try {
      const resp = await baseGet({
        url: `independent-air-brokerage-transactions/${id}/logs/`,
        params,
      });

      return resp.data;
    } catch (error) {
      console.log(error);
    }
  },
  getAirExternalLogs: async ({ id, params }) => {
    try {
      const resp = await baseGet({
        url: `air-brokerage-transactions/${id}/external-logs/`,
        params,
      });

      return resp.data;
    } catch (error) {
      console.log(error);
    }
  },
  getIndependentAirExternalLogs: async ({ id, params }) => {
    try {
      const resp = await baseGet({
        url: `independent-air-brokerage-transactions/${id}/external-logs/`,
        params,
      });

      return resp.data;
    } catch (error) {
      console.log(error);
    }
  },
  getSeaActivityLogs: async ({ id, params }) => {
    try {
      const resp = await baseGet({
        url: `sea-brokerage-transactions/${id}/logs/`,
        params,
      });

      return resp.data;
    } catch (error) {
      console.log(error);
    }
  },
  getIndependentSeaActivityLogs: async ({ id, params }) => {
    try {
      const resp = await baseGet({
        url: `independent-sea-brokerage-transactions/${id}/logs/`,
        params,
      });

      return resp.data;
    } catch (error) {
      console.log(error);
    }
  },
  getSeaExternalLogs: async ({ id, params }) => {
    try {
      const resp = await baseGet({
        url: `sea-brokerage-transactions/${id}/external-logs/`,
        params,
      });

      return resp.data;
    } catch (error) {
      console.log(error);
    }
  },
  getIndependentSeaExternalLogs: async ({ id, params }) => {
    try {
      const resp = await baseGet({
        url: `independent-sea-brokerage-transactions/${id}/external-logs/`,
        params,
      });

      return resp.data;
    } catch (error) {
      console.log(error);
    }
  },
  // list
  getAirList: async ({ params }) => {
    try {
      const resp = await baseGet({
        url: "air-brokerage-transactions/",
        params,
      });

      return resp.data;
    } catch (error) {
      console.log(error);
    }
  },
  getSeaList: async ({ params }) => {
    try {
      const resp = await baseGet({
        url: "sea-brokerage-transactions/",
        params,
      });

      return resp.data;
    } catch (error) {
      console.log(error);
    }
  },
  getIndependentAirList: async ({ params }) => {
    try {
      const resp = await baseGet({
        url: "independent-air-brokerage-transactions/",
        params,
      });

      return resp.data;
    } catch (error) {
      console.log(error);
    }
  },
  getIndependentSeaList: async ({ params }) => {
    try {
      const resp = await baseGet({
        url: "independent-sea-brokerage-transactions/",
        params,
      });

      return resp.data;
    } catch (error) {
      console.log(error);
    }
  },
  // submit
  submitAir: async ({ id }) => {
    try {
      const resp = await basePost({
        url: `air-brokerage-transactions/${id}/submit/`,
      });
      return resp.data;
    } catch (error) {
      console.log(error);
    }
  },
  submitIndependentAir: async ({ id }) => {
    try {
      const resp = await basePost({
        url: `independent-air-brokerage-transactions/${id}/submit/`,
      });
      return resp.data;
    } catch (error) {
      console.log(error);
    }
  },
  submitIndependentSea: async ({ id }) => {
    try {
      const resp = await basePost({
        url: `independent-sea-brokerage-transactions/${id}/submit/`,
      });
      return resp.data;
    } catch (error) {
      console.log(error);
    }
  },
  submitSea: async ({ id }) => {
    try {
      const resp = await basePost({
        url: `sea-brokerage-transactions/${id}/submit/`,
      });
      return resp.data;
    } catch (error) {
      console.log(error);
    }
  },
};
