<template>
  <LayoutComponent
    :user-name="userName"
    :user-groups="userGroups"
  >
    <div class="container">
      <PageTitle title="Deletion Requests - Dashboard" />
      <div class="py-3">
        <span class="fs-4 pe-3">Search</span>
        <div class="form-floating w-25 bl-search">
          <input
            type="text"
            class="form-control"
            id="BLSearch"
            placeholder="BL No."
          />
          <label for="BLSearch">BL No.</label>
        </div>
      </div>
      <table class="table table-light table-striped table-bordered mt-4">
        <thead>
          <tr>
            <th scope="col">Type</th>
            <th scope="col">MBL / MAWB</th>
            <th scope="col">HBL / HAWB</th>
            <th scope="col">Reference Number</th>
            <th scope="col">Status</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="deleteReq in deleteReqs"
            :key="deleteReq.url"
            @click="toggleDetailModal"
          >
            <td>{{ getType(deleteReq) }}</td>
            <td scope="row">
              {{
                deleteReq.transaction.master_airway_bill_number
                || deleteReq.transaction.master_bill_of_lading
                || deleteReq.transaction.transport_transaction?.master_airway_bill_number
                || deleteReq.transaction.transport_transaction?.master_bill_of_lading
              }}
            </td>
            <td>
              {{
                deleteReq.transaction.house_airway_bill_number
                || deleteReq.transaction.house_bill_of_lading
                || deleteReq.transaction.transport_transaction?.house_airway_bill_number
                || deleteReq.transaction.transport_transaction?.house_bill_of_lading
              }}
            </td>
            <td>{{
              deleteReq.transaction.registry_number
              || deleteReq.transaction.transport_transaction?.registry_number
            }}</td>
            <td>{{ statusFormatter(deleteReq.status) }}</td>
            <td>
              <button
                type="button"
                class="btn"
                @click="handleClickApprove(deleteReq)"
              >
                Approve
              </button>
              <button
                type="button"
                class="btn"
                @click="handleClickReject(deleteReq)"
              >
                Reject
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <ApprovalModal
      v-show="showApprovalModal"
      type="deletion"
      @cancel-modal="cancelApprovalModal"
      @confirm-modal="confirmApprovalModal"
    />
    <RejectionModal
      v-show="showRejectionModal"
      type="deletion"
      @cancel-modal="cancelRejectionModal"
      @confirm-modal="confirmRejectionModal"
    />
  </LayoutComponent>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRouter } from 'vue-router';

import ApprovalModal from "@/components/ApprovalModal.vue";
import LayoutComponent from "@/components/LayoutComponent.vue";
import PageTitle from "@/components/PageTitle.vue";
import RejectionModal from "@/components/RejectionModal.vue";

import { deleteServices, rawGet, rawPatch, userServices } from "@/services";
import { getUserId, statusFormatter } from "@/utils/helpers";

const router = useRouter();

const deleteReqs = ref([]);
const showApprovalModal = ref(false);
const showRejectionModal = ref(false);
const targetTransaction = ref(null);
const userName = ref(null);
const userGroups = ref([])

// approve
const cancelApprovalModal = () => {
  showApprovalModal.value = !showApprovalModal.value;
  targetTransaction.value = null;
}

const confirmApprovalModal = async () => {
  await rawPatch({
    url: targetTransaction.value.url,
    data: {
      status: "approved"
    }
  })
  fetchList()
  showApprovalModal.value = !showApprovalModal.value;
}

const handleClickApprove = (transaction) => {
  showApprovalModal.value = !showApprovalModal.value;
  targetTransaction.value = transaction;
}

// reject
const cancelRejectionModal = () => {
  showRejectionModal.value = !showRejectionModal.value;
  targetTransaction.value = null;
}

const confirmRejectionModal = async () => {
  await rawPatch({
    url: targetTransaction.value.url,
    data: {
      status: "rejected"
    }
  })
  fetchList()
  showRejectionModal.value = !showRejectionModal.value;
}

const handleClickReject = (transaction) => {
  showRejectionModal.value = !showRejectionModal.value;
  targetTransaction.value = transaction;
}

// fetching/searching lists
const fetchList  = async () => {
  const deleteResp = await deleteServices.getList({
    params: "?is_active=true"
  });

  for (let index = 0; index < deleteResp.length; index++) {
    const deleteReq = deleteResp[index];
    let url = deleteReq.transaction
    if (deleteReq.status === "approved") {
      url = url + "?is_active=false"
    }
    deleteReq.transaction = await rawGet({ url })
  }

  deleteReqs.value = deleteResp || []
}

const getType = (req) => {
  const type = req.transaction.type === "import"
    || req.transaction.transport_transaction?.type === "import"
    ? "IMPORT"
    : "EXPORT"

  let transaction
  if (req.transaction.url.includes("air-transport-transactions")) {
    transaction = "AIR"
  } else if (req.transaction.url.includes("sea-transport-transactions")) {
    transaction = "SEA"
  } else if (req.transaction.transport_transaction?.url.includes("air-transport-transactions")) {
    transaction = "DEP-BROKERAGE-AIR"
  } else if (req.transaction.transport_transaction?.url.includes("sea-transport-transactions")) {
    transaction = "DEP-BROKERAGE-SEA"
  } else if (req.transaction.url.includes("independent-air-brokerage-transactions")) {
    transaction = "IND-BROKERAGE-AIR"
  } else if (req.transaction.url.includes("independent-sea-brokerage-transactions")) {
    transaction = "IND-BROKERAGE-SEA"
  }
  
  return `${transaction}-${type}`
}

onMounted(async () => {
  const userId = getUserId()
  const resp = await userServices.getDetails(userId)
  userName.value = resp.last_name

  userGroups.value = resp.groups
  if (!resp.groups.includes("Admin")) {
    router.go(-1)
  }
  fetchList()
})
</script>

<style scoped>
.bl-search {
  display: inline-block;
}
</style>
