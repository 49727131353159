import { baseDelete, baseGet, basePost } from "./baseRequests";

// TODO: throw error instead of logging
// handle the error in the pages
export const userServices = {
  create: async (data) => {
    const resp = await basePost({
      url: "users/",
      data,
    });

    return resp.data;
  },
  delete: async (id) => {
    const resp = await baseDelete({
      url: `users/${id}/`,
    });

    return resp.data;
  },
  getDetails: async (id) => {
    try {
      const resp = await baseGet({ url: `users/${id}/` });
      return resp.data;
    } catch (error) {
      console.log(error);
    }
  },
  getGroupList: async () => {
    const resp = await baseGet({
      url: "groups/",
    });

    return resp.data;
  },
  getList: async (params) => {
    const resp = await baseGet({
      url: "users/",
      params,
    });

    return resp.data;
  },
};
