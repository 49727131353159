import { baseGet, basePost, baseUpdate } from "./baseRequests";

// TODO: throw error instead of logging
// handle the error in the pages
export const billServices = {
  create: async ({
    address,
    billedTo,
    billingAccount,
    billingStatementDocumentNumber,
    cargoDescription,
    consignee,
    nominalCost,
    projectedProfit,
    salesman,
    shipper,
    soa,
    soaDocumentNumber,
    tin,
    totalBilledCharge,
    transaction,
  }) => {
    const data = {
      address,
      billed_to: billedTo,
      billing_account_reference_number: billingAccount,
      billing_statement_document_number: billingStatementDocumentNumber,
      cargo_description: cargoDescription,
      consignee,
      nominal_cost: nominalCost,
      projected_profit: projectedProfit,
      salesman,
      shipper,
      soa_document_number: soaDocumentNumber,
      soa_reference_number: soa,
      tin,
      total_billed_charges: totalBilledCharge,
      transaction,
    };

    try {
      const resp = await basePost({ url: "bills/", data });
      return resp.data;
    } catch (error) {
      console.log(error);
    }
  },
  createRate: async ({ data, id }) => {
    try {
      const resp = await basePost({
        url: `bills/${id}/rates/`,
        data,
      });
      return resp;
    } catch (error) {
      console.log(error);
    }
  },
  downloadBillDocument: async ({ billID, documentID }) => {
    try {
      const resp = await baseGet({
        url: `bills/${billID}documents/${documentID}/download/`,
      });
      return resp;
    } catch (error) {
      console.log(error);
    }
  },
  getActivityLogs: async ({ id }) => {
    try {
      const resp = await baseGet({
        url: `bills/${id}/logs/`,
      });

      return resp.data;
    } catch (error) {
      console.log(error);
    }
  },
  getExternalLogs: async ({ id }) => {
    try {
      const resp = await baseGet({
        url: `bills/${id}/external-logs/`,
      });

      return resp.data;
    } catch (error) {
      console.log(error);
    }
  },
  getDetails: async ({ id }) => {
    try {
      const resp = await baseGet({ url: `bills/${id}/` });
      return resp.data;
    } catch (error) {
      console.log(error);
    }
  },
  getDocumentList: async ({ id }) => {
    try {
      const resp = await baseGet({
        url: `bills/${id}/documents/?is_active=true`,
      });
      return resp.data;
    } catch (error) {
      console.log(error);
    }
  },
  getList: async ({ params }) => {
    try {
      const resp = await baseGet({ url: "bills/", params });
      return resp.data;
    } catch (error) {
      console.log(error);
    }
  },
  getRates: async ({ id }) => {
    try {
      const resp = await baseGet({
        url: `bills/${id}/rates/`,
        params: {
          is_active: true,
        },
      });
      return resp.data;
    } catch (error) {
      console.log(error);
    }
  },
  submit: async ({ id }) => {
    try {
      const resp = await basePost({ url: `bills/${id}/submit/` });
      return resp.data;
    } catch (error) {
      console.log(error);
    }
  },
  update: async ({
    address,
    billedTo,
    billingAccountReferenceNumber,
    billingStatementDocumentNumber,
    cargoDescription,
    nominalCost,
    projectedProfit,
    salesman,
    shipper,
    soaDocumentNumber,
    soaReferenceNumber,
    tin,
    totalBilledCharge,
    transaction,
    id,
  }) => {
    const data = {
      address,
      billed_to: billedTo,
      billing_account_reference_number: billingAccountReferenceNumber,
      billing_statement_document_number: billingStatementDocumentNumber,
      cargo_description: cargoDescription,
      nominal_cost: nominalCost,
      projected_profit: projectedProfit,
      salesman,
      shipper,
      soa_document_number: soaDocumentNumber,
      soa_reference_number: soaReferenceNumber,
      tin,
      total_billed_charges: totalBilledCharge,
      transaction,
    };

    try {
      const resp = await baseUpdate({ url: `bills/${id}/`, data });
      return resp.data;
    } catch (error) {
      console.log(error);
    }
  },
};
