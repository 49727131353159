<template>
  <div class="container pt-5 ">
    <ErrorMessage
      v-show="hasError"
      @dismiss-alert="handleOnDismissalert"
    >
      Invalid username or password
    </ErrorMessage>
    <div class="form-container p-5 mx-auto bg-green-primary">
      <label
        for="usernameInput"
        class="form-label text-white text-align-left"
      >
        Username
      </label>
      <input
        type="text"
        v-model="username"
        placeholder="Username"
        class="form-control mb-3 text-lowercase"
        id="usernameInput"
      >
      <label
        for="passwordInput"
        class="form-label text-white"
      >
        Password
      </label>
      <input
        type="password"
        v-model="password"
        placeholder="Password"
        class="form-control"
        id="passwordInput"
      >
      <div class="text-center pt-3">
        <button
          type="button"
          class="btn btn-secondary"
          @click="login"
        >
          Login
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { AuthServices } from '@/services/AuthService';

import ErrorMessage from "@/components/ErrorMessage.vue";

const router = useRouter();

const username = ref("");
const password = ref("");
const hasError = ref(false);

const login = async () => {
  try {
    await AuthServices.login({
      username: username.value?.toLowerCase() || "",
      password: password.value,
    })
    router.push("/")
  } catch (error) {
    hasError.value = true;
  }
}

const handleOnDismissalert = () => {
  hasError.value = false;
}
</script>

<style scoped>
.form-container {
  width: 50%;
  text-align: left;
}
</style>
