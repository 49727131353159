<template>
  <LayoutComponent
    :user-name="userName"
    :user-groups="userGroups"
  >
    <div class="container">
      <PageTitle title="Payee - Create" />
      <PayeeForm mode="create" />
    </div>
  </LayoutComponent>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";

import LayoutComponent from "@/components/LayoutComponent.vue";
import PageTitle from "@/components/PageTitle.vue";
import PayeeForm from "@/components/PayeeForm.vue";

import { userAccess } from "@/utils/constants";
import { getUserId } from "@/utils/helpers";
import { userServices } from "@/services";

const router = useRouter();

const userName = ref(null);
const userGroups = ref([])

onMounted(async () => {
  const userId = getUserId()
  const resp = await userServices.getDetails(userId)
  userName.value = resp.last_name

  userGroups.value = resp.groups
  if (!resp.groups.some(role => userAccess.hasAccessToMemberModify.includes(role))) {
    router.go(-1)
  }
})
</script>

<style scoped>
</style>
