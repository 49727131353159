import { baseGet, basePost } from "./baseRequests";

export const deleteServices = {
  getList: async ({ params }) => {
    try {
      const resp = await baseGet({ url: `deletion-requests/${params || ""}` });
      return resp.data.results;
    } catch (error) {
      console.log(error);
      return "Server error";
    }
  },
  create: async ({ url }) => {
    try {
      const data = {
        transaction: url,
      };
      const resp = await basePost({ url: "deletion-requests/", data });

      return resp;
    } catch (error) {
      console.log(error);
    }
  },
};
