<template>
  <div class="container-fluid">
    <div class="row flex-nowrap">
      <SideBar :user-groups="props.userGroups || []"/>
      <div class="col py-3 px-0">
        <div class="d-flex justify-content-end">
          <h3>
              Hello, <span class="text-capitalize">{{ props.userName?.toLowerCase() }}</span>
          </h3><fas icon="fa-user" class="px-4 header-fas"/>
          <button
            class="btn button-primary me-2"
            @click="onClickLogout"
          >
            Logout
          </button>
        </div>
        <hr />
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from "vue";
import { useRouter } from "vue-router";

import SideBar from "@/components/SideBar.vue";

const props = defineProps(["userName", "userGroups"])
const router = useRouter();

const onClickLogout = () => {
  document.cookie = "BearerToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  document.cookie = "RefreshToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  document.cookie = "User=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
  router.push("/login")
}
</script>

<style scoped>
.header-fas {
  font-size: 30px;
}
</style>