<template>
  <LayoutComponent
    :user-name="userName"
    :user-groups="userGroups"
  >
    <div class="container">
      <!-- TODO: breakdown into smaller components -->
      <PageTitle title="Members - Dashboard" />
      <h4 class="pt-3">Client List</h4>
      <div class="py-3 pt-0">
        <span class="fs-4 pe-3">Search</span>
        <div class="form-floating w-25 search">
          <input
            type="text"
            class="form-control"
            id="ClientSearch"
            placeholder="Client Name"
            v-model="clientStringSearch"
          />
          <label for="ClientSearch">Search Client</label>
        </div>
        <VueDatePicker
          v-model="clientDateSearch"
          range
          id="clientDateSearch"
          aria-label="Filter Client By Date"
          class="date-search"
          :enable-time-picker="false"
        />
        <button
          type="button"
          class="btn btn-success"
          @click="onClickClientSearch"
        >
          Search
        </button>
      </div>
      <button
        v-show="hasAccessToModify"
        type="button"
        class="btn btn-success"
        @click="onClickClientCreate"
      >
        Create
      </button>
      <table class="table table-light table-striped table-bordered mt-4">
        <thead>
          <tr>
            <th scope="col">Company Name</th>
            <th scope="col">Salesman</th>
            <th scope="col">Created By</th>
            <th scope="col">Creation Date</th>
            <th scope="col">Last Updated By</th>
            <th scope="col">Last Updated Date</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="client in clientDetails"
            :key="client?.url"
            @click="onClickRow('clients', client)"
          >
            <th scope="row" class="link">{{ client.company_name }}</th>
            <td>{{ client.salesman }}</td>
            <td>{{ client.created_by }}</td>
            <td>{{ dateFormatter(client.datetime_created) }}</td>
            <td>{{ client.updated_by }}</td>
            <td>{{ dateFormatter(client.datetime_updated) }}</td>
          </tr>
        </tbody>
      </table>
      <nav aria-label="Client List Navigation">
        <ul class="pagination">
          <li :class="getArrowClass('client-prev')">
            <button
              class="page-link"
              href="#"
              aria-label="Previous"
              @click="onClickClientSearch(clientPage - 1)"
            >
              <span aria-hidden="true">&laquo;</span>
            </button>
          </li>
          <li
            v-for="page in clientPageList"
            :key="page"
            :class="getPageButtonClass(clientPage, page)"
          >
            <button
              class="page-link"
              href="#"
              @click="handleOnClickPageClient(page)"
            >
              {{ page }}
            </button>
          </li>
          <li :class="getArrowClass('client-next')">
            <button
              class="page-link"
              href="#"
              aria-label="Next"
              @click="onClickClientSearch(clientPage + 1)"
            >
              <span aria-hidden="true">&raquo;</span>
            </button>
          </li>
        </ul>
      </nav>

      <h4 class="pt-3">Payee List</h4>
      <div class="py-3 pt-0">
        <span class="fs-4 pe-3">Search</span>
        <div class="form-floating w-25 search">
          <input
            type="text"
            class="form-control"
            id="payeeSearch"
            placeholder="Payee Name"
            v-model="payeeStringSearch"
          />
          <label for="payeeSearch">Search Payee</label>
        </div>
        <VueDatePicker
          v-model="payeeDateSearch"
          range
          id="clientDateSearch"
          aria-label="Filter Client By Date"
          class="date-search"
          :enable-time-picker="false"
        />
        <button
          type="button"
          class="btn btn-success"
          @click="onClickPayeeSearch"
        >
          Search
        </button>
      </div>
      <button
        v-show="hasAccessToModify"
        type="button"
        class="btn btn-success"
        @click="onClickPayeeCreate"
      >
        Create
      </button>
      <table class="table table-light table-striped table-bordered mt-4">
        <thead>
          <tr>
            <th scope="col">Company Name</th>
            <th scope="col">Payee Agent</th>
            <th scope="col">Created By</th>
            <th scope="col">Creation Date</th>
            <th scope="col">Last Updated By</th>
            <th scope="col">Last Updated Date</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="payee in payeeDetails"
            :key="payee?.url"
            @click="onClickRow('payees', payee)"
          >
            <th scope="row" class="link">{{ payee.company_name }}</th>
            <td>{{ payee.agent?.name }}</td>
            <td>{{ payee.created_by }}</td>
            <td>{{ dateFormatter(payee.datetime_created) }}</td>
            <td>{{ payee.updated_by }}</td>
            <td>{{ dateFormatter(payee.datetime_updated) }}</td>
          </tr>
        </tbody>
      </table>
      <nav aria-label="Payee List Navigation">
        <ul class="pagination">
          <li :class="getArrowClass('payee-prev')">
            <button
              class="page-link"
              href="#"
              aria-label="Previous"
              @click="onClickPayeeSearch(payeePage - 1)"
            >
              <span aria-hidden="true">&laquo;</span>
            </button>
          </li>
          <li
            v-for="page in payeePageList"
            :key="page"
            :class="getPageButtonClass(payeePage, page)"
          >
            <button
              class="page-link"
              href="#"
              @click="handleOnClickPagePayee(page)"
            >
              {{ page }}
            </button>
          </li>
          <li :class="getArrowClass('payee-next')">
            <button
              class="page-link"
              href="#"
              aria-label="Next"
              @click="onClickPayeeSearch(payeePage + 1)"
            >
              <span aria-hidden="true">&raquo;</span>
            </button>
          </li>
        </ul>
      </nav>

      <DetailModal
        v-show="showDetailModal"
        :activityLogs="activityLogs"
        :has-access-to-modify="hasAccessToModify"
        @click-edit="onClickEdit"
        @toggle-delete-modal="toggleDeleteModal"
        @toggle-detail-modal="toggleDetailModal"
      />
      <DeleteModal
        v-show="showDeleteModal"
        @toggle-delete-modal="toggleDeleteModal"
      />
    </div>
  </LayoutComponent>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";

import DeleteModal from "@/components/MemberDashboard/DeleteModal.vue";
import DetailModal from "@/components/MemberDashboard/DetailModal.vue";
import LayoutComponent from "@/components/LayoutComponent.vue";
import PageTitle from "@/components/PageTitle.vue";

import { clientServices, payeeServices, rawGet, userServices } from "@/services";
import { createPageList, dateFormatter, fetchAll, getIdFromURL, getUserId } from "@/utils/helpers";
import { userAccess } from "@/utils/constants";

const router = useRouter();
const showDetailModal = ref(false);
const showDeleteModal = ref(false);
const selectedEntity = ref("");
const selectedEntityDetails = ref({});
const userName = ref(null);
const activityLogs = ref([]);
const userGroups = ref([])
const hasAccessToModify = ref(false)

const clientStringSearch = ref("");
const clientDateSearch = ref(null);
const clientDetails = ref([]);
const clientPage = ref(1);
const clientPageList = ref([]);
const clientHasNext = ref(false);
const clientHasPrev = ref(false);

const payeeStringSearch = ref("");
const payeeDateSearch = ref(null);
const payeeDetails = ref([]);
const payeePage = ref(1);
const payeePageList = ref([]);
const payeeHasNext = ref(false);
const payeeHasPrev = ref(false);


const onClickEdit = () => {
  const id = getIdFromURL(selectedEntityDetails.value.url);
  router.push(`/members/${selectedEntity.value}/${id}/edit`);
};

const onClickClientCreate = () => {
  router.push("/members/clients/create")
}

const onClickPayeeCreate = () => {
  router.push("/members/payees/create")
}

const onClickRow = async (entity, entityDetails) => {
  const id = getIdFromURL(entityDetails.url)
  let resp
  if(entity === "clients") {
    resp = await clientServices.getActivityLogs({ id })
  } else {
    resp = await payeeServices.getActivityLogs({ id })
  }

  let tempActivity = resp?.results || []
  if (resp.next) {
    const next = await fetchAll(resp.next)
    tempActivity = [...tempActivity, ...next]
  }
  activityLogs.value = tempActivity

  if (activityLogs.value.length) {
    for (let index = 0; index < activityLogs.value.length; index++) {
      const log = activityLogs.value[index];

      log.user = await rawGet({ url: log.user })
    }
  }

  toggleDetailModal(entity, entityDetails)
};

const toggleDetailModal = (entity, entityDetails) => {
  selectedEntity.value = entity;
  showDetailModal.value = !showDetailModal.value;
  selectedEntityDetails.value = entityDetails;
};

const toggleDeleteModal = () => {
  showDeleteModal.value = !showDeleteModal.value;
};

const fetchClients = async (page, argsParams) => {
  const params = {
    is_active: true,
    ...argsParams,
    ...(page !== 1 && ({
      limit: 10,
      offset: 10 * (page - 1),
      page: page,
    }))
  }

  const clientsResponse = await clientServices.getList({ params });
  const clientResult = clientsResponse.results
  for (let index = 0; index < clientResult.length; index++) {
    const payeeId = getIdFromURL(clientResult[index].url);
    // get the created_by and updated_by names
    const tempCreatedBy = clientResult[index].created_by;
    const tempUpdatedBy = clientResult[index].updated_by;
    let createdBy = "";
    let updatedBy = "";

    if (tempCreatedBy !== null) {
      const temp = await rawGet({ url: tempCreatedBy });
      createdBy = `${temp.first_name} ${temp.last_name}`;
    }
    if (tempUpdatedBy !== null && tempUpdatedBy !== tempCreatedBy) {
      const temp = await rawGet({ url: tempUpdatedBy });
      updatedBy = `${temp.first_name} ${temp.last_name}`;
    } else if (tempUpdatedBy !== null && tempUpdatedBy === tempCreatedBy) {
      updatedBy = createdBy;
    }

    clientResult[index].created_by = createdBy;
    clientResult[index].updated_by = updatedBy;

    // get the agent
    const agent = await payeeServices.getAgentListByPayeeId({ id: payeeId });
    clientResult[index].agent = agent[0];
  }

  clientDetails.value = clientResult || [];
  clientPage.value = page;
  clientPageList.value = createPageList(clientsResponse.count)
  clientHasNext.value = !!clientsResponse.next
  clientHasPrev.value = !!clientsResponse.previous
}

const fetchPayees = async (page, argsParams) => {
  const params = {
    is_active: true,
    ...argsParams,
    ...(page !== 1 && ({
      limit: 10,
      offset: 10 * (page - 1),
      page: page,
    }))
  }

  // TODO: check agent and bank
  const payeesResponse = await payeeServices.getList({ params });
  const payeesResults = payeesResponse.results;

  for (let index = 0; index < payeesResults.length; index++) {
    const payeeId = getIdFromURL(payeesResults[index].url);
    // get the created_by and updated_by names
    const tempCreatedBy = payeesResults[index].created_by;
    const tempUpdatedBy = payeesResults[index].updated_by;
    let createdBy = "";
    let updatedBy = "";

    if (tempCreatedBy !== null) {
      const temp = await rawGet({ url: tempCreatedBy });
      createdBy = `${temp.first_name} ${temp.last_name}`;
    }
    if (tempUpdatedBy !== null && tempUpdatedBy !== tempCreatedBy) {
      const temp = await rawGet({ url: tempUpdatedBy });
      updatedBy = `${temp.first_name} ${temp.last_name}`;
    } else if (tempUpdatedBy !== null && tempUpdatedBy === tempCreatedBy) {
      updatedBy = createdBy;
    }

    payeesResults[index].created_by = createdBy;
    payeesResults[index].updated_by = updatedBy;

    // get the agent
    const agent = await payeeServices.getAgentListByPayeeId({ id: payeeId });
    payeesResults[index].agent = agent.results[0];
  }

  payeeDetails.value = payeesResults || [];
  payeePage.value = page;
  payeePageList.value = createPageList(payeesResponse.count)
  payeeHasNext.value = !!payeesResponse.next
  payeeHasPrev.value = !!payeesResponse.previous
}

const onClickClientSearch = async (page) => {
  const datetime_filter = {}
  if (clientDateSearch.value?.length === 2) {
    datetime_filter.datetime_created__gte = dateFormatter(clientDateSearch.value[0], true)
    datetime_filter.datetime_created__lte = dateFormatter(clientDateSearch.value[1], true)
  }

  await fetchClients(
    page,
    {
      company_name: clientStringSearch.value,
      ...datetime_filter 
    }
  )
}

const onClickPayeeSearch = async (page) => {
  const datetime_filter = {}
  if (payeeDateSearch.value?.length === 2) {
    datetime_filter.datetime_created__gte = dateFormatter(payeeDateSearch.value[0], true)
    datetime_filter.datetime_created__lte = dateFormatter(payeeDateSearch.value[1], true)
  }

  await fetchPayees(
    page,  
    {
      company_name: payeeStringSearch.value,
      ...datetime_filter 
    }
  )
}

// pagination
const getPageButtonClass = (currentPage, page) => {
  return currentPage === page ? "page-item active" : "page-item"
}

const getArrowClass = (arrow) => {
  if (arrow === "client-prev") {
    return clientHasPrev.value ? "page-item" : "page-item disabled"
  } else if (arrow === "client-next") {
    return clientHasNext.value ? "page-item" : "page-item disabled"
  } else if (arrow === "payee-prev") {
    return payeeHasPrev.value ? "page-item" : "page-item disabled"
  } else if (arrow === "payee-next") {
    return payeeHasNext.value ? "page-item" : "page-item disabled"
  }
}

const handleOnClickPageClient = (page) => {
  if (page === clientPage.value) return
  onClickClientSearch(page)
}

const handleOnClickPagePayee = (page) => {
  if (page === payeePage.value) return
  onClickPayeeSearch(page)
}

onMounted(async () => {
  const userId = getUserId()
  const resp = await userServices.getDetails(userId)
  userName.value = resp.last_name

  userGroups.value = resp.groups
  if (!resp.groups.length) {
    router.go(-1)
  }
  hasAccessToModify.value = resp.groups.some(role => userAccess.hasAccessToMemberModify.includes(role))

  const clientPromise = fetchClients(1);
  const payeePromise = fetchPayees(1);

  await Promise.all([clientPromise, payeePromise]);
})
</script>

<style scoped>
.search {
  display: inline-block;
}

.date-search {
  display: inline-block;
  width: 30%;
  margin: 0 10px;
}

.link {
  color: #457b9d;
  text-decoration: underline;
  cursor: pointer;
}
</style>
