<template>
  <div class="import-modal container">
    <div class="d-flex justify-content-between pb-5">
      <fas icon="fa-solid fa-xmark" class="detail-modal-fas" @click="toggleDetailModal" />
      <div class="modal-buttons mt-2">
        <button type="button" class="btn btn-light me-3" @click="clickView">
          View
        </button>
        <button
          type="button"
          class="btn btn-light me-3"
          @click="clickEdit"
          :disabled="isEditDisabled"
        >
          Edit
        </button>
      </div>
    </div>
    <div class="mx-5">
      <table class="table detail-tables fs-5">
        <tbody>
          <tr>
            <td class="fw-semibold">Created By:</td>
            <td>{{ nameFormatter(props.billing.created_by) }}</td>
          </tr>
          <tr> 
            <td class="fw-semibold">Creation Date:</td>
            <td>{{ dateFormatter(props.billing.datetime_created) }}</td>
          </tr>
          <tr>
            <td class="fw-semibold">Last Updated By:</td>
            <td>{{ nameFormatter(props.billing.updated_by) }}</td>
          </tr>
          <tr>
            <td class="fw-semibold">Last Update Date:</td>
            <td>{{ dateFormatter(props.billing.datetime_updated) }}</td>
          </tr>
          <tr>
            <td class="fw-semibold">Submitted By:</td>
            <td>{{ nameFormatter(props.billing.submitted_by) }}</td>
          </tr>
          <tr>
            <td class="fw-semibold">Submission Date:</td>
            <td>{{ dateFormatter(props.billing.datetime_submitted) }}</td>
          </tr>
          <tr>
            <td class="fw-semibold">Completion Date:</td>
            <td>{{ dateFormatter(props.billing.datetime_completed) }}</td>
          </tr>
          <tr>
            <td class="fw-semibold">Total Billed Charges:</td>
            <td>{{ roundNumber(props.billing.total_billed_charges) }}</td>
          </tr>
          <tr>
            <td class="fw-semibold">Nominal Cost:</td>
            <td>{{ roundNumber(props.billing.nominal_cost) }}</td>
          </tr>
          <tr>
            <td class="fw-semibold">Projected Profit:</td>
            <td>{{ roundNumber(props.billing.projected_profit) }}</td>
          </tr>
        </tbody>
      </table>
      <h3 class="mt-4">External Logs</h3>
      <hr />
      <table class="table table-secondary logs-tables">
        <thead>
          <tr>
            <th scope="col">Date</th>
            <th scope="col">Event</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="log in props.externalLogs" :key="log.url">
            <td scope="row">{{ dateFormatter(log.datetime_created) }}</td>
            <td>{{ log.notes }}</td>
          </tr>
        </tbody>
        </table>
      <h3 class="mt-4">Activity Logs</h3>
      <hr />
      <table class="table table-secondary logs-tables">
        <thead>
          <tr>
            <th scope="col">Date</th>
            <th scope="col">Event</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="log in props.activityLogs" :key="log.url">
            <td scope="row">{{ dateFormatter(log.timestamp) }}</td>
            <td>{{ log.action_type?.toUpperCase() }}</td>
            <td>{{ nameFormatter(log.user) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script setup>
import { computed, defineEmits, defineProps } from "vue";
import { dateFormatter, nameFormatter, roundNumber } from "@/utils/helpers";

const props = defineProps(["activityLogs", "externalLogs", "billing"]);
const emit = defineEmits([
  "clickEdit",
  "clickView",
  "toggleDetailModal",
]);

const isEditDisabled = computed(() => {
  return props.transaction?.status === "amendment_requested"
})

const clickEdit = () => {
  emit("clickEdit");
};

const clickView = () => {
  emit("clickView");
};

const toggleDetailModal = () => {
  emit("toggleDetailModal");
};
</script>

<style scoped>
.import-modal {
  background-color: #adadad;
  position: fixed;
  overflow-y: scroll;
  top: 0;
  right: 0;
  height: 100%;
  width: 50%;
}

.detail-modal-fas {
  font-size: 40px;
  padding-left: 5px;
  padding-top: 5px;
  cursor: pointer;
}

.modal-buttons .btn {
  height: 40px;
  padding-left: 20px;
  padding-right: 20px;
}

.logs-tables th {
  background-color: #adadad;
}

.detail-tables td {
  background-color: #adadad;
}

.detail-tables td.fw-semibold{
  width: 230px;
}
</style>
