<template>
  <div class="alert alert-danger alert-dismissible fade show float-end" role="alert">
    <slot></slot>
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      @click="handleOnClose"
    ></button>
  </div>
</template>

<script setup>
import { defineEmits } from "vue";

const emit = defineEmits(["dismissAlert"]);

const handleOnClose = () => {
  emit("dismissAlert")
}
</script>

<style scoped>
</style>
