<template>
  <LayoutComponent
    :user-name="userName"
    :user-groups="userGroups"
  >
    <div class="container">
      <PageTitle title="User Access - Dashboard" />
      <div class="p-3">
        <span class="fs-4 pe-3">Search</span>
        <div class="form-floating w-25 bl-search">
          <input
            type="text"
            class="form-control"
            id="BLSearch"
            placeholder="BL No."
            v-model="stringSearch"
          />
          <label for="BLSearch">Search Bills</label>
        </div>
        <button
          type="button"
          class="btn btn-success"
          @click="onClickSearch"
        >
          Search
        </button>
      </div>
      <button
        class="btn my-3 me-3 button-primary"
        type="button"
        @click="onClickCreate"
      >
        Create
      </button>
      <table class="table table-light table-striped table-bordered mt-4">
        <thead>
          <tr>
            <th scope="col">Full Name</th>
            <th scope="col">Email</th>
            <th scope="col">Phone Number</th>
            <th scope="col">Role</th>
            <th scope="col">Is Active</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="user in userList"
            :key="user.url"
          >
            <th scope="row">{{ user.first_name }} {{ user.last_name }}</th>
            <td>{{ user.email }}</td>
            <td>{{ user.phone_number }}</td>
            <td>{{ user.groups.join(", ") }}</td>
            <td>{{ user.is_active ? "YES" : "NO" }}</td>
            <td>
              <fas 
                icon="fa-pen-to-square"
                class="mx-4 cursor-pointer text-success"
                @click="onClickEdit(user.url)"
              />
              <fas
                class="cursor-pointer text-danger"
                icon="fa-trash"
                @click="onClickDelete(user.url)"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <DeleteModal
        v-show="showDeleteModal"
        @cancel-modal="handleOnCancelDelete"
        @confirm-modal="handleOnConfirmDelete"
      />
    </div>
  </LayoutComponent>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";

import DeleteModal from "@/components/DeleteModal.vue";
import LayoutComponent from "@/components/LayoutComponent.vue";
import PageTitle from "@/components/PageTitle.vue";

import { userServices } from "@/services";
import { createPageList, getIdFromURL, getUserId } from "@/utils/helpers";

const router = useRouter();
const stringSearch = ref("");
const userList = ref([]);
const userPage = ref(1);
const userPageList = ref([]);
const userHasPrev = ref(false);
const userHasNext = ref(false);
const showDeleteModal = ref(false)
const selectedUser = ref();
const userName = ref(null);
const userGroups = ref([])

const onClickCreate = () => {
  router.push("/admin/users/create")
}

const onClickEdit = (url) => {
  const id = getIdFromURL(url)
  router.push(`/admin/users/${id}/edit`);
};

// delete
const onClickDelete = async (url) => {
  selectedUser.value = url
  showDeleteModal.value = true
}

const handleOnConfirmDelete = async () => {
  const id = getIdFromURL(selectedUser.value);

  await userServices.delete(id);
  await onClickSearch(userPage)
  selectedUser.value = null
  showDeleteModal.value = false
}

const handleOnCancelDelete = () => {
  selectedUser.value = null
  showDeleteModal.value = false
}

// fetching/searching lists
const fetchList = async (page, paramsArg) => {
  const params = {
    ...paramsArg,
    ...(page !== 1 && ({
      limit: 10,
      offset: 10 * (page - 1),
      page: page,
    }))
  }
  
  const resp = await userServices.getList({ params })

  userList.value = resp.results || [];
  userPage.value = page
  userPageList.value = createPageList(resp.count)
  userHasPrev.value = !!resp.previous
  userHasNext.value = !!resp.next
}

const onClickSearch = async (page) => {
  await fetchList(
    page,  
    {
      ...(stringSearch.value && { search: stringSearch.value }),
    }
  )
}

onMounted(async () => {
  const userId = getUserId()
  const resp = await userServices.getDetails(userId)
  userName.value = resp.last_name

  userGroups.value = resp.groups
  if (!resp.groups.includes("Admin")) {
    router.go(-1)
  }

  await fetchList(1)
})
</script>

<style scoped>
.bl-search {
  display: inline-block;
}

.date-search {
  display: inline-block;
  width: 30%;
  margin: 0 10px;
}

.link {
  color: #457b9d;
  text-decoration: underline;
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}
</style>
