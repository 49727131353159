export const countryChoices = [
  { value: "PH", label: "PHILIPPINES" },
  { value: "US", label: "AMERICA" },
  { value: "IN", label: "INDIA" },
  { value: "JP", label: "JAPAN" },
  { value: "CN", label: "CHINA" },
];

export const shortHandMonths = [
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MAY",
  "JUN",
  "JUL",
  "AUG",
  "SEP",
  "OCT",
  "NOV",
  "DEC",
];

export const userAccess = {
  hasAccessToMember: ["All"],
  hasAccessToMemberModify: ["Admin", "Accounting Group"],
  hasAccessToImport: [
    "Admin",
    "Import",
    "Operations Manager",
    "Accounting",
    "HR and Sales",
  ],
  hasAccessToImportModify: ["Admin", "Import", "Operations Manager"],
  hasAccessToExport: [
    "Admin",
    "Export",
    "Operations Manager",
    "Accounting",
    "HR and Sales",
  ],
  hasAccessToExportModify: ["Admin", "Export", "Operations Manager"],
  hasAccessToBilling: ["Admin", "Accounting"],
  hasAccessToBrokerage: [
    "Admin",
    "Brokerage",
    "Operations Manager",
    "Accounting",
    "HR and Sales",
  ],
  hasAccessToBrokerageModify: ["Admin", "Brokerage", "Operations Manager"],
  hasAccessToVoucher: ["All"],
};
