<template>
  <LayoutComponent
    :user-name="userName"
    :user-groups="userGroups"
  >
    <div class="container">
      <PageTitle title="Independent Brokerage - Create" />
      <div v-if="!initialDataSubmitted">
        <form>
          <label for="direction" class="form-label mt-2">Direction</label>
          <div
            id="direction"
            class="form-check"
            v-for="direction in directions"
            :key="direction"
          >
            <input
              class="form-check-input"
              type="radio"
              name="flexRadioDirection"
              :id="direction"
              @change="onChangeSelectedDirection(direction)"
            >
            <label
              class="form-check-label"
              :for="direction"
            >
              {{ direction }}
            </label>
          </div>
          <label for="type" class="form-label mt-2">Type</label>
          <div
            id="type"
            class="form-check"
            v-for="singleType in types"
            :key="singleType"
          >
            <input
              class="form-check-input"
              type="radio"
              name="flexRadioDefault"
              :id="singleType"
              @change="onChangeSelectedType(singleType)"
            >
            <label
              class="form-check-label"
              :for="singleType"
            >
              {{ singleType }}
            </label>
          </div>
          <label for="client" class="form-label mt-2">Client</label>
          <select
            class="form-select"
            id="client"
            aria-label="Client"
            v-model="initialData.client"
          >
            <option
              v-for="client in clients"
              :key="client.url"
              :value="client"
            >
              {{ client.company_name }}
            </option>
          </select>
        </form>
        <button
          type="button"
          class="btn btn-success mt-3"
          @click="handleOnClickNext"
          :disabled="isButtonDisabled"
        >
          Next
        </button>
      </div>
      <div v-else>
        <ImportAirForm
          v-if="
            initialData.type === 'Air'
            && initialData.direction === 'Import'
          "
          :client="initialData.client"
          :selected-transaction = "selectedTransaction"
          mode="independent-brokerage"
        />
        <ImportSeaForm
          v-else-if="
            initialData.direction === 'Import'
          "
          :type="initialData.type"
          :client="initialData.client"
          :selected-transaction = "selectedTransaction"
          mode="independent-brokerage"
        />
        <ExportAirForm
          v-else-if="
            initialData.type === 'Air'
            && initialData.direction === 'Export'
          "
          :type="initialData.type"
          :client="initialData.client"
          :selected-transaction = "selectedTransaction"
          mode="independent-brokerage"
        />
        <ExportSeaForm
          v-else-if="
            initialData.direction === 'Export'
          "
          :type="initialData.type"
          :client="initialData.client"
          :selected-transaction = "selectedTransaction"
          mode="independent-brokerage"
        />
      </div>
    </div>
  </LayoutComponent>
</template>

<script setup>
import { computed, onMounted, ref } from "vue";
import { useRouter } from 'vue-router';

import ExportAirForm from "@/components/ExportAirForm.vue";
import ExportSeaForm from "@/components/ExportSeaForm.vue";
import ImportAirForm from "@/components/ImportAirForm.vue";
import ImportSeaForm from "@/components/ImportSeaForm.vue";
import LayoutComponent from "@/components/LayoutComponent.vue";
import PageTitle from "@/components/PageTitle.vue";

import { clientServices, userServices, } from "@/services";
import { userAccess } from "@/utils/constants";
import { fetchAll, getUserId } from "@/utils/helpers";

const types = ["LCL", "FCL", "Consol", "Air"];
const directions = ["Import", "Export"];

const router = useRouter();

const clients = ref([]);
const initialDataSubmitted = ref(false);
const initialData = ref({
  direction: "",
  type: "",
  client: {},
})
const userName = ref(null);
const userGroups = ref([])

const isButtonDisabled = computed(() => {
  return initialData.value.direction === ""
    || initialData.value.type === ""
    || JSON.stringify(initialData.value.client) === "{}"
})

const onChangeSelectedDirection = (direction) => {
  initialData.value.direction = direction;
}

const onChangeSelectedType = (type) => {
  initialData.value.type = type;
}

onMounted(async () => {
  const userId = getUserId()
  const resp = await userServices.getDetails(userId)
  userName.value = resp.last_name

  userGroups.value = resp.groups
  if (!resp.groups.some(role => userAccess.hasAccessToBrokerageModify.includes(role))) {
    router.go(-1)
  }
  const clientsResp = await clientServices.getList({ params: { is_active: true } });
  
  let tempClients = clientsResp.results
  if (clientsResp.next) {
    const nextRes = await fetchAll(clientsResp.next)
    tempClients = [...tempClients, ...nextRes]
  }
  clients.value = tempClients
})
</script>

<style scoped>
</style>
