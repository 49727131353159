<template>
  <form>
    <div class="mb-3">
      <label for="salesman" class="form-label">Salesman</label>
      <input
        type="text"
        class="form-control"
        id="salesman"
        aria-label="Salesman"
        disabled
        :value="billingForm.salesman"
      >
      <label for="billedTo" class="form-label">Billed To</label>
      <input
        type="text"
        class="form-control"
        id="billedTo"
        aria-label="Billed To"
        disabled
        :value="billingForm.billedTo"
      >
      <label for="tin" class="form-label">TIN</label>
      <input
        type="text"
        class="form-control"
        id="tin"
        aria-label="TIN"
        disabled
        :value="billingForm.tin"
      >
      <label for="address" class="form-label">Address</label>
      <input
        type="text"
        class="form-control"
        id="address"
        aria-label="Address"
        disabled
        :value="billingForm.address"
      >
      <label for="shipper" class="form-label">Shipper</label>
      <input
        type="text"
        class="form-control"
        id="shipper"
        aria-label="Shipper"
        disabled
        :value="billingForm.shipper"
      >
      <label for="consignee" class="form-label">Consignee</label>
      <input
        type="text"
        class="form-control"
        id="consignee"
        aria-label="Consignee"
        disabled
        :value="billingForm.billedTo"
      >
      <label for="originCountry" class="form-label">Origin Country</label>
      <input
        type="text"
        class="form-control" id="originCountry"
        aria-label="Origin Country"
        :value="billingForm.originCountry"
        disabled
      >
      <label for="destinationCountry" class="form-label">Destination Country</label>
      <input
        type="text"
        class="form-control" id="destinationCountry"
        aria-label="Destination Country"
        :value="billingForm.destinationCountry"
        disabled
      >
      <label for="cargoDescription" class="form-label">Cargo Description</label>
      <select
        class="form-select"
        id="cargoDescription"
        aria-label="Cargo Description"
        v-model="billingForm.cargoDescription"
        :disabled="isView"
      >
        <option value="DANGEROUS">DANGEROUS</option>
        <option value="GENERAL">GENERAL CARGO</option>
      </select>
      <label for="mawb" class="form-label">MAWB</label>
      <input
        type="text"
        class="form-control"
        id="mawb"
        aria-label="MAWB"
        disabled
        :value="billingForm.masterAirwayBill"
      >
      <label for="hawb" class="form-label">HAWB</label>
      <input
        type="text"
        class="form-control"
        id="hawb"
        aria-label="HAWB"
        disabled
        :value="billingForm.houseAirwayBill"
      >
      <label for="vesselOrFlightNumber" class="form-label">Flight Number</label>
      <input
        type="text"
        class="form-control"
        id="vesselOrFlightNumber"
        aria-label="Flight Number"
        disabled
        :value="billingForm.flightNumber"
      >
      <label for="etd" class="form-label">ETD</label>
      <input
        type="text"
        class="form-control"
        id="etd"
        aria-label="ETD"
        disabled
        :value="billingForm.ETD"
      >
      <label for="eta" class="form-label">ETA</label>
      <input
        type="text"
        class="form-control"
        id="eta"
        aria-label="ETA"
        disabled
        :value="billingForm.ETA"
      >
      <label for="exchangeRate" class="form-label">Exchange Rate</label>
      <input
        type="text"
        class="form-control"
        id="exchangeRate"
        aria-label="Exchange Rate"
        disabled
        :value="roundNumber(billingForm.usdToPhp)"
      >
      <label for="SOARefNumber" class="form-label">SOA Reference Number</label>
      <input
        type="text"
        class="form-control case-forced"
        id="SOARefNumber"
        aria-label="SOA Reference Number"
        v-model="billingForm.soaReferenceNumber"
        :disabled="isView"
      >
      <label for="BARefNumber" class="form-label">
        Billing Account Reference Number
      </label>
      <input
        type="text"
        class="form-control case-forced"
        id="BARefNumber"
        aria-label="Billing Account Reference Number"
        v-model="billingForm.billingAccountReferenceNumber"
        :disabled="isView"
      >
      <label for="billingStatementDocumentNumber" class="form-label">Billing Statement Document Number</label>
      <input
        type="text"
        class="form-control case-forced"
        id="billingStatementDocumentNumber"
        aria-label="Billing Statement Document Number"
        v-model="billingForm.billingStatementDocumentNumber"
        :disabled="isView"
      >
      <label for="soaDocumentNumber" class="form-label">SOA Document Number</label>
      <input
        type="text"
        class="form-control case-forced"
        id="soaDocumentNumber"
        aria-label="SOA Document Number"
        v-model="billingForm.soaDocumentNumber"
        :disabled="isView"
      >
      <div class="d-flex align-items-center mt-2">
        <h4 class="me-3">Rates</h4>
        <button
          v-if="!isView"
          type="button"
          class="btn button-primary"
          @click="addRateRow"
        >
          Add Rate
        </button>
      </div>
      <table class="table">
        <thead>
          <tr>
            <th scope="col">Mode Of Shipment</th>
            <th scope="col">Description</th>
            <th scope="col">Rate</th>
            <th scope="col">Unit</th>
            <th scope="col">Taxable</th>
            <th scope="col">Action/s</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(rate, index) in rateRows" :key="index">
            <td>
              <select
                class="form-select"
                id="modeOfShipment"
                aria-label="Mode Of Shipment"
                v-model="rate.modeOfShipment"
                disabled
              >
                <option
                  v-for="mos in modeOfShipmentList"
                  :value="mos.value"
                  :key="mos.value"
                >
                  {{mos.label}}
                </option>
              </select>
            </td>
            <td>
              <textarea 
                class="form-control"
                id="description"
                aria-label="Description"
                rows="3"
                v-model="rate.description"
                :disabled="isView"
              ></textarea>
            </td>
            <td>
              <input
                type="number"
                class="form-control"
                id="rate"
                aria-label="Rate"
                v-model="rate.rate"
                :disabled="isView"
              >
            </td>
            <td>
              <select
                class="form-select"
                id="unit"
                aria-label="Unit"
                v-model="rate.unit"
                :disabled="isView"
              >
                <option
                  v-for="rateUnit in rateUnitsList"
                  :value="rateUnit.url"
                  :key="rateUnit.label"
                >
                  {{rateUnit.label}}
                </option>
              </select>
            </td>
            <td>
              <input
                type="checkbox"
                class="form-check-input ms-2"
                id="taxType"
                aria-label="Taxable"
                v-model="rate.taxable"
                :disabled="isView"
              >
            </td>
            <td>
              <button
                type="button"
                class="btn btn-danger"
                @click="deleteRateRow(index)"
                :disabled="isView"
              >
                Delete
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <label for="total" class="form-label">Total Billed Charge</label>
      <input
        type="text"
        class="form-control"
        id="total"
        aria-label="Total Billed Charge"
        disabled
        :value="billingForm.totalBilledCharges">
      <label for="nominalCost" class="form-label">Nominal Cost</label>
      <input
        type="number"
        class="form-control"
        id="nominalCost"
        aria-label="Nominal Cost"
        v-model="billingForm.nominalCost"
        :disabled="isView"
      >
      <label for="profit" class="form-label">Projected Profit</label>
      <input
        type="text"
        class="form-control"
        id="profit"
        aria-label="Projected Profit"
        disabled
        v-model="billingForm.projectedProfit"
      >
    </div>
    <div v-if="!isView">
      <button
        type="button"
        class="btn btn-secondary me-3"
        @click="handleOnSubmit('in_progress')"
      >
        Save as Draft
      </button>
      <button
      type="button"
      class="btn button-primary"
      @click="handleOnSubmit('submitted')"
      >
        Submit
      </button>
    </div>
  </form>
</template>

<script setup>
import { computed, defineProps, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { roundNumber } from "@/utils/helpers";
import { billServices, clientServices, rawPatch } from "@/services";

const route = useRoute()
const router = useRouter();
const props = defineProps([
  "billing",
  "category",
  "isView",
  "modeOfShipment",
  "rates",
  "transaction",
])
const rateRows = ref([]);
const modeOfShipmentList = ref([])
const rateUnitsList = ref([])

const totalBilledCharge = computed(() => {
  const val = rateRows.value?.reduce(
      (currentSum, rate) => currentSum + rate.rate,
      0
    )
  return val
})

const projectedProfit = computed(() => {
  return Math.round((totalBilledCharge.value - billingForm.value.nominalCost) * 100) / 100
})

const billingForm = ref({
  destinationCountry: "",
  ETA: new Date(),
  ETD: new Date(),
  flightNumber: "",
  houseAirwayBill: "",
  masterAirwayBill: "",
  originCountry: "",
  usdToPhp: 0,
  
  // billing
  address: "",
  billedTo: "",
  billingAccountReferenceNumber: "",
  cargoDescription: "",
  consignee: {},
  nominalCost: 0,
  projectedProfit: projectedProfit,
  salesman: "",
  shipper: "",
  soaReferenceNumber: "",
  tin: "",
  totalBilledCharges: totalBilledCharge,
  billingStatementDocumentNumber: "",
  soaDocumentNumber: "",
})

const addRateRow = () => {
  rateRows.value.push({
    url: "client-rates",
    modeOfShipment: props.modeOfShipment,
    description: "",
    rate: 0,
    unit: "",
    taxable: false,
    edited: false,
  });
}

const deleteRateRow = (index) => {
  const tempRates = structuredClone(rateRows.value)
  tempRates.splice(index, 1)
  rateRows.value = tempRates;
}

watch
// watch(
//   () => rateRows.value,
//   (newVal, oldVal) => {
//     console.log(newVal, oldVal)
//     if (Object.keys(oldVal).length === Object.keys(newVal).length) {
//       newVal.forEach((newRate, index) => {
//         newRate.edited = JSON.stringify(newRate) !== JSON.stringify(oldVal[index])
//         if (index === 0) {
//           console.log(newRate)
//           console.log(oldVal[index])
//         }
//       });

//       // rateRows.value = newVal;
//     }
//   },
//   { deep: true }
// )

const handleOnSubmit = async (status) => {
  const id = route.params.id;

  await billServices.update({
    address: billingForm.value.address,
    billedTo: billingForm.value.billedTo,
    billingAccountReferenceNumber: billingForm.value.billingAccountReferenceNumber?.toUpperCase() || "",
    billingStatementDocumentNumber: billingForm.value.billingStatementDocumentNumber?.toUpperCase() || "",
    cargoDescription: billingForm.value.cargoDescription?.toUpperCase() || "",
    nominalCost: billingForm.value.nominalCost,
    projectedProfit: billingForm.value.projectedProfit,
    salesman: billingForm.value.salesman,
    shipper: billingForm.value.shipper,
    soaDocumentNumber: billingForm.value.soaDocumentNumber?.toUpperCase() || "",
    soaReferenceNumber: billingForm.value.soaReferenceNumber?.toUpperCase() || "",
    tin: billingForm.value.tin,
    totalBilledCharge: billingForm.value.totalBilledCharge,
    transaction: billingForm.value.transaction.url,
    id,
  });

  for (let index = 0; index < rateRows.value.length; index++) {
    const rate = rateRows.value[index];
    const data = {
      description: rate.description?.toUpperCase() || "",
      rate: rate.rate,
      unit: rate.unit,
      mode_of_shipment: rate.modeOfShipment,
      is_taxable: rate.taxable,
    }

    if (rate.url?.includes('client-rates')) {
      await billServices.createRate({
        id,
        data,
      })
    } else {
      // TODO FE: optimize, do not patch all, just the updated ones
      // create a list of og rates to compare
      await rawPatch({
        url: rate.url,
        data,
      })
    }
  }

  if (status === 'submitted') {
    await billServices.submit({ id })
  }
  router.push("/billings");
}

const populateBillingForm = (transaction, billing) => {
  billingForm.value.destinationCountry = transaction.destination_country_name
  billingForm.value.ETA = new Date(transaction.estimated_time_of_arrival)
  billingForm.value.ETD = new Date(transaction.estimated_time_of_departure)
  billingForm.value.flightNumber = transaction.flight_number
  billingForm.value.houseAirwayBill = transaction.house_airway_bill_number
  billingForm.value.masterAirwayBill = transaction.master_airway_bill_number
  billingForm.value.originCountry = transaction.origin_country_name
  billingForm.value.usdToPhp = roundNumber(transaction.usd_to_php_conversion)
  billingForm.value.address = billing.address
  billingForm.value.billedTo = billing.billed_to
  billingForm.value.billingAccountReferenceNumber =
    billing.billing_account_reference_number
  billingForm.value.cargoDescription = billing.cargo_description
  billingForm.value.consignee = billing.consignee
  billingForm.value.nominalCost = roundNumber(billing.nominal_cost)
  billingForm.value.salesman = billing.salesman
  billingForm.value.shipper = billing.shipper
  billingForm.value.soaReferenceNumber = billing.soa_reference_number
  billingForm.value.tin = billing.tin
  billingForm.value.transaction = transaction
  billingForm.value.billingStatementDocumentNumber = billing.billingStatementDocumentNumber
  billingForm.value.soaDocumentNumber = billing.soaDocumentNumber
}

onMounted(async () => {
  populateBillingForm(props.transaction, props.billing)

  // populate rates
  rateRows.value = props.rates.map(rate => ({
    ...rate,
    modeOfShipment: rate.mode_of_shipment,
    description: rate.description,
    taxable: rate.is_taxable,
    rate: Number(rate.rate),
    edited: false,
  }))

  // populate dropdowns
  // mode of shipments
  const tempMos = await clientServices.getModeOfShipments();
  modeOfShipmentList.value = tempMos?.map((mos) => ({
    label: mos.code,
    value: mos.url
  }))

  // rate units
  rateUnitsList.value = await clientServices.getRateUnits();
})
</script>

<style scoped>
input.case-forced {
  text-transform: uppercase;
}
textarea {
  text-transform: uppercase;
}
</style>
