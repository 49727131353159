<template>
  <RouterView />
</template>

<script>

export default  {
  name: 'App',
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.bg-green-primary {
  background-color: #204520;
}

.button-primary {
  background-color: #01703b !important;
  color: #e9ffdb;
}

.button-primary:hover {
  background-color: #6bbf2f !important;
}

.button-primary:active {
  box-shadow: inset 0 1px 2px rgba(0,0,0,.35) !important;
}

.w-30 {
  width: 30%;
}
</style>
