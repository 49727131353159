<template>
  <form>
    <div class="mb-3">
      <label for="mawb" class="form-label">Master Airway Bill Number (MAWB)</label>
      <input
        type="text"
        class="form-control"
        :class="errors.mawb ? 'error' : ''"
        id="mawb"
        aria-label="Master Airway Bill Number (MAWB)"
        v-model="transportTransaction.mawb"
        :disabled="isInputDisabled"
      >
      <InputErrorLabel :show="errors.mawb" />
      <label for="registryNumber" class="form-label">Registry Number</label>
      <input
        type="text"
        class="form-control"
        :class="errors.registryNumber ? 'error' : ''"
        id="registryNumber"
        aria-label="Registry Number"
        v-model="transportTransaction.registryNumber"
        :disabled="isInputDisabled"
      >
      <InputErrorLabel :show="errors.registryNumber" />
      <label for="foreignAgent" class="form-label">Foreign Agent</label>
      <input
        type="text"
        class="form-control"
        :class="errors.foreignAgent ? 'error' : ''"
        id="foreignAgent"
        aria-label="Foreign Agent"
        v-model="transportTransaction.foreignAgent"
        :disabled="isInputDisabled"
      >
      <InputErrorLabel :show="errors.foreignAgent" />
      <label for="originCountry" class="form-label">Origin Country</label>
      <select
        class="form-select"
        :class="errors.originCountry ? 'error' : ''"
        id="originCountry"
        aria-label="Origin Country"
        v-model="transportTransaction.originCountry"
        :disabled="isInputDisabled"
      >
        <option
          v-for="cof in countryChoices"
          :value="cof.value"
          :key="cof.value"
        >
          {{ cof.label }}
        </option>
      </select>
      <InputErrorLabel :show="errors.originCountry" />
      <label for="portOfLoading" class="form-label">Port Of Loading</label>
      <input
        type="text"
        class="form-control"
        :class="errors.portOfLoading ? 'error' : ''"
        id="portOfLoading"
        aria-label="Port Of Loading"
        v-model="transportTransaction.portOfLoading"
        :disabled="isInputDisabled"
      >
      <InputErrorLabel :show="errors.portOfLoading" />
      <label for="destinationCountry" class="form-label">Destination Country</label>
      <input
        type="text"
        class="form-control"
        id="destinationCountry"
        aria-label="Destination Country"
        disabled
        v-model="transportTransaction.destinationCountryFiller"
      >
      <label for="portOfDischarge" class="form-label">Port Of Discharge</label>
      <select
        class="form-select"
        :class="errors.portOfDischarge ? 'error' : ''"
        id="portOfDischarge"
        aria-label="Port Of Discharge"
        v-model="transportTransaction.portOfDischarge"
        :disabled="isInputDisabled"
      >
        <option value="MANILA">MANILA</option>
        <option value="CEBU">CEBU</option>
        <option value="CLARK">CLARK</option>
        <option value="DAVAO">DAVAO</option>
      </select>
      <InputErrorLabel :show="errors.portOfDischarge" />
      <label for="flightNumber" class="form-label">Flight Number</label>
      <input
        type="text"
        class="form-control"
        :class="errors.flightNumber ? 'error' : ''"
        id="flightNumber"
        aria-label="Flight Number"
        v-model="transportTransaction.flightNumber"
        :disabled="isInputDisabled"
      >
      <InputErrorLabel :show="errors.flightNumber" />
      <label for="carrierNumber" class="form-label">Carrier Number</label>
      <input
        type="text"
        class="form-control"
        :class="errors.carrierNumber ? 'error' : ''"
        id="carrierNumber"
        aria-label="Carrier Number"
        v-model="transportTransaction.carrierNumber"
        :disabled="isInputDisabled"
      >
      <InputErrorLabel :show="errors.carrierNumber" />
      <label for="coloader" class="form-label">Coloader</label>
      <input
        type="text"
        class="form-control"
        :class="errors.coloader ? 'error' : ''"
        id="coloader"
        aria-label="Coloader"
        v-model="transportTransaction.coloader"
        :disabled="isInputDisabled"
      >
      <InputErrorLabel :show="errors.coloader" />
      <label for="warehouse" class="form-label">Warehouse</label>
      <input
        type="text"
        class="form-control"
        :class="errors.warehouse ? 'error' : ''"
        id="warehouse"
        aria-label="Warehouse"
        v-model="transportTransaction.warehouse"
        :disabled="isInputDisabled"
      >
      <InputErrorLabel :show="errors.warehouse" />
      <label for="totalGrossWeight" class="form-label">Total Gross Weight (kg)</label>
      <input
        type="number"
        class="form-control"
        :class="errors.totalGrossWeight ? 'error' : ''"
        id="totalGrossWeight"
        aria-label="Total Gross Weight (kg)"
        v-model="transportTransaction.totalGrossWeight"
        :disabled="isInputDisabled"
      >
      <InputErrorLabel :show="errors.totalGrossWeight" />
      <label for="totalChargeableWeight" class="form-label">Total Chargeable Weight(kg)</label>
      <input
        type="number"
        class="form-control"
        :class="errors.totalChargeableWeight ? 'error' : ''"
        id="totalChargeableWeight"
        aria-label="Total Chargeable Weight(kg)"
        v-model="transportTransaction.totalChargeableWeight"
        :disabled="isInputDisabled"
      >
      <InputErrorLabel :show="errors.totalChargeableWeight" />
      <label for="totalVolume" class="form-label">Total Volume (cbm)</label>
      <input
        type="number"
        class="form-control"
        :class="errors.totalVolume ? 'error' : ''"
        id="totalVolume"
        aria-label="Total Volume (cbm)"
        v-model="transportTransaction.totalVolume"
        :disabled="isInputDisabled"
      >
      <InputErrorLabel :show="errors.totalVolume" />
      <label for="dateETD" class="form-label">Estimated Time Of Departure</label>
      <VueDatePicker
        :input-class-name="errors.dateETD ? 'error' : ''"
        v-model="transportTransaction.dateETD"
        id="dateETD"
        aria-label="Estimated Time Of Departure"
        :disabled="isInputDisabled"
        :enable-time-picker="false"
      />
      <InputErrorLabel :show="errors.dateETD" />
      <label for="dateETA" class="form-label">Estimated Time Of Arrival</label>
      <VueDatePicker
        :input-class-name="errors.dateETA ? 'error' : ''"
        v-model="transportTransaction.dateETA"
        id="dateETA"
        aria-label="Estimated Time Of Arrival"
        :disabled="isInputDisabled"
        :enable-time-picker="false"
      />
      <InputErrorLabel :show="errors.dateETA" />
      <label for="dateATA" class="form-label">Actual Time Of Arrival</label>
      <VueDatePicker
        :input-class-name="errors.dateATA ? 'error' : ''"
        v-model="transportTransaction.dateATA"
        id="dateATA"
        aria-label="Actual Time Of Arrival"
        :disabled="isInputDisabled"
        :enable-time-picker="false"
      />
      <InputErrorLabel :show="errors.dateATA" />
      <label for="dateATB" class="form-label">Actual Time Of Berthing</label>
      <VueDatePicker
        :input-class-name="errors.dateATB ? 'error' : ''"
        v-model="transportTransaction.dateATB"
        id="dateATB"
        aria-label="Actual Time Of Berthing"
        :disabled="isInputDisabled"
        :enable-time-picker="false"
      />
      <InputErrorLabel :show="errors.dateATB" />
      <!-- transaction type -->
      <label for="transactionType" class="form-label">Transaction Type</label>
      <input
        type="text"
        class="form-control case-not-forced"
        id="transactionType"
        aria-label="Transaction Type"
        v-model="transportTransaction.transactionType"
        disabled
      >
      <label for="salesman" class="form-label">Salesman</label>
      <input
        type="text"
        class="form-control case-not-forced"
        id="salesman"
        aria-label="Salesman"
        v-model="transportTransaction.salesman"
        disabled
      >
      <label for="consignee" class="form-label">Consignee</label>
      <input
        type="text"
        class="form-control case-not-forced"
        id="consignee"
        aria-label="Consignee"
        v-model="transportTransaction.consigneeValue"
        disabled
      >
      <label for="shipper" class="form-label">Shipper</label>
      <textarea
        class="form-control"
        :class="errors.shipper ? 'error' : ''"
        id="shipper"
        aria-label="Shipper"
        rows="3"
        v-model="transportTransaction.shipper"
        :disabled="isInputDisabled"
      ></textarea>
      <InputErrorLabel :show="errors.shipper" />
      <label for="shipperAddress" class="form-label">Shipper Address</label>
      <textarea
        class="form-control"
        id="shipperAddress"
        aria-label="Shipper Address"
        rows="3"
        v-model="transportTransaction.shipperAddress"
        :disabled="isInputDisabled"
      ></textarea>
      <label for="notifyParty" class="form-label">Notify Party</label>
      <input
        type="text"
        class="form-control"
        :class="errors.notifyParty ? 'error' : ''"
        id="notifyParty"
        aria-label="Notify Party"
        v-model="transportTransaction.notifyParty"
        :disabled="isInputDisabled"
      >
      <InputErrorLabel :show="errors.notifyParty" />
      <label for="thirdParty" class="form-label">Third Party</label>
      <input
        type="text"
        class="form-control"
        :class="errors.thirdParty ? 'error' : ''"
        id="thirdParty"
        aria-label="Third Party"
        v-model="transportTransaction.thirdParty"
        :disabled="isInputDisabled"
      >
      <InputErrorLabel :show="errors.thirdParty" />
      <label for="hawb" class="form-label">House Airway Bill Number (HAWB)</label>
      <input
        type="text"
        class="form-control"
        :class="errors.hawb ? 'error' : ''"
        id="hawb"
        aria-label="House Airway Bill Number (HAWB)"
        v-model="transportTransaction.hawb"
        :disabled="isInputDisabled"
      >
      <InputErrorLabel :show="errors.hawb" />
      <label for="blNatureCode" class="form-label">BL Nature Code</label>
      <select
        class="form-select"
        :class="errors.blNatureCode ? 'error' : ''"
        id="blNatureCode"
        aria-label="BL Nature Code"
        v-model="transportTransaction.blNatureCode"
        :disabled="isInputDisabled"
      >
        <option value="23">23</option>
        <option value="24">24</option>
      </select>
      <InputErrorLabel :show="errors.blNatureCode" />
      <label for="destinationCountry" class="form-label">Destination Country</label>
      <input
        type="text"
        class="form-control"
        :class="errors.destinationCountryFiller ? 'error' : ''"
        id="destinationCountry"
        aria-label="Destination Country"
        disabled
        v-model="transportTransaction.destinationCountryFiller"  
      >
      <InputErrorLabel :show="errors.destinationCountryFiller" />
      <label for="portOfDischarge" class="form-label">Port Of Discharge</label>
      <select
        class="form-select"
        :class="errors.portOfDischarge ? 'error' : ''"
        id="portOfDischarge"
        aria-label="Port Of Discharge"
        v-model="transportTransaction.portOfDischarge"
        :disabled="isInputDisabled"
      >
        <option value="MANILA">MANILA</option>
        <option value="CEBU">CEBU</option>
        <option value="CLARK">CLARK</option>
        <option value="DAVAO">DAVAO</option>
      </select>
      <InputErrorLabel :show="errors.portOfDischarge" />
      <label for="packagingType" class="form-label">Packaging Type</label>
      <select
        class="form-select"
        :class="errors.packagingType ? 'error' : ''"
        id="packagingType"
        aria-label="Packaging Type"
        v-model="transportTransaction.packagingType"
        :disabled="isInputDisabled"
      >
        <option v-for="pt in packagingTypeChoices" :key="pt.url" :value="pt.url">{{ pt.label }}</option>
        <!-- TODO FE: handle others -->
        <option value="others">Others</option>
      </select>
      <InputErrorLabel :show="errors.packagingType" />
      <label for="natureOfGoods" class="form-label">Nature Of Goods</label>
      <select
        class="form-select"
        :class="errors.natureOfGoods ? 'error' : ''"
        id="natureOfGoods"
        aria-label="Nature Of Goods"
        v-model="transportTransaction.natureOfGoods"
        :disabled="isInputDisabled"
      >
        <option value="DANGEROUS">DANGEROUS</option>
        <option value="GENERAL">GENERAL CARGO</option>
      </select>
      <InputErrorLabel :show="errors.natureOfGoods" />
      <label for="ttGrossWeight" class="form-label">Gross Weight</label>
      <input
        type="number"
        class="form-control"
        :class="errors.ttGrossWeight ? 'error' : ''"
        id="ttGrossWeight"
        aria-label="Gross Weight"
        v-model="transportTransaction.ttGrossWeight"
        :disabled="isInputDisabled"
      >
      <InputErrorLabel :show="errors.ttGrossWeight" />
      <label for="ttChargeableWeight" class="form-label">Chargeable Weight</label>
      <input
        type="number"
        class="form-control"
        :class="errors.ttChargeableWeight ? 'error' : ''"
        id="ttChargeableWeight"
        aria-label="Chargeable Weight"
        v-model="transportTransaction.ttChargeableWeight"
        :disabled="isInputDisabled"
      >
      <InputErrorLabel :show="errors.ttChargeableWeight" />
      <label for="ttNetWeight" class="form-label">Net Weight</label>
      <input
        type="number"
        class="form-control"
        :class="errors.ttNetWeight ? 'error' : ''"
        id="ttNetWeight"
        aria-label="Net Weight"
        v-model="transportTransaction.ttNetWeight"
        :disabled="isInputDisabled"
      >
      <InputErrorLabel :show="errors.ttNetWeight" />
      <label for="itemDescription" class="form-label">Item Description</label>
      <textarea
        class="form-control"
        :class="errors.itemDescription ? 'error' : ''"
        id="itemDescription"
        aria-label="Item Description"
        rows="3"
        v-model="transportTransaction.itemDescription"
        :disabled="isInputDisabled"
      ></textarea>
      <InputErrorLabel :show="errors.itemDescription" />
      <label for="dateABRD" class="form-label">Airway Bill Received Date</label>
      <VueDatePicker
        :input-class-name="errors.dateABRD ? 'error' : ''"
        v-model="transportTransaction.dateABRD"
        id="dateABRD"
        aria-label="Airway Bill Received Date"
        :disabled="isInputDisabled"
        :enable-time-picker="false"
      />
      <InputErrorLabel :show="errors.dateABRD" />
      <label for="dateDRD" class="form-label">Document Request Date</label>
      <VueDatePicker
        :input-class-name="errors.dateDRD ? 'error' : ''"
        v-model="transportTransaction.dateDRD"
        id="dateDRD"
        aria-label="Document Request Date"
        :disabled="isInputDisabled"
        :enable-time-picker="false"
      />
      <InputErrorLabel :show="errors.dateDRD" />
      <label for="dateAND" class="form-label">Arrival Notice Date</label>
      <VueDatePicker
        :input-class-name="errors.dateAND ? 'error' : ''"
        v-model="transportTransaction.dateAND"
        id="dateAND"
        aria-label="Arrival Notice Date"
        :disabled="isInputDisabled"
        :enable-time-picker="false"
      />
      <InputErrorLabel :show="errors.dateAND" />
      <label for="quantity" class="form-label">Quantity</label>
      <input
        type="number"
        class="form-control"
        :class="errors.quantity ? 'error' : ''"
        id="quantity"
        aria-label="Quantity"
        v-model="transportTransaction.quantity"
        :disabled="isInputDisabled"
      >
      <InputErrorLabel :show="errors.quantity" />
      <label for="incoterm" class="form-label">Incoterm</label>
      <select
        class="form-select"
        :class="errors.incoterm ? 'error' : ''"
        id="incoterm"
        aria-label="Incoterm"
        v-model="transportTransaction.incoterm"
        :disabled="isInputDisabled"
      >
        <option
          v-for="incoterm in incotermChoices"
          :key="incoterm.url"
          :value="incoterm.url"
          :disabled="isInputDisabled"
        >
            {{ `${incoterm.label} (${incoterm.code})` }}
        </option>
      </select>
      <InputErrorLabel :show="errors.incoterm" />
      <label for="marksAndNumbers" class="form-label">Marks and Numbers</label>
      <textarea
        class="form-control"
        :class="errors.marksAndNumbers ? 'error' : ''"
        id="marksAndNumbers"
        aria-label="Marks and Numbers"
        rows="3"
        v-model="transportTransaction.marksAndNumbers"
        :disabled="isInputDisabled"
      ></textarea>
      <InputErrorLabel :show="errors.marksAndNumbers" />
      <label for="usdToPhp" class="form-label">USD to PHP</label>
      <input
        type="number"
        class="form-control"
        :class="errors.usdToPHP ? 'error' : ''"
        id="usdToPhp"
        aria-label="USD to PHP"
        v-model="transportTransaction.usdToPHP"
        :disabled="isInputDisabled"
      >
      <InputErrorLabel :show="errors.usdToPHP" />
      <label for="freightType" class="form-label">Freight Type</label>
      <select
        class="form-select"
        :class="errors.freightType ? 'error' : ''"
        id="freightType"
        aria-label="Freight Type"
        v-model="transportTransaction.freightType"
        :disabled="isInputDisabled"
      >
        <option
          v-for="ft in freightTypeChoices"
          :key="ft.url"
          :value="ft.url"
        >
          {{ ft.name }}
        </option>
      </select>
      <InputErrorLabel :show="errors.freightType" />
    </div>
    <button
      v-if="!isView && props.mode !== 'brokerage'"
      type="button"
      class="btn btn-secondary me-3"
      @click="handleOnSubmit('in_progress')"
    >
      Save as Draft
    </button>
    <button
      v-if="!isView"
      type="button"
      class="btn button-primary"
      @click="handleOnSubmit('submitted')"
    >
      Submit
    </button>
  </form>
</template>

<script setup>
import { computed, defineProps, onMounted, ref } from "vue";
import { useRouter } from 'vue-router';

import InputErrorLabel from "@/components/InputErrorLabel.vue";

import {
  billServices,
  brokerageServices,
  rawPatch,
  transportServices
} from "@/services";
import { countryChoices } from "@/utils/constants";
import { getIdFromURL } from "@/utils/helpers";

const router = useRouter();
const props = defineProps([
  "client",
  "isEdit",
  "isView",
  "mode",
  "selectedTransaction",
  "type",
]);
const incotermChoices = ref([]);
const packagingTypeChoices = ref([]);
const freightTypeChoices = ref([]);
const client = ref({ ...props.client });
const transportTransaction = ref({
  blNatureCode: props.selectedTransaction?.bl_natural_code,
  coloader: props.selectedTransaction?.coloader,
  consignee: props.client?.url,
  consigneeValue: props.client?.company_name,
  dateAND: props.selectedTransaction?.arrival_notice_date ? new Date(props.selectedTransaction.arrival_notice_date) : null,
  dateATA: props.selectedTransaction?.actual_time_of_arrival ? new Date(props.selectedTransaction.actual_time_of_arrival) : null,
  dateATB: props.selectedTransaction?.actual_time_of_berthing ? new Date(props.selectedTransaction.actual_time_of_berthing) : null,
  dateDRD: props.selectedTransaction?.document_request_date ? new Date(props.selectedTransaction.document_request_date) : null,
  dateETA: props.selectedTransaction?.estimated_time_of_arrival ? new Date(props.selectedTransaction.estimated_time_of_arrival) : null,
  dateETD: props.selectedTransaction?.estimated_time_of_departure ? new Date(props.selectedTransaction.estimated_time_of_departure) : null,
  destinationCountry: "PH",
  destinationCountryFiller: "PHILIPPINES",
  foreignAgent: props.selectedTransaction?.foreign_agent,
  incoterm: props.selectedTransaction?.incoterm,
  itemDescription: props.selectedTransaction?.item_description,
  marksAndNumbers: props.selectedTransaction?.marks_and_numbers,
  notifyParty: props.selectedTransaction?.notify_party,
  originCountry: props.selectedTransaction?.origin_country,
  portOfDischarge: props.selectedTransaction?.port_of_discharge,
  portOfLoading: props.selectedTransaction?.port_of_loading,
  registryNumber: props.selectedTransaction?.registry_number,
  salesman: props.client?.salesman,
  shipper: props.selectedTransaction?.shipper,
  shipperAddress: props.selectedTransaction?.shipper_address,
  thirdParty: props.selectedTransaction?.third_party,
  totalGrossWeight: props.selectedTransaction?.total_gross_weight,
  totalVolume: props.selectedTransaction?.total_volume,
  type: "import",
  ttGrossWeight: props.selectedTransaction?.gross_weight,
  ttNetWeight: props.selectedTransaction?.net_weight,
  usdToPHP: props.selectedTransaction?.usd_to_php_conversion,
  warehouse: props.selectedTransaction?.warehouse,

  // AirTransportTransaction
  carrierNumber: props.selectedTransaction?.carrier_number,
  transactionType: "AIR", // save as category in DB
  category: "url for air", // fkey to TransportTransactionCategory
  dateABRD: props.selectedTransaction?.airway_bill_received_date ? new Date(props.selectedTransaction.airway_bill_received_date) : null,
  flightNumber: props.selectedTransaction?.flight_number,
  freightType: props.selectedTransaction?.freight_type,
  hawb: props.selectedTransaction?.house_airway_bill_number,
  mawb: props.selectedTransaction?.master_airway_bill_number,
  natureOfGoods: props.selectedTransaction?.nature_of_goods,
  packagingType: props.selectedTransaction?.packaging_type,
  quantity: props.selectedTransaction?.quantity,
  totalChargeableWeight: props.selectedTransaction?.total_chargeable_weight,
  // shipper details???? meron sa BE

  ttChargeableWeight: 0,
});
const errors = ref({})

const isInputDisabled = computed(() => {
  if (props.mode === "brokerage") return true
  if (props.isView) return true
  return false
})

const mapImportTransactionData = ({
  blNatureCode,
  coloader,
  consignee,
  dateAND,
  dateATA,
  dateATB,
  dateDRD,
  dateETA,
  dateETD,
  destinationCountry,
  foreignAgent,
  incoterm,
  itemDescription,
  marksAndNumbers,
  notifyParty,
  originCountry,
  portOfDischarge,
  portOfLoading,
  registryNumber,
  salesman,
  shipper,
  shipperAddress,
  thirdParty,
  totalGrossWeight,
  totalVolume,
  type,
  ttGrossWeight,
  ttNetWeight,
  usdToPHP,
  warehouse,

  carrierNumber,
  category,
  dateABRD,
  flightNumber,
  freightType,
  hawb,
  mawb,
  natureOfGoods,
  packagingType,
  quantity,
  totalChargeableWeight,
}) => {
  // todo add default blank strings
  return {
    bl_natural_code: blNatureCode,
    coloader: coloader?.toUpperCase() || "",
    consignee: consignee,
    arrival_notice_date: dateAND?.toISOString(),
    actual_time_of_arrival: dateATA?.toISOString(),
    actual_time_of_berthing: dateATB?.toISOString(),
    document_request_date: dateDRD?.toISOString(),
    estimated_time_of_departure: dateETD?.toISOString(),
    estimated_time_of_arrival: dateETA?.toISOString(),
    destination_country: destinationCountry,
    foreign_agent: foreignAgent?.toUpperCase() || "",
    gross_weight: ttGrossWeight,
    incoterm: incoterm,
    item_description: itemDescription?.toUpperCase() || "",
    marks_and_numbers: marksAndNumbers?.toUpperCase() || "",
    net_weight: ttNetWeight,
    notify_party: notifyParty?.toUpperCase() || "",
    origin_country: originCountry,
    port_of_discharge: portOfDischarge?.toUpperCase() || "",
    port_of_loading: portOfLoading?.toUpperCase() || "",
    registry_number: registryNumber?.toUpperCase() || "",
    salesman,
    shipper: shipper?.toUpperCase() || "",
    shipper_address: shipperAddress?.toUpperCase() || "",
    third_party: thirdParty?.toUpperCase() || "",
    total_gross_weight: totalGrossWeight,
    total_volume: totalVolume,
    type: type,
    usd_to_php_conversion: usdToPHP,
    warehouse: warehouse?.toUpperCase() || "",

    airway_bill_received_date: dateABRD?.toISOString(),
    carrier_number: carrierNumber?.toUpperCase() || "",
    category: category,
    flight_number: flightNumber?.toUpperCase() || "",
    freight_type: freightType,
    house_airway_bill_number: hawb?.toUpperCase() || "",
    master_airway_bill_number: mawb?.toUpperCase() || "",
    nature_of_goods: natureOfGoods,
    packaging_type: packagingType,
    quantity: quantity,
    total_chargeable_weight: totalChargeableWeight,
  };
}

const validateData = ({
  blNatureCode,
  coloader,
  dateAND,
  dateATA,
  dateATB,
  dateDRD,
  dateETA,
  dateETD,
  destinationCountry,
  foreignAgent,
  incoterm,
  itemDescription,
  marksAndNumbers,
  notifyParty,
  originCountry,
  portOfDischarge,
  portOfLoading,
  registryNumber,
  shipper,
  shipperAddress,
  thirdParty,
  totalGrossWeight,
  totalVolume,
  type,
  ttGrossWeight,
  ttNetWeight,
  usdToPHP,
  warehouse,

  carrierNumber,
  category,
  dateABRD,
  flightNumber,
  freightType,
  hawb,
  mawb,
  natureOfGoods,
  packagingType,
  quantity,
  totalChargeableWeight,
}) => {
  if (!blNatureCode) {
    errors.value.blNatureCode = "Invalid BL Nature Code"
  }
  if (!coloader) {
    errors.value.coloader = "Invalid Coloader"
  }
  if (!dateAND) {
    errors.value.dateAND = "Invalid Arrival Notice Date"
  }
  if (!dateATA) {
    errors.value.dateATA = "Invalid Actual Time Of Arrival"
  }
  if (!dateATB) {
    errors.value.dateATB = "Invalid Actual Time Of Berthing"
  }
  if (!dateDRD) {
    errors.value.dateDRD = "Invalid Document Request Date"
  }
  if (!dateETD) {
    errors.value.dateETD = "Invalid Estimated Time Of Departure"
  }
  if (!dateETA) {
    errors.value.dateETA = "Invalid Estimated Time Of Arrival"
  }
  if (!destinationCountry) {
    errors.value.destinationCountry = "Invalid Destination Country"
  }
  if (!foreignAgent) {
    errors.value.foreignAgent = "Invalid Foreign Agent"
  }
  if (!ttGrossWeight) {
    errors.value.ttGrossWeight = "Invalid Gross Weight"
  }
  if (!incoterm) {
    errors.value.incoterm = "Invalid Incoterm"
  }
  if (!itemDescription) {
    errors.value.itemDescription = "Invalid Item Description"
  }
  if (!marksAndNumbers) {
    errors.value.marksAndNumbers = "Invalid Marks And Numbers"
  }
  if (!ttNetWeight) {
    errors.value.ttNetWeight = "Invalid Net Weight"
  }
  if (!notifyParty) {
    errors.value.notifyParty = "Invalid Notify Party"
  }
  if (!originCountry) {
    errors.value.originCountry = "Invalid origin Country"
  }
  if (!portOfDischarge) {
    errors.value.portOfDischarge = "Invalid Port Of Discharge"
  }
  if (!portOfLoading) {
    errors.value.portOfLoading = "Invalid Port Of Loading"
  }
  if (!registryNumber) {
    errors.value.registryNumber = "Invalid Registry Number"
  }
  if (!shipper) {
    errors.value.shipper = "Invalid Shipper"
  }
  if (!shipperAddress) {
    errors.value.shipperAddress = "Invalid Shipper Address"
  }
  if (!thirdParty) {
    errors.value.thirdParty = "Invalid Third Party"
  }
  if (!totalGrossWeight) {
    errors.value.totalGrossWeight = "Invalid Total Gross Weight"
  }
  if (!totalVolume) {
    errors.value.totalVolume = "Invalid Total Volume"
  }
  if (!type) {
    errors.value.type = "Invalid Type"
  }
  if (!usdToPHP) {
    errors.value.usdToPHP = "Invalid USD To PHP Conversion"
  }
  if (!warehouse) {
    errors.value.warehouse = "Invalid Warehouse"
  }
  if (!dateABRD) {
    errors.value.dateABRD = "Invalid Airway Bill Received Date"
  }
  if (!carrierNumber) {
    errors.value.carrierNumber = "Invalid Carrier Number"
  }
  if (!category) {
    errors.value.category = "Invalid Category"
  }
  if (!flightNumber) {
    errors.value.flightNumber = "Invalid Flight Number"
  }
  if (!freightType) {
    errors.value.freightType = "Invalid Freight Type"
  }
  if (!hawb) {
    errors.value.hawb = "Invalid House Airway Bill Number"
  }
  if (!mawb) {
    errors.value.mawb = "Invalid Master Airway Bill Number"
  }
  if (!natureOfGoods) {
    errors.value.natureOfGoods = "Invalid Nature Of Goods"
  }
  if (!packagingType) {
    errors.value.packagingType = "Invalid Packaging Type"
  }
  if (!quantity) {
    errors.value.quantity = "Invalid Quantity"
  }
  if (!totalChargeableWeight) {
    errors.value.totalChargeableWeight = "Invalid Chargeable Weight"
  }

  return Object.keys(errors.value).length ? false : true
}

const handleOnSubmit = async (status) => {
  errors.value = {}

  if (
    status === "submitted"
    && !validateData(transportTransaction.value)
    && props.mode !== "brokerage"
  ) {
    return
  }

  let url
  let transaction
  if (props.mode === "brokerage") {
    url = "/brokerages/dependent"

    if (props.isEdit) {
      transaction = props.selectedTransaction
    } else {
      transaction = await brokerageServices.createAir({
        transportTransaction: props.selectedTransaction.url,
        internalCode: "air-brokerage"
      });
    }

    if (status === 'submitted') {
      const id = getIdFromURL(transaction.url);
      await brokerageServices.submitAir({ id });
    }
  } else if (props.mode === "independent-brokerage") {
    url = "/brokerages/independent"
    const data = mapImportTransactionData(transportTransaction.value)
    
    if (props.isEdit) {
      transaction = await rawPatch({
        url: props.selectedTransaction.url,
        data
      })
    } else {
      transaction = await brokerageServices.createIndependentAir({
        data
      })
    }

    if (status === 'submitted') {
      const id = getIdFromURL(transaction.url);
      await brokerageServices.submitIndependentAir({ id });
    }
  } else if (props.mode === "import") {
    url = "/imports"
    const data = mapImportTransactionData(transportTransaction.value)

    if (props.isEdit) {
      transaction = await rawPatch({
        url: props.selectedTransaction.url,
        data
      })
    } else {
      transaction = await transportServices.createAir({ data });
    }

    if (status === 'submitted') {
      const id = getIdFromURL(transaction.url);
      await transportServices.submitAir({ id });
    }
  }

  // create billing
  if (!props.isEdit) {
    await billServices.create({
      address: client.value.address,
      billedTo: client.value.company_name,
      billingAccount: "",
      cargoDescription: transportTransaction.value.natureOfGoods,
      consignee: client.value.url,
      nominalCost: 0, // manual input
      projectedProfit: 0, // total billed - nominal
      salesman: client.value.salesman,
      shipper: transportTransaction.value.shipper?.toUpperCase() || "",
      soa: "",
      tin: client.value.tin_number,
      totalBilledCharge: 0, // sum of rates
      transaction: transaction.url,
      billingStatementDocumentNumber: "",
      soaDocumentNumber: "",
    })
  }

  router.push(url)
}

const updateTransactionDetails = () => {
  transportTransaction.value = {
    ...transportTransaction.value,
    blNatureCode: props.selectedTransaction?.bl_natural_code,
    coloader: props.selectedTransaction?.coloader,
    consignee: props.client?.url,
    consigneeValue: props.client?.company_name,
    dateAND: props.selectedTransaction?.arrival_notice_date ? new Date(props.selectedTransaction.arrival_notice_date) : null,
    dateATA: props.selectedTransaction?.actual_time_of_arrival ? new Date(props.selectedTransaction.actual_time_of_arrival) : null,
    dateATB: props.selectedTransaction?.actual_time_of_berthing ? new Date(props.selectedTransaction.actual_time_of_berthing) : null,
    dateDRD: props.selectedTransaction?.document_request_date ? new Date(props.selectedTransaction.document_request_date) : null,
    dateETA: props.selectedTransaction?.estimated_time_of_arrival ? new Date(props.selectedTransaction.estimated_time_of_arrival) : null,
    dateETD: props.selectedTransaction?.estimated_time_of_departure ? new Date(props.selectedTransaction.estimated_time_of_departure) : null,
    foreignAgent: props.selectedTransaction?.foreign_agent,
    incoterm: props.selectedTransaction?.incoterm,
    itemDescription: props.selectedTransaction?.item_description,
    marksAndNumbers: props.selectedTransaction?.marks_and_numbers,
    notifyParty: props.selectedTransaction?.notify_party,
    originCountry: props.selectedTransaction?.origin_country,
    portOfDischarge: props.selectedTransaction?.port_of_discharge,
    portOfLoading: props.selectedTransaction?.port_of_loading,
    registryNumber: props.selectedTransaction?.registry_number,
    salesman: props.client?.salesman,
    shipper: props.selectedTransaction?.shipper,
    shipperAddress: props.selectedTransaction?.shipper_address,
    thirdParty: props.selectedTransaction?.third_party,
    totalGrossWeight: props.selectedTransaction?.total_gross_weight,
    totalVolume: props.selectedTransaction?.total_volume,
    ttGrossWeight: props.selectedTransaction?.gross_weight,
    ttNetWeight: props.selectedTransaction?.net_weight,
    usdToPHP: props.selectedTransaction?.usd_to_php_conversion,
    warehouse: props.selectedTransaction?.warehouse,
    carrierNumber: props.selectedTransaction?.carrier_number,
    dateABRD: props.selectedTransaction?.airway_bill_received_date ? new Date(props.selectedTransaction.airway_bill_received_date) : null,
    flightNumber: props.selectedTransaction?.flight_number,
    freightType: props.selectedTransaction?.freight_type,
    hawb: props.selectedTransaction?.house_airway_bill_number,
    mawb: props.selectedTransaction?.master_airway_bill_number,
    natureOfGoods: props.selectedTransaction?.nature_of_goods,
    packagingType: props.selectedTransaction?.packaging_type,
    quantity: props.selectedTransaction?.quantity,
    totalChargeableWeight: props.selectedTransaction?.total_chargeable_weight,
    ttChargeableWeight: 0,
  }
}

onMounted(async () => {
  incotermChoices.value = await transportServices.getIncoterms();
  packagingTypeChoices.value = await transportServices.getAirPackagingTypes();
  freightTypeChoices.value = await transportServices.getAirFreightTypes();
  const categories = await transportServices.getCategories();
  transportTransaction.value.category = categories.filter(category => (
    category.medium.toLowerCase() === "air"
  ))[0].url;

  if (props.isEdit || props.isView) {
    updateTransactionDetails();
  } else {
    transportTransaction.value.incoterm = incotermChoices.value[0].url
    transportTransaction.value.freightType = freightTypeChoices.value[0].url
    transportTransaction.value.packagingType = packagingTypeChoices.value[0].url
    transportTransaction.value.originCountry = "JP"
    transportTransaction.value.natureOfGoods = "GENERAL"
  }
})
</script>

<style scoped>
input:not(.case-not-forced) {
  text-transform: uppercase;
}

textarea:not(.case-not-forced) {
  text-transform: uppercase;
}

::v-deep .error {
  border-color: red;
}
</style>
