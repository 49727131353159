<template>
  <LayoutComponent
    :user-name="userName"
    :user-groups="userGroups"
  >
    <div class="container">
      <PageTitle title="Independent Brokerage - Edit" />
      <ImportAirForm
        v-if="
          type === 'Air'
          && transaction?.type === 'import'
        "
        :client="client"
        :is-edit="true"
        :is-view="false"
        :selected-transaction="transaction"
        mode="independent-brokerage"
      />
      <ExportAirForm
        v-else-if="
          type === 'Air'
          && transaction?.type === 'export'
        "
        :client="client"
        :is-edit="true"
        :is-view="false"
        :selected-transaction="transaction"
        mode="independent-brokerage"
      />
      <ImportSeaForm
        v-else-if="transaction?.type === 'import'"
        :type="type"
        :client="client"
        :is-edit="true"
        :is-view="false"
        :selected-transaction="transaction"
        mode="independent-brokerage"
      />
      <ExportSeaForm
        v-else-if="transaction?.type === 'export'"
        :type="type"
        :client="client"
        :is-edit="true"
        :is-view="false"
        :selected-transaction="transaction"
        mode="independent-brokerage"
      />
    </div>
  </LayoutComponent>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRoute, useRouter } from 'vue-router';

import ExportAirForm from "@/components/ExportAirForm.vue";
import ExportSeaForm from "@/components/ExportSeaForm.vue";
import ImportAirForm from "@/components/ImportAirForm.vue";
import ImportSeaForm from "@/components/ImportSeaForm.vue";
import LayoutComponent from "@/components/LayoutComponent.vue";
import PageTitle from "@/components/PageTitle.vue";

import { brokerageServices, rawGet, userServices } from "@/services";
import { userAccess } from "@/utils/constants";
import { getUserId } from "@/utils/helpers";

const route = useRoute();
const router = useRouter();

const type = ref("");
const transaction = ref(null);
const client = ref();
const userName = ref(null);
const userGroups = ref([])

onMounted(async () => {
  const userId = getUserId()
  const resp = await userServices.getDetails(userId)
  userName.value = resp.last_name

  userGroups.value = resp.groups
  if (!resp.groups.some(role => userAccess.hasAccessToBrokerageModify.includes(role))) {
    router.go(-1)
  }
  type.value = route.query?.type || "Air";
  const id = route.params.id;

  if (type.value === "Air") {
    transaction.value = await brokerageServices.getIndependentAirDetails({ id })
  } else {
    transaction.value = await brokerageServices.getIndependentSeaDetails({ id })
  }
  const consignee = await rawGet({
    url: transaction.value.consignee
  });
  client.value = consignee;
})
</script>

<style scoped>
</style>
