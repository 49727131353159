<template>
  <form>
    <div class="mb-3">
      <label for="companyName" class="form-label">Company Name</label>
      <input
        type="text"
        class="form-control"
        :class="errors.companyName ? 'error' : ''"
        id="companyName"
        aria-label="Company Name"
        v-model="payee.companyName"
      >
      <InputErrorLabel :show="errors.companyName" />
      <label for="address" class="form-label">Address</label>
      <input
        type="text"
        class="form-control"
        :class="errors.address ? 'error' : ''"
        id="address"
        aria-label="Address"
        v-model="payee.address"
      >
      <InputErrorLabel :show="errors.address" />
      <label for="telephoneNumber" class="form-label">Telephone Number</label>
      <input
        type="text"
        class="form-control"
        :class="errors.telephoneNumber ? 'error' : ''"
        id="telephoneNumber"
        aria-label="Telephone Number"
        v-model="payee.telephoneNumber"
      >
      <InputErrorLabel :show="errors.telephoneNumber" />
      <label for="tinNumber" class="form-label">Tin Number</label>
      <input
        type="text"
        class="form-control"
        :class="errors.tinNumber ? 'error' : ''"
        id="tinNumber"
        aria-label="Tin Number"
        v-model="payee.tinNumber"
      >
      <InputErrorLabel :show="errors.tinNumber" />
    </div>
    <h5 class="pt-4">Agent Details</h5>
    <div class="mb-3">
      <label for="adCompanyName" class="form-label">Company Name</label>
      <input
        type="text"
        class="form-control"
        :class="errors.agentCompanyName ? 'error' : ''"
        id="adCompanyName"
        aria-label="Company Name"
        v-model="agent.companyName"
      >
      <InputErrorLabel :show="errors.agentCompanyName" />
      <label for="adAddress" class="form-label">Address</label>
      <input
        type="text"
        class="form-control"
        :class="errors.agentAddress ? 'error' : ''"
        id="adAddress"
        aria-label="Address"
        v-model="agent.address"
      >
      <InputErrorLabel :show="errors.agentAddress" />
      <label for="countryOfOrigin" class="form-label">Country Of Origin</label>
      <select
        v-model="agent.countryOfOrigin"
        class="form-select"
        :class="errors.countryOfOrigin ? 'error' : ''"
        id="countryOfOrigin"
        aria-label="Country Of Origin"
      >
        <option
          v-for="cof in countryChoices"
          :value="cof.value"
          :key="cof.value"
        >
          {{ cof.label }}
        </option>
      </select>
      <InputErrorLabel :show="errors.countryOfOrigin" />
      <label for="swiftCode" class="form-label">Swift Code</label>
      <input
        type="text"
        class="form-control"
        :class="errors.swiftCode ? 'error' : ''"
        id="swiftCode"
        aria-label="Swift Code"
        v-model="agent.swiftCode"
      >
      <InputErrorLabel :show="errors.swiftCode" />
      <label for="bankDetails" class="form-label">Bank Details</label>
      <textarea
        class="form-control"
        :class="errors.bankDetails ? 'error' : ''"
        id="bankDetails"
        aria-label="Bank Details"
        rows="3"
        v-model="agent.bankDetails"
      ></textarea>
      <InputErrorLabel :show="errors.bankDetails" />
    </div>
    <button type="button" class="btn button-primary" @click="handleOnSubmit">Submit</button>
  </form>
</template>

<script setup>
import { defineProps, ref, watch } from "vue";
import { useRouter } from 'vue-router';

import InputErrorLabel from "@/components/InputErrorLabel.vue";

import { payeeServices, rawPatch } from "@/services";
import { getIdFromURL } from "@/utils/helpers";
import { countryChoices } from "@/utils/constants";

const router = useRouter();
const props = defineProps(["mode", "payee", "agent", "bank"]);

const payee = ref({
  companyName: "",
  address: "",
  telephoneNumber: "",
  tinNumber: ""
});
const agent = ref({
  companyName: "",
  address: "",
  countryOfOrigin: "",
  swiftCode: "",
  bankDetails: ""
});
const errors = ref({})

watch(
  () => props.agent,
  (newAgent) => {
    if (props.mode === "edit") {
      agent.value = ({
        ...agent.value,
        companyName: newAgent.name,
        address: newAgent.address,
        countryOfOrigin: newAgent.country_of_origin
      })
    }
  }
)
watch(
  () => props.payee,
  (newPayee) => {
    if (props.mode === "edit") {
      payee.value = {
        companyName: newPayee.company_name,
        address: newPayee.address,
        telephoneNumber: newPayee.telephone_number,
        tinNumber: newPayee.tin_number,
      }
    }
  }
)
watch(
  () => props.bank,
  (newBank) => {
    if (props.mode === "edit") {
      agent.value = ({
        ...agent.value,
        swiftCode: newBank.swift_code,
        bankDetails: newBank.details
      })
    }
  }
)

const validateData = () => {
  errors.value = {}

  // payee
  if (!payee.value?.companyName) {
    errors.value.companyName = "Invalid Company Name"
  }
  if (!payee.value?.address) {
    errors.value.address = "Invalid Address"
  }
  if (!payee.value?.telephoneNumber) {
    errors.value.telephoneNumber = "Invalid Telephone Number"
  }
  if (!payee.value?.tinNumber) {
    errors.value.tinNumber = "Invalid Tin Number"
  }
  // agent
  if (!agent.value.companyName) {
    errors.value.agentCompanyName = "Invalid Company Name"
  }
  if (!agent.value.address) {
    errors.value.agentAddress = "Invalid Address"
  }
  if (!agent.value.countryOfOrigin) {
    errors.value.countryOfOrigin = "Invalid Country Of Origin"
  }
  // bank
  if (!agent.value.swiftCode) {
    errors.value.swiftCode = "Invalid Swift Code"
  }
  if (!agent.value.bankDetails) {
    errors.value.bankDetails = "Invalid Bank Details"
  }

  return Object.keys(errors.value).length ? false : true
}

const handleOnSubmit = async () => {
  errors.value = {}

  if (!validateData()) {
    return
  }

  if (props.mode === "create") {
    const payeeResponse = await payeeServices.create({
      companyName: payee.value?.companyName?.toUpperCase() || "",
      address: payee.value?.address?.toUpperCase() || "",
      telephoneNumber: payee.value?.telephoneNumber?.toUpperCase() || "",
      tinNumber: payee.value?.tinNumber?.toUpperCase() || "",
    });
    const payeeId = getIdFromURL(payeeResponse.url)

    const agentResponse = await payeeServices.createAgent({
      companyName: agent.value.companyName?.toUpperCase() || "",
      address: agent.value.address?.toUpperCase() || "",
      countryOfOrigin: agent.value.countryOfOrigin?.toUpperCase() || "",
      payeeId,
    });
    const agentId = getIdFromURL(agentResponse.url);

    await payeeServices.createBank({
      countryOfOrigin: agent.value.countryOfOrigin?.toUpperCase() || "",
      swiftCode: agent.value.swiftCode?.toUpperCase() || "",
      bankDetails: agent.value.bankDetails?.toUpperCase() || "",
      agentId,
      agent: agentResponse.url
    })
  } else {
    const payeePatch = rawPatch({
      url: props.payee.url,
      data: {
        company_name: payee.value.companyName?.toUpperCase() || "",
        address: payee.value.address?.toUpperCase() || "",
        telephone_number: payee.value.telephoneNumber?.toUpperCase() || "",
        tin_number: payee.value.tinNumber?.toUpperCase() || "",
      },
    })
    const agentPatch = rawPatch({
      url: props.agent.url,
      data: {
        name: agent.value.companyName?.toUpperCase() || "",
        address: agent.value.address?.toUpperCase() || "",
        country_of_origin: agent.value.countryOfOrigin?.toUpperCase() || "",
      },
    })
    const bankPatch = rawPatch({
      url: props.bank.url,
      data: {
        swift_code: agent.value.swiftCode?.toUpperCase() || "",
        details: agent.value.bankDetails?.toUpperCase() || "",
      }
    })

    await Promise.all([agentPatch, payeePatch, bankPatch])
  }

  router.push("/members")
}
</script>
<style scoped>
input {
  text-transform: uppercase;
}

textarea {
  text-transform: uppercase;
}

.error {
  border-color: red;
}
</style>