<template>
  <form>
    <div class="mb-3">
      <label for="mbl" class="form-label">Master Bill of Lading (MBL)</label>
      <input
        type="text"
        class="form-control"
        id="mbl"
        aria-label="Master Bill of Lading (MBL)"
        v-model="transportTransaction.mbl"
        :disabled="isInputDisabled"
      >
      <label for="registryNumber" class="form-label">Registry Number</label>
      <input
        type="text"
        class="form-control"
        id="registryNumber"
        aria-label="Registry Number"
        v-model="transportTransaction.registryNumber"
        :disabled="isInputDisabled"
      >
      <label for="foreignAgent" class="form-label">Foreign Agent</label>
      <input
        type="text"
        class="form-control"
        id="foreignAgent"
        aria-label="Foreign Agent"
        v-model="transportTransaction.foreignAgent"
        :disabled="isInputDisabled"
      >
      <label for="originCountry" class="form-label">Origin Country</label>
      <input
        type="text"
        class="form-control"
        id="originCountry"
        aria-label="Origin Country"
        disabled
        v-model="transportTransaction.originCountryFiller"
      >
      <label for="portOfLoading" class="form-label">Port Of Loading</label>
      <select
        class="form-select"
        id="portOfLoading"
        aria-label="Port Of Loading"
        v-model="transportTransaction.portOfLoading"
        :disabled="isInputDisabled"
      >
        <option value="MANILA SOUTH">MANILA SOUTH</option>
        <option value="MANILA NORTH">MANILA NORTH</option>
        <option value="BATANGAS CITY">BATANGAS CITY</option>
        <option value="CAGAYAN">CAGAYAN</option>
        <option value="DAVAO">DAVAO</option>
        <option value="CAGAYAN DE ORO">CAGAYAN DE ORO</option>
      </select>
      <label for="destinationCountry" class="form-label">Destination Country</label>
      <select
        class="form-select"
        id="destinationCountry"
        aria-label="Destination Country"
        v-model="transportTransaction.destinationCountry"
        :disabled="isInputDisabled"
      >
        <option
          v-for="cof in countryChoices"
          :value="cof.value"
          :key="cof.value"
        >
          {{ cof.label }}
        </option>
      </select>
      <label for="portOfDischarge" class="form-label">Port Of Discharge</label>
      <input
        type="text"
        class="form-control"
        id="portOfDischarge"
        aria-label="Port Of Discharge"
        v-model="transportTransaction.portOfDischarge"
        :disabled="isInputDisabled"
      >
      <label for="vesselNumber" class="form-label">Vessel Number</label>
      <input
        type="text"
        class="form-control"
        id="vesselNumber"
        aria-label="Vessel Number"
        v-model="transportTransaction.vesselNumber"
        :disabled="isInputDisabled"
      >
      <label for="shippingLine" class="form-label">Shipping Line</label>
      <input
        type="text"
        class="form-control"
        id="shippingLine"
        aria-label="Shipping Line"
        v-model="transportTransaction.shippingLine"
        :disabled="isInputDisabled"
      >
      <label for="coloader" class="form-label">Coloader</label>
      <input
        type="text"
        class="form-control"
        id="coloader"
        aria-label="Coloader"
        v-model="transportTransaction.coloader"
        :disabled="isInputDisabled"
      >
      <label for="warehouse" class="form-label">Warehouse</label>
      <input
        type="text"
        class="form-control"
        id="warehouse"
        aria-label="Warehouse"
        v-model="transportTransaction.warehouse"
        :disabled="isInputDisabled"
      >
      <label for="totalGrossWeight" class="form-label">Total Gross Weight (kg)</label>
      <input
        type="number"
        class="form-control"
        id="totalGrossWeight"
        aria-label="Total Gross Weight (kg)"
        v-model="transportTransaction.totalGrossWeight"
        :disabled="isInputDisabled"
      >
      <label for="totalVolume" class="form-label">Total Volume (cbm)</label>
      <input
        type="number"
        class="form-control"
        id="totalVolume"
        aria-label="Total Volume (cbm)"
        v-model="transportTransaction.totalVolume"
        :disabled="isInputDisabled"
      >
      <label for="dateETD" class="form-label">Estimated Time Of Departure</label>
      <VueDatePicker
        v-model="transportTransaction.dateETD"
        id="dateETD"
        aria-label="Estimated Time Of Departure"
        :disabled="isInputDisabled"
        :enable-time-picker="false"
      />
      <label for="dateETA" class="form-label">Estimated Time Of Arrival</label>
      <VueDatePicker
        v-model="transportTransaction.dateETA"
        id="dateETA"
        aria-label="Estimated Time Of Arrival"
        :disabled="isInputDisabled"
        :enable-time-picker="false"
      />
      <label for="dateATA" class="form-label">Actual Time Of Arrival</label>
      <VueDatePicker
        v-model="transportTransaction.dateATA"
        id="dateATA"
        aria-label="Actual Time Of Arrival"
        :disabled="isInputDisabled"
        :enable-time-picker="false"
      />
      <label for="dateATB" class="form-label">Actual Time Of Berthing</label>
      <VueDatePicker
        v-model="transportTransaction.dateATB"
        id="dateATB"
        aria-label="Actual Time Of Berthing"
        :disabled="isInputDisabled"
        :enable-time-picker="false"
      />
      <!-- transaction type -->
      <label for="transactionType" class="form-label">Transaction Type</label>
      <input
        type="text"
        class="form-control"
        id="transactionType"
        aria-label="Transaction Type"
        v-model="transportTransaction.transactionType"
        disabled
      >
      <label for="salesman" class="form-label">Salesman</label>
      <input
        type="text"
        class="form-control case-not-forced"
        id="salesman"
        aria-label="Salesman"
        v-model="transportTransaction.salesman"
        disabled
      >
      <label for="consignee" class="form-label">Consignee</label>
      <input
        type="text"
        class="form-control case-not-forced"
        id="consignee"
        aria-label="Consignee"
        v-model="transportTransaction.consigneeValue"
        disabled
      >
      <label for="shipper" class="form-label">Shipper</label>
      <input
        type="text"
        class="form-control"
        id="shipper"
        aria-label="Shipper"
        v-model="transportTransaction.shipper"
        :disabled="isInputDisabled"
      >
      <label for="shipperAddress" class="form-label">Shipper Address</label>
      <textarea
        class="form-control"
        id="shipperAddress"
        aria-label="Shipper Address"
        rows="3"
        v-model="transportTransaction.shipperAddress"
        :disabled="isInputDisabled"
      ></textarea>
      <label for="notifyParty" class="form-label">Notify Party</label>
      <input
        type="text"
        class="form-control"
        id="notifyParty"
        aria-label="Notify Party"
        v-model="transportTransaction.notifyParty"
        :disabled="isInputDisabled"
      >
      <label for="thirdParty" class="form-label">Third Party</label>
      <input
        type="text"
        class="form-control"
        id="thirdParty"
        aria-label="Third Party"
        v-model="transportTransaction.thirdParty"
        :disabled="isInputDisabled"
      >
      <label for="hbl" class="form-label">House Bill of Lading (HBL)</label>
      <input
        type="text"
        class="form-control"
        id="hbl"
        aria-label="House Bill of Lading (HBL)"
        v-model="transportTransaction.hbl"
        :disabled="isInputDisabled"
      >
      <label for="blNatureCode" class="form-label">BL Nature Code</label>
      <select
        class="form-select"
        id="blNatureCode"
        aria-label="BL Nature Code"
        v-model="transportTransaction.blNatureCode"
        :disabled="isInputDisabled"
      >
        <option value="23">23</option>
        <option value="24">24</option>
      </select>
      <label for="destinationCountry" class="form-label">Destination Country</label>
      <select
        class="form-select"
        id="destinationCountry"
        aria-label="Destination Country"
        v-model="transportTransaction.destinationCountry"
        :disabled="isInputDisabled"
      >
        <option
          v-for="cof in countryChoices"
          :value="cof.value"
          :key="cof.value"
        >
          {{ cof.label }}
        </option>
      </select>
      <label for="portOfDischarge" class="form-label">Port Of Discharge</label>
      <input
        type="text"
        class="form-control"
        id="portOfDischarge"
        aria-label="Port Of Discharge"
        v-model="transportTransaction.portOfDischarge"
        :disabled="isInputDisabled"
      >
      <label for="containerType" class="form-label">Container Type</label>
      <select
        class="form-select"
        id="containerType"
        aria-label="Container Type"
        v-model="transportTransaction.containerType"
        :disabled="isInputDisabled"
      >
        <option v-for="ct in containerTypeChoices" :key="ct.url" :value="ct.url">{{ ct.label }}</option>
        <!-- TODO FE: handle others -->
        <option value="others">Others</option>
      </select>
      <label for="containerSize" class="form-label">Container Size</label>
      <select
        class="form-select"
        id="containerSize"
        aria-label="Container Size"
        v-model="transportTransaction.containerSize"
        :disabled="isInputDisabled"
      >
        <option v-for="cs in containerSizeChoices" :key="cs.url" :value="cs.url">{{ cs.label }}</option>
        <!-- TODO FE: handle others -->
        <option value="others">Others</option>
      </select>
      <label for="ttGrossWeight" class="form-label">Gross Weight</label>
      <input
        type="number"
        class="form-control"
        id="ttGrossWeight"
        aria-label="Gross Weight"
        v-model="transportTransaction.ttGrossWeight"
        :disabled="isInputDisabled"
      >
      <label for="cbm" class="form-label">CBM</label>
      <input
        type="number"
        class="form-control"
        id="cbm"
        aria-label="CBM"
        v-model="transportTransaction.cbm"
        :disabled="isInputDisabled"
      >
      <label for="ttNetWeight" class="form-label">Net Weight</label>
      <input
        type="number"
        class="form-control"
        id="ttNetWeight"
        aria-label="Net Weight"
        v-model="transportTransaction.ttNetWeight"
        :disabled="isInputDisabled"
      >
      <label for="itemDescription" class="form-label">Item Description</label>
      <textarea
        class="form-control"
        id="itemDescription"
        aria-label="Item Description"
        rows="3"
        v-model="transportTransaction.itemDescription"
        :disabled="isInputDisabled"
      ></textarea>
      <label for="dateBLRD" class="form-label">BL Received Date</label>
      <VueDatePicker
        v-model="transportTransaction.dateBLRD"
        id="dateBLRD"
        aria-label="Airway Bill Received Date"
        :disabled="isInputDisabled"
        :enable-time-picker="false"
      />
      <label for="dateDRD" class="form-label">Document Request Date</label>
      <VueDatePicker
        v-model="transportTransaction.dateDRD"
        id="dateDRD"
        aria-label="Document Request Date"
        :disabled="isInputDisabled"
        :enable-time-picker="false"
      />
      <label for="dateAND" class="form-label">Arrival Notice Date</label>
      <VueDatePicker
        v-model="transportTransaction.dateAND"
        id="dateAND"
        aria-label="Arrival Notice Date"
        :disabled="isInputDisabled"
        :enable-time-picker="false"
      />
      <label for="containerQuantity" class="form-label">Container Quantity</label>
      <input
        type="number"
        class="form-control"
        id="containerQuantity"
        aria-label="Container Quantity"
        v-model="transportTransaction.containerQuantity"
        :disabled="isInputDisabled"
      >
      <label for="incoterm" class="form-label">Incoterm</label>
      <select
        class="form-select"
        id="incoterm"
        aria-label="Incoterm"
        v-model="transportTransaction.incoterm"
        :disabled="isInputDisabled"
      >
        <option
          v-for="incoterm in incotermChoices"
          :key="incoterm.url"
          :value="incoterm.url"
        >
          {{ `${incoterm.label} (${incoterm.code})` }}
        </option>
      </select>
      <label for="marksAndNumbers" class="form-label">Marks and Numbers</label>
      <textarea
        class="form-control"
        id="marksAndNumbers"
        aria-label="Marks and Numbers"
        rows="3"
        v-model="transportTransaction.marksAndNumbers"
        :disabled="isInputDisabled"
      ></textarea>
      <label for="usdToPhp" class="form-label">USD to PHP</label>
      <input
        type="number"
        class="form-control"
        id="usdToPhp"
        aria-label="USD to PHP"
        v-model="transportTransaction.usdToPHP"
        :disabled="isInputDisabled"
      >
      <label for="modeOfShipment" class="form-label">Mode of Shipment</label>
      <select
        class="form-select"
        id="modeOfShipment"
        aria-label="Mode of Shipment"
        v-model="transportTransaction.modeOfShipment"
        :disabled="isInputDisabled"
      >
        <option v-for="ms in modeOfShipmentChoices" :key="ms.url" :value="ms.url">{{ ms.name }}</option>
      </select>
    </div>
    <button
      v-if="!isView && props.mode !== 'brokerage'"
      type="button"
      class="btn btn-secondary me-3"
      @click="handleOnSubmit('in_progress')"
    >
      Save as Draft
    </button>
    <button
      v-if="!isView"
      type="button"
      class="btn button-primary"
      @click="handleOnSubmit('submitted')"
    >
      Submit
    </button>
  </form>
</template>

<script setup>
import { computed, defineProps, onMounted, ref, watch } from "vue";
import { useRouter } from 'vue-router';

import {
  billServices,
  brokerageServices,
  rawPatch,
  transportServices
} from "@/services";
import { countryChoices } from "@/utils/constants";
import { getIdFromURL } from "@/utils/helpers";

const props = defineProps([
  "client",
  'isEdit',
  'isView',
  "mode",
  'selectedTransaction',
  "type",
]);
const router = useRouter();
const incotermChoices = ref([]);
const containerTypeChoices = ref([]);
const containerSizeChoices = ref([]);
const modeOfShipmentChoices = ref([]);
const transportTransaction = ref({
  blNatureCode: props.selectedTransaction?.bl_natural_code,
  coloader: props.selectedTransaction?.coloader,
  consignee: props.client?.url,
  consigneeValue: props.client?.company_name,
  dateAND: props.selectedTransaction?.arrival_notice_date ? new Date(props.selectedTransaction.arrival_notice_date) : null,
  dateATA: props.selectedTransaction?.arrival_notice_dactual_time_of_arrivalate ? new Date(props.selectedTransaction.arrival_notice_dactual_time_of_arrivalate) : null,
  dateATB: props.selectedTransaction?.actual_time_of_berthing ? new Date(props.selectedTransaction.actual_time_of_berthing) : null,
  dateDRD: props.selectedTransaction?.document_request_date ? new Date(props.selectedTransaction.document_request_date) : null,
  dateETA: props.selectedTransaction?.estimated_time_of_arrival ? new Date(props.selectedTransaction.estimated_time_of_arrival) : null,
  dateETD: props.selectedTransaction?.estimated_time_of_departure ? new Date(props.selectedTransaction.estimated_time_of_departure) : null,
  destinationCountry: "Philippines",
  foreignAgent: props.selectedTransaction?.foreign_agent,
  incoterm: props.selectedTransaction?.incoterm,
  itemDescription: props.selectedTransaction?.item_description,
  marksAndNumbers: props.selectedTransaction?.marks_and_numbers,
  notifyParty: props.selectedTransaction?.notify_party,
  originCountry: "PH",
  originCountryFiller: "Philippines",
  portOfDischarge: props.selectedTransaction?.port_of_discharge,
  portOfLoading: props.selectedTransaction?.port_of_loading,
  registryNumber: props.selectedTransaction?.registry_number,
  salesman: props.client?.salesman,
  shipper: props.selectedTransaction?.shipper,
  thirdParty: props.selectedTransaction?.third_party,
  totalGrossWeight: props.selectedTransaction?.total_gross_weight,
  totalVolume: props.selectedTransaction?.total_volume,
  type: "export",
  ttGrossWeight: props.selectedTransaction?.gross_weight,
  ttNetWeight: props.selectedTransaction?.net_weight,
  usdToPHP: props.selectedTransaction?.warehouse,
  warehouse: props.selectedTransaction?.warehouse,

  // SeaTransportTransaction
  category: "url for sea", // fkey to TransportTransactionCategory
  cbm: props.selectedTransaction?.cbm,
  containerQuantity: props.selectedTransaction?.container_quantity,
  containerSize: props.selectedTransaction?.container_size,
  containerType: props.selectedTransaction?.container_type,
  dateBLRD: props.selectedTransaction?.bl_received_date ? new Date(props.selectedTransaction.bl_received_date) : null,
  hbl: props.selectedTransaction?.house_bill_of_lading,
  mbl: props.selectedTransaction?.master_bill_of_lading,
  modeOfShipment: props.selectedTransaction?.mode_of_shipment,
  shipperAddress: props.selectedTransaction?.shipper_address,
  shippingLine: props.selectedTransaction?.shipping_line,
  transactionType: props.selectedTransaction?.category, // save as category in DB
  vesselNumber: props.selectedTransaction?.vessel_number,
});

const mapTransactionData = ({
  blNatureCode,
  coloader,
  consignee,
  dateAND,
  dateATA,
  dateATB,
  dateDRD,
  dateETA,
  dateETD,
  destinationCountry,
  foreignAgent,
  incoterm,
  itemDescription,
  marksAndNumbers,
  notifyParty,
  originCountry,
  portOfDischarge,
  portOfLoading,
  registryNumber,
  salesman,
  shipper,
  thirdParty,
  totalGrossWeight,
  totalVolume,
  type,
  ttGrossWeight,
  ttNetWeight,
  usdToPHP,
  warehouse,

  category,
  cbm,
  containerQuantity,
  containerSize,
  containerType,
  dateBLRD,
  hbl,
  mbl,
  modeOfShipment,
  shipperAddress,
  shippingLine,
  vesselNumber,
}) => {
  return {
    bl_natural_code: blNatureCode,
    coloader: coloader?.toUpperCase() || "",
    consignee,
    arrival_notice_date: dateAND?.toISOString(),
    actual_time_of_arrival: dateATA?.toISOString(),
    actual_time_of_berthing: dateATB?.toISOString(),
    document_request_date: dateDRD?.toISOString(),
    estimated_time_of_departure: dateETD?.toISOString(),
    estimated_time_of_arrival: dateETA?.toISOString(),
    destination_country: destinationCountry,
    foreign_agent: foreignAgent?.toUpperCase() || "",
    incoterm,
    item_description: itemDescription?.toUpperCase() || "",
    marks_and_numbers: marksAndNumbers?.toUpperCase() || "",
    notify_party: notifyParty?.toUpperCase() || "",
    origin_country: originCountry,
    port_of_discharge: portOfDischarge?.toUpperCase() || "",
    port_of_loading: portOfLoading,
    registry_number: registryNumber?.toUpperCase() || "",
    salesman,
    shipper: shipper?.toUpperCase() || "",
    third_party: thirdParty?.toUpperCase() || "",
    total_gross_weight: totalGrossWeight,
    total_volume: totalVolume,
    type,
    gross_weight: ttGrossWeight,
    net_weight: ttNetWeight,
    usd_to_php_conversion: usdToPHP,
    warehouse: warehouse?.toUpperCase() || "",

    category,
    cbm,
    container_quantity: containerQuantity,
    container_size: containerSize,
    container_type: containerType,
    bl_received_date: dateBLRD?.toISOString(),
    house_bill_of_lading: hbl?.toUpperCase() || "",
    master_bill_of_lading: mbl?.toUpperCase() || "",
    mode_of_shipment: modeOfShipment,
    shipper_address: shipperAddress?.toUpperCase() || "",
    shipping_line: shippingLine?.toUpperCase() || "",
    vessel_number: vesselNumber?.toUpperCase() || "",
  };
}

const handleOnSubmit = async (status) => {
  let transaction
  let url

  if (props.mode === "brokerage") {
    url = "/brokerages/dependent"

    if (props.isEdit) {
      transaction = props.selectedTransaction
    } else {
      transaction = await brokerageServices.createSea({
        transportTransaction: props.selectedTransaction.url,
        internalCode: "sea-brokerage"
      });
    }

    if (status === 'submitted') {
      const id = getIdFromURL(transaction.url);
      await brokerageServices.submitSea({ id });
    }
  } else if (props.mode === "independent-brokerage") {
    url = "/brokerages/independent"
    const data = mapTransactionData(transportTransaction.value)
    
    transaction = await brokerageServices.createIndependentSea({
      data
    })

    if (status === 'submitted') {
      const id = getIdFromURL(transaction.url);
      await brokerageServices.submitIndependentSea({ id });
    }
  } else if (props.mode === "export") {
    const data = mapTransactionData(transportTransaction.value);
    url = "/exports"

    if (props.isEdit) {
      transaction = await rawPatch({
        url: props.selectedTransaction.url,
        data,
      })
    } else {
      transaction = await transportServices.createSea({ data });
    }

    if (status === 'submitted') {
      const id = getIdFromURL(transaction.url);
      await transportServices.submitSea({ id });
    }
  }

  if (!props.isEdit) {
    await billServices.create({
      address: props.client.address,
      billedTo: props.client.company_name,
      billingAccount: "",
      cargoDescription: "GENERAL",
      consignee: props.client.url,
      nominalCost: 0, // manual input
      projectedProfit: 0, // total billed - nominal
      salesman: props.client.salesman,
      shipper: transportTransaction.value.shipper?.toUpperCase() || "",
      soa: "",
      tin: props.client.tin_number,
      totalBilledCharge: 0, // sum of rates
      transaction: transaction.url,
      billingStatementDocumentNumber: "",
      soaDocumentNumber: "",
    })
  }

  router.push(url);
}

watch(
  () => props.type,
  (type) => {
    transportTransaction.value.transactionType = type;
  }
)

const isInputDisabled = computed(() => {
  if (props.mode === "brokerage") return true
  if (props.isView) return true
  return false
})

const updateTransactionDetails = () => {
  transportTransaction.value = {
    ...transportTransaction.value,
    blNatureCode: props.selectedTransaction?.bl_natural_code,
    coloader: props.selectedTransaction?.coloader,
    consignee: props.client?.url,
    consigneeValue: props.client?.company_name,
    dateAND: props.selectedTransaction?.arrival_notice_date ? new Date(props.selectedTransaction.arrival_notice_date) : null,
    dateATA: props.selectedTransaction?.actual_time_of_arrival ? new Date(props.selectedTransaction.actual_time_of_arrival) : null,
    dateATB: props.selectedTransaction?.actual_time_of_berthing ? new Date(props.selectedTransaction.actual_time_of_berthing) : null,
    dateDRD: props.selectedTransaction?.document_request_date ? new Date(props.selectedTransaction.document_request_date) : null,
    dateETA: props.selectedTransaction?.estimated_time_of_arrival ? new Date(props.selectedTransaction.estimated_time_of_arrival) : null,
    dateETD: props.selectedTransaction?.estimated_time_of_departure ? new Date(props.selectedTransaction.estimated_time_of_departure) : null,
    destinationCountry: props.selectedTransaction?.destination_country,
    foreignAgent: props.selectedTransaction?.foreign_agent,
    incoterm: props.selectedTransaction?.incoterm,
    itemDescription: props.selectedTransaction?.item_description,
    marksAndNumbers: props.selectedTransaction?.marks_and_numbers,
    notifyParty: props.selectedTransaction?.notify_party,
    originCountry: props.selectedTransaction?.origin_country,
    portOfDischarge: props.selectedTransaction?.port_of_discharge,
    portOfLoading: props.selectedTransaction?.port_of_loading,
    registryNumber: props.selectedTransaction?.registry_number,
    salesman: props.client?.salesman,
    shipper: props.selectedTransaction?.shipper,
    thirdParty: props.selectedTransaction?.third_party,
    totalGrossWeight: props.selectedTransaction?.total_gross_weight,
    totalVolume: props.selectedTransaction?.total_volume,
    ttGrossWeight: props.selectedTransaction?.gross_weight,
    ttNetWeight: props.selectedTransaction?.net_weight,
    usdToPHP: props.selectedTransaction?.usd_to_php_conversion,
    warehouse: props.selectedTransaction?.warehouse,

    // SeaTransportTransaction
    cbm: props.selectedTransaction?.cbm,
    containerQuantity: props.selectedTransaction?.container_quantity,
    containerSize: props.selectedTransaction?.container_size,
    containerType: props.selectedTransaction?.container_type,
    dateBLRD: props.selectedTransaction?.bl_received_date ? new Date(props.selectedTransaction.bl_received_date) : null,
    hbl: props.selectedTransaction?.house_bill_of_lading,
    mbl: props.selectedTransaction?.master_bill_of_lading,
    modeOfShipment: props.selectedTransaction?.mode_of_shipment,
    shipperAddress: props.selectedTransaction?.shipper_address,
    shippingLine: props.selectedTransaction?.shipping_line,
    transactionType: props.type || props.selectedTransaction?.category, // save as category in DB
    vesselNumber: props.selectedTransaction?.vessel_number,
  }
}

onMounted(async () => {
  transportTransaction.value.transactionType = props.type;
  incotermChoices.value = await transportServices.getIncoterms();
  containerTypeChoices.value = await transportServices.getContainerTypes();
  containerSizeChoices.value = await transportServices.getContainerSizes();
  modeOfShipmentChoices.value = await transportServices.getModeOfShipments();
  const categories = await transportServices.getCategories();
  transportTransaction.value.category = categories.filter(category => (
    category.medium.toLowerCase() === "sea" 
    && category.category.toLowerCase() === props.type.toLowerCase()
  ))[0]?.url;
  
  if (props.isEdit || props.isView) {
    updateTransactionDetails();
  } else {
    transportTransaction.value.incoterm = incotermChoices.value[0].url
    transportTransaction.value.containerSize = containerSizeChoices.value[0].url
    transportTransaction.value.containerType = containerTypeChoices.value[0].url
    transportTransaction.value.modeOfShipment = modeOfShipmentChoices.value[0].url
    transportTransaction.value.destinationCountry = "JP"
  }
})
</script>

<style scoped>
input:not(.case-not-forced) {
  text-transform: uppercase;
}

textarea:not(.case-not-forced) {
  text-transform: uppercase;
}
</style>
