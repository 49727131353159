<template>
  <div class="delete-modal container text-center">
    <h1 class="confirm">Confirm</h1>
    <h1 class="pb-5">Action</h1>
    <div class="d-flex justify-content-around mt-5">
      <button
        type="button"
        class="btn button-primary me-3"
        @click="toggleDeleteModal"
      >
        Confirm
      </button>
      <button type="button" class="btn btn-light" @click="toggleDeleteModal">
        Cancel
      </button>
    </div>
  </div>
</template>

<script setup>
import { defineEmits } from "vue";

const emit = defineEmits(["toggleDeleteModal"]);

const toggleDeleteModal = () => {
  emit("toggleDeleteModal");
};
</script>

<style scoped>
.delete-modal {
  background-color: #adadad;
  position: fixed;
  bottom: 25%;
  left: 35%;
  height: 50%;
  width: 50%;
  border: 2px solid black;
}

.delete-modal .confirm {
  margin-top: 4em;
}

.delete-modal .btn {
  padding-left: 30px;
  padding-right: 30px;
}
</style>
