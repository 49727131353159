<template>
  <LayoutComponent
    :user-name="userName"
    :user-groups="userGroups"
  >
    <div class="container">
      <PageTitle title="Dependent Brokerage - Create" />
      <div v-if="!initialDataSubmitted">
        <form>
          <label for="direction" class="form-label mt-2">Direction</label>
          <div
            id="direction"
            class="form-check"
            v-for="direction in directions"
            :key="direction"
          >
            <input
              class="form-check-input"
              type="radio"
              name="flexRadioDirection"
              :id="direction"
              @change="onChangeSelectedDirection(direction)"
            >
            <label
              class="form-check-label"
              :for="direction"
            >
              {{ direction }}
            </label>
          </div>
          <label for="type" class="form-label mt-2">Type</label>
          <div
            id="type"
            class="form-check"
            v-for="singleType in types"
            :key="singleType"
          >
            <input
              class="form-check-input"
              type="radio"
              name="flexRadioDefault"
              :id="singleType"
              @change="onChangeSelectedType(singleType)"
            >
            <label
              class="form-check-label"
              :for="singleType"
            >
              {{ singleType }}
            </label>
          </div>
          <label
            for="master"
            class="form-label"
          >
            Master Airway Bill (MAWB) / Master Bill of Lading (MBL)
          </label>
          <input
            type="text"
            class="form-control"
            id="master"
            aria-label="Master Airway Bill (MAWB) / Master Bill of Lading (MBL)"
            :value="initialData.master"
            @input="onChangeMaster"
          >
          <label
            for="house"
            class="form-label"
          >
            House Airway Bill (HAWB) / House Bill of Lading (HBL)
          </label>
          <input
            type="text"
            class="form-control"
            id="house"
            aria-label="House Airway Bill (HAWB) / House Bill of Lading (HBL)"
            :value="initialData.house"
            @input="onChangeHouse"
          >
        </form>
        <button
          type="button"
          class="btn btn-success mt-3"
          @click="handleOnClickNext"
          :disabled="isButtonDisabled"
        >
          Next
        </button>
      </div>
      <div v-else>
        <ImportAirForm
          v-if="
            initialData.type === 'Air'
            && initialData.direction === 'Import'
          "
          :client="selectedClient"
          :selected-transaction = "selectedTransaction"
          mode="brokerage"
        />
        <ImportSeaForm
          v-else-if="
            initialData.direction === 'Import'
          "
          :type="initialData.type"
          :client="selectedClient"
          :selected-transaction = "selectedTransaction"
          mode="brokerage"
        />
        <ExportAirForm
          v-else-if="
            initialData.type === 'Air'
            && initialData.direction === 'Export'
          "
          :type="initialData.type"
          :client="selectedClient"
          :selected-transaction = "selectedTransaction"
          mode="brokerage"
        />
        <ExportSeaForm
          v-else-if="
            initialData.direction === 'Export'
          "
          :type="initialData.type"
          :client="selectedClient"
          :selected-transaction = "selectedTransaction"
          mode="brokerage"
        />
      </div>
    </div>
  </LayoutComponent>
</template>

<script setup>
import { computed, ref, onMounted, watch } from "vue";
import { useRouter } from 'vue-router';

import ExportAirForm from "@/components/ExportAirForm.vue";
import ExportSeaForm from "@/components/ExportSeaForm.vue";
import ImportAirForm from "@/components/ImportAirForm.vue";
import ImportSeaForm from "@/components/ImportSeaForm.vue";
import LayoutComponent from "@/components/LayoutComponent.vue";
import PageTitle from "@/components/PageTitle.vue";

import { rawGet, transportServices, userServices } from "@/services";
import { userAccess } from "@/utils/constants";
import { getUserId } from "@/utils/helpers";

const types = ["LCL", "FCL", "Consol", "Air"];
const directions = ["Import", "Export"];

const router = useRouter();

const initialDataSubmitted = ref(false);
const initialData = ref({
  direction: "",
  type: "",
  client: {},
  house: "",
  master: ""
})
const selectedClient = ref({});
const selectedTransactionCategory = ref({});
const selectedTransaction = ref({});
const houseTimer = ref(null);
const masterTimer = ref(null);
const userName = ref(null);
const userGroups = ref([])

const isButtonDisabled = computed(() => {
  return JSON.stringify(selectedTransaction.value) === "{}"
    || selectedTransactionCategory.value.category !== initialData.value.type
    || selectedTransaction.value.type !== initialData.value.direction.toLocaleLowerCase()
})

watch(
  () => ({...initialData.value}),
  async (val, oldVal) => {
    const { type, house, master } = val
    if (
      type === ""
      || house === ""
      || master === ""
    ) {
      selectedTransaction.value = {}
      selectedClient.value = {}

      return
    } else if (
      type === oldVal.type
      && house === oldVal.house
      && master === oldVal.master

    ) {
      return
    }

    // get transaction details
    let transaction
    const params = {
      is_active: true
    }
    if (type === "Air") {
      params.house_airway_bill_number = house
      params.master_bill_of_lading = master
      
      transaction = await transportServices.getAirList({ params })
    } else {
      params.house_bill_of_lading = house
      params.master_airway_bill_number = master
      
      transaction = await transportServices.getSeaList({ params })
    }

    // get transaction category
    if (transaction?.count) {
      selectedTransaction.value = transaction.results[0]

      if (!selectedTransaction.value?.category) {
        selectedTransactionCategory.value = {};
      } else {
        selectedTransactionCategory.value = await rawGet({
          url: selectedTransaction.value.category
        })
      }

      selectedClient.value = await rawGet({
        url: selectedTransaction.value.consignee
      })
    } else {
      selectedTransaction.value = {}
    }
  },
  { deep: true },
)

const onChangeSelectedDirection = (direction) => {
  initialData.value.direction = direction;
}

const onChangeSelectedType = (type) => {
  initialData.value.type = type;
}

const onChangeHouse = (event) => {
  clearTimeout(houseTimer.value);

  houseTimer.value = setTimeout(() => {
    initialData.value.house = event.target.value
  }, 500)
}

const onChangeMaster = (event) => {
  clearTimeout(masterTimer.value);

  masterTimer.value = setTimeout(() => {
    initialData.value.master = event.target.value
  }, 500)
}

const handleOnClickNext = () => {
  initialDataSubmitted.value = true;
}

onMounted(async () => {
  const userId = getUserId()
  const resp = await userServices.getDetails(userId)
  userName.value = resp.last_name

  userGroups.value = resp.groups
  if (!resp.groups.some(role => userAccess.hasAccessToBrokerageModify.includes(role))) {
    router.go(-1)
  }
})
</script>

<style scoped>
</style>
