<template>
  <LayoutComponent
    :user-name="userName"
    :user-groups="userGroups"
  >
    <div class="container">
      <PageTitle title="Payee - Edit" />
      <PayeeForm mode="edit" :payee="payee" :agent="agent" :bank="bank" />
    </div>
  </LayoutComponent>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

import LayoutComponent from "@/components/LayoutComponent.vue";
import PageTitle from "@/components/PageTitle.vue";
import PayeeForm from "@/components/PayeeForm.vue";

import { payeeServices, rawGet, userServices } from "@/services";
import { userAccess } from "@/utils/constants";
import { getUserId } from "@/utils/helpers";

const route = useRoute()
const router = useRouter();
const payee = ref({})
const agent = ref({})
const bank = ref({})
const userName = ref(null);
const userGroups = ref([])

onMounted(async () => {
  const userId = getUserId()
  const resp = await userServices.getDetails(userId)
  userName.value = resp.last_name

  userGroups.value = resp.groups
  if (!resp.groups.some(role => userAccess.hasAccessToMemberModify.includes(role))) {
    router.go(-1)
  }
  const id = route.params.id

  payee.value = await payeeServices.getDetails({ id })
  const agentResp = await payeeServices.getAgentListByPayeeId({ id })
  agent.value = agentResp.results[0]
  const bank_url = agentResp.results[0]?.url + "banks/"
  const bankResp = await rawGet({ url: bank_url })
  bank.value = bankResp.results[0]
})
</script>
