<template>
  <LayoutComponent
    :user-name="userName"
    :user-groups="userGroups"
  >
    <div class="container">
      <PageTitle title="Amendment Requests - Dashboard" />
      <div class="py-3">
        <span class="fs-4 pe-3">Search</span>
        <div class="form-floating w-25 bl-search">
          <input
            type="text"
            class="form-control"
            id="BLSearch"
            placeholder="BL No."
          />
          <label for="BLSearch">BL No.</label>
        </div>
      </div>
      <table class="table table-light table-striped table-bordered mt-4">
        <thead>
          <tr>
            <th scope="col">Type</th>
            <th scope="col">MBL / MAWB</th>
            <th scope="col">HBL / HAWB</th>
            <th scope="col">Reference Number</th>
            <th scope="col">Status</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="amendment in amendments"
            :key="amendment.url"
            @click="toggleDetailModal"
          >
            <td>{{ getType(amendment) }}</td>
            <td scope="row">
              {{
                amendment.transaction.master_airway_bill_number
                || amendment.transaction.master_bill_of_lading
              }}
            </td>
            <td>
              {{
                amendment.transaction.house_airway_bill_number
                || amendment.transaction.house_bill_of_lading
              }}
            </td>
            <td>{{ amendment.transaction.registry_number }}</td>
            <td>{{ statusFormatter(amendment.status) }}</td>
            <td v-if="amendment.status === 'submitted'">
              <button
                type="button"
                class="btn button-primary me-2"
                @click="handleClickApprove(amendment)"
              >
                Approve
              </button>
              <button
                type="button"
                class="btn btn-danger"
                @click="handleClickReject(amendment)"
              >
                Reject
              </button>
            </td>
            <td v-else>
              -
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <ApprovalModal
      v-show="showApprovalModal"
      type="amendment"
      @cancel-modal="cancelApprovalModal"
      @confirm-modal="confirmApprovalModal"
    />
    <RejectionModal
      v-show="showRejectionModal"
      type="amendment"
      @cancel-modal="cancelRejectionModal"
      @confirm-modal="confirmRejectionModal"
    />
  </LayoutComponent>
</template>

<script setup>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";

import LayoutComponent from "@/components/LayoutComponent.vue";
import ApprovalModal from "@/components/ApprovalModal.vue";
import PageTitle from "@/components/PageTitle.vue";
import RejectionModal from "@/components/RejectionModal.vue";

import { amendmentServices, rawGet, rawPatch, userServices } from "@/services";
import { getUserId, statusFormatter } from "@/utils/helpers";

const router = useRouter();

const amendments = ref([]);
const showApprovalModal = ref(false);
const showRejectionModal = ref(false);
const targetTransaction = ref(null);
const userName = ref(null);
const userGroups = ref([])

const handleClickApprove = (transaction) => {
  showApprovalModal.value = !showApprovalModal.value;
  targetTransaction.value = transaction;
}

const confirmApprovalModal = async () => {
  await rawPatch({
    url: targetTransaction.value.url,
    data: {
      status: "approved"
    }
  })
  fetchList()
  showApprovalModal.value = !showApprovalModal.value;
}

const cancelApprovalModal = () => {
  showApprovalModal.value = !showApprovalModal.value;
  targetTransaction.value = null;
}

const handleClickReject = (transaction) => {
  showRejectionModal.value = !showRejectionModal.value;
  targetTransaction.value = transaction;
}

const confirmRejectionModal = async () => {
  await rawPatch({
    url: targetTransaction.value.url,
    data: {
      status: "rejected"
    }
  })
  fetchList()
  showRejectionModal.value = !showRejectionModal.value;
}

const cancelRejectionModal = () => {
  showRejectionModal.value = !showRejectionModal.value;
  targetTransaction.value = null;
}

const fetchList  = async () => {
  const amendmentResp = await amendmentServices.getList({
    params: "?is_active=true"
  });

  for (let index = 0; index < amendmentResp.length; index++) {
    const amendment = amendmentResp[index];
    amendment.transaction = await rawGet({ url: amendment.transaction })
  }

  amendments.value = amendmentResp || []
}
const getType = (amendment) => {
  if (amendment.transaction.url.includes("bills")) {
    return "BILLING"
  }

  const type = amendment.transaction.type === "import" ? "IMPORT" : "EXPORT"
  let transaction
  if (amendment.transaction.url.includes("air-transport-transactions")) {
    transaction = "AIR"
  } else if (amendment.transaction.url.includes("sea-transport-transactions")) {
    transaction = "SEA"
  } else if (amendment.transaction.url.includes("independent-air-brokerage-transactions")) {
    transaction = "IND-BROKERAGE-AIR"
  } else if (amendment.transaction.url.includes("independent-sea-brokerage-transactions")) {
    transaction = "IND-BROKERAGE-SEA"
  }
  
  return `${transaction}-${type}`
}

onMounted(async () => {
  const userId = getUserId()
  const resp = await userServices.getDetails(userId)
  userName.value = resp.last_name

  userGroups.value = resp.groups
  if (!resp.groups.includes("Admin")) {
    router.go(-1)
  }
  fetchList()
})
</script>

<style scoped>
.bl-search {
  display: inline-block;
}
</style>
