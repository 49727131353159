<template>
  <LayoutComponent
    :user-name="userName"
    :user-groups="userGroups"
  >
    <div class="container">
      <PageTitle title="Home" />
    </div>
  </LayoutComponent>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";

import LayoutComponent from "../components/LayoutComponent.vue";
import PageTitle from "@/components/PageTitle.vue";
import { getUserId } from "@/utils/helpers";
import { userServices } from "@/services";

const router = useRouter();
const userName = ref(null);
const userGroups = ref([])

onMounted(async () => {
  const userId = getUserId()
  const resp = await userServices.getDetails(userId)
  userName.value = resp.last_name

  userGroups.value = resp.groups
  if (!resp.groups.length) {
    router.go(-1)
  }
})
</script>

<style></style>
