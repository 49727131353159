<template>
  <ModalBackdrop @on-click-modal="cancelModal">
    <div class="rejection-modal container text-center" @click.stop="">
      <h3 class="mt-4">Are you sure you want to reject {{ props.type }}?</h3>
      <div class="d-flex justify-content-around my-4">
        <button
          type="button"
          class="btn button-primary me-3"
          @click="confirmModal"
        >
          Confirm
        </button>
        <button type="button" class="btn btn-light" @click="cancelModal">
          Cancel
        </button>
      </div>
    </div>
  </ModalBackdrop>
</template>

<script setup>
import { defineEmits, defineProps } from "vue";

import ModalBackdrop from "./ModalBackdrop.vue";

const props = defineProps([ "type" ]);
const emit = defineEmits(["cancelModal", "confirmModal"]);

const cancelModal = () => {
  emit("cancelModal");
};

const confirmModal = () => {
  emit("confirmModal");
};
</script>

<style scoped>
.rejection-modal {
  background-color: #adadad;
  position: fixed;
  bottom: 69%;
  left: 28%;
  width: 50%;
  border: 2px solid black;
}

.rejection-modal .btn {
  padding-left: 30px;
  padding-right: 30px;
}
</style>
