<template>
  <!-- <ErrorMessage
    v-show="hasError"
    @dismiss-alert="handleOnDismissalert"
  >
    {{ errorMessage }}
  </ErrorMessage> -->
  <form>
    <div class="mb-3">
      <label for="companyName" class="form-label">Company Name</label>
      <input
        type="text"
        class="form-control"
        :class="errors.companyName ? 'error' : ''"
        id="companyName"
        aria-label="Company Name"
        v-model="companyName"
      >
      <InputErrorLabel :show="errors.companyName" />
      <label for="address" class="form-label">Address</label>
      <input
        type="text"
        class="form-control"
        :class="errors.address ? 'error' : ''"
        id="address"
        aria-label="Address"
        v-model="address"
      >
      <InputErrorLabel :show="errors.address" />
      <label for="telephoneNumber" class="form-label">Telephone Number</label>
      <input
        type="text"
        class="form-control"
        :class="errors.telephoneNumber ? 'error' : ''"
        id="telephoneNumber"
        aria-label="Telephone Number"
        v-model="telephoneNumber"
      >
      <InputErrorLabel :show="errors.telephoneNumber" />
      <label for="tinNumber" class="form-label">Tin Number</label>
      <input
        type="text"
        class="form-control"
        :class="errors.tinNumber ? 'error' : ''"
        id="tinNumber"
        aria-label="Tin Number"
        v-model="tinNumber"
      >
      <InputErrorLabel :show="errors.tinNumber" />
      <label for="salesman" class="form-label">Salesman</label>
      <input
        type="text"
        class="form-control"
        :class="errors.salesman ? 'error' : ''"
        id="salesman"
        aria-label="Salesman"
        v-model="salesman"
      >
      <InputErrorLabel :show="errors.salesman" />
      <div class="d-flex align-items-center pt-4">
        <h5 class="me-3">Rates</h5>
        <button type="button" class="btn button-primary" @click="addRateRow">Add Rate</button>
      </div>
      <table class="table">
        <thead>
          <tr>
            <th scope="col">Mode Of Shipment</th>
            <th scope="col">Mode Of Shipment Details</th>
            <th scope="col">Rate</th>
            <th scope="col">Unit</th>
            <th scope="col">Taxable</th>
            <th scope="col">Action/s</th>
          </tr>
        </thead>
        <tbody>
          <template v-if="rateRows.length">
            <tr v-for="(rate, index) in rateRows" :key="index">
              <td>
                <select class="form-select" id="modeOfShipment" aria-label="Mode Of Shipment" v-model="rate.modeOfShipment">
                  <option v-for="mos in modeOfShipmentList" :value="mos.url" :key="mos.url">{{mos.code}}</option>
                </select>
              </td>
              <td>
                <textarea 
                  class="form-control"
                  id="description"
                  aria-label="Mode Of Shipment Details"
                  rows="3"
                  v-model="rate.description"
                ></textarea>
              </td>
              <td>
                <input type="number" class="form-control" id="rate" aria-label="Rate" v-model="rate.rate">
              </td>
              <td>
                <select class="form-select" id="unit" aria-label="Unit" v-model="rate.unit">
                  <option v-for="rateUnit in rateUnitsList" :value="rateUnit.url" :key="rateUnit.label">{{rateUnit.label}}</option>
                </select>
              </td>
              <td>
                <input type="checkbox" value="" class="form-check-input ms-2" id="taxType" aria-label="Taxable" v-model="rate.taxable">
              </td>
              <td>
                <button type="button" class="btn btn-danger" @click="deleteRateRow(index)">Delete</button>
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <button type="button" class="btn button-primary" @click="handleOnSubmit">Submit</button>
  </form>
</template>

<script setup>
import { defineProps, onMounted, ref, watch } from "vue";
import { useRouter } from 'vue-router';

// import ErrorMessage from "@/components/ErrorMessage.vue";
import InputErrorLabel from "@/components/InputErrorLabel.vue";

import { clientServices, rawPatch } from "@/services";
import { getIdFromURL } from "@/utils/helpers";

const props = defineProps(["client", "mode", "rates"]);
const router = useRouter();
const rateRows = ref([]);
const companyName = ref("");
const address = ref("");
const telephoneNumber = ref("");
const tinNumber = ref("");
const salesman = ref("");
const modeOfShipmentList = ref([]);
const rateUnitsList = ref([]);
const deletedRates = ref([]);

const errorMessage =ref("")
const errors = ref({})
const hasError = ref(false)

const addRateRow = () => {
  rateRows.value.push({
    id: 0,
    modeOfShipment: "",
    description: "",
    rate: 0,
    unit: "",
    taxable: false
  });
}

const deleteRateRow = (index) => {
  const tempRates = structuredClone(rateRows.value)
  const deletedRate = tempRates.splice(index, 1)

  if (deletedRate[0].url) {
    deletedRates.value.push(getIdFromURL(deletedRate[0].url))
  }
  rateRows.value = tempRates;
}

const validateData = () => {
  if (!companyName.value) {
    errors.value.companyName = "Invalid Company Name"
  }
  if (!address.value) {
    errors.value.address = "Invalid Address"
  }
  if (!telephoneNumber.value) {
    errors.value.telephoneNumber = "Invalid Telephone Number"
  }
  if (!tinNumber.value) {
    errors.value.tinNumber = "Invalid Tin Number"
  }
  if (!salesman.value) {
    errors.value.salesman = "Invalid Salesman"
  }

  return Object.keys(errors.value).length ? false : true
}

const handleOnSubmit = async () => {
  errorMessage.value = ""
  hasError.value = false
  errors.value = {}

  if (!validateData()) {
    errorMessage.value = "Missing vital information(s). Please check again"
    hasError.value = true
    return
  }

  let clientResponse;
  const data = {
    company_name: companyName.value?.toUpperCase() || "",
    address: address.value?.toUpperCase() || "",
    telephone_number: telephoneNumber.value?.toUpperCase() || "",
    tin_number: tinNumber.value?.toUpperCase() || "",
    salesman: salesman.value?.toUpperCase() || "",
  };

  // update or create client
  if (props.mode === "create") {
    clientResponse = await clientServices.create({ data })
  } else {
    clientResponse = await rawPatch({
      url: props.client.url,
      data,
    })
  }

  // update or create client rates
  rateRows.value.forEach(rate => {
    if (rate.id === 0) {
      clientServices.createClientRate({
        ...rate,
        description: rate.description?.toUpperCase() || "",
        client: clientResponse.url
      })
    } else {
      // TODO FE: optimize, do not patch all, just the updated ones
      rawPatch({
        url: rate.url,
        data: {
          ...rate,
          description: rate.description?.toUpperCase() || "",
          mode_of_shipment: rate.modeOfShipment,
          is_taxable: rate.taxable
        }
      })
    }
  })

  // delete rates
  deletedRates.value.forEach(rate => {
    if (props.mode !== "create") {
      clientServices.deleteClientRate({
        clientId: getIdFromURL(props.client.url),
        id: rate
      })
    }
  })

  router.push("/members")
}

// const handleOnDismissalert = () => {
//   hasError.value = false;
// }

watch(
  () => props.rates,
  (rates) => {
    rateRows.value = rates
  }
)

watch(
  () => props.client,
  (client) => {
    if (props.mode === "edit") {
      companyName.value = client.company_name;
      address.value = client.address;
      telephoneNumber.value = client.telephone_number;
      tinNumber.value = client.tin_number;
      salesman.value = client.salesman;
    }
  }
)

onMounted(async () => {
  const modeOfShipmentResponse = await clientServices.getModeOfShipments();
  modeOfShipmentList.value = modeOfShipmentResponse;

  const rateUnitsResponse = await clientServices.getRateUnits();
  rateUnitsList.value = rateUnitsResponse;
})
</script>

<style scoped>
input {
  text-transform: uppercase;
}

textarea {
  text-transform: uppercase;
}

.error {
  border-color: red;
}
</style>
