<template>
  <div class="backdrop-container" @click="handleOnClickModal">
    <slot></slot>
  </div>
</template>

<script setup>
import { defineEmits } from "vue";

const emit = defineEmits(["onClickModal"]);

const handleOnClickModal = () => {
  emit("onClickModal");
};
</script>

<style scoped>
.backdrop-container {
  height: 100vh;
  width: 100vw;
  background-color: rgba(222, 222, 222, .75);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;
}
</style>
