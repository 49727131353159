<template>
  <LayoutComponent
    :user-name="userName"
    :user-groups="userGroups"
  >
    <div class="container">
      <PageTitle title="Billing - Edit" />
      <BillingAirForm
        v-if="medium === 'air'"
        :billing="billing"
        :category="category"
        :is-view="false"
        :mode-of-shipment="modeOfShipment"
        :rates="rates"
        :transaction="transaction"
      />
      <BillingSeaForm
        v-else-if="medium === 'sea'"
        :billing="billing"
        :category="category"
        :is-view="false"
        :mode-of-shipment="modeOfShipment"
        :rates="rates"
        :transaction="transaction"
      />
    </div>
  </LayoutComponent>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

import BillingAirForm from "@/components/BillingAirForm.vue";
import BillingSeaForm from "@/components/BillingSeaForm.vue";
import LayoutComponent from "@/components/LayoutComponent.vue";
import PageTitle from "@/components/PageTitle.vue";

import { billServices, clientServices, rawGet, userServices } from "@/services";
import { userAccess } from "@/utils/constants";
import { fetchAll, getIdFromURL, getUserId } from "@/utils/helpers";

const route = useRoute()
const router = useRouter();

const billing = ref()
const transaction = ref()
const category = ref('')
const medium = ref('')
const rates = ref([]);
const modeOfShipment = ref('');
const userName = ref(null);
const userGroups = ref([])

onMounted(async () => {
  const userId = getUserId()
  const resp = await userServices.getDetails(userId)
  userName.value = resp.last_name

  userGroups.value = resp.groups
  if (!resp.groups.some(role => userAccess.hasAccessToBilling.includes(role))) {
    router.go(-1)
  }
  const id = route.params.id;
  let tempRates
  const billingResp  = await billServices.getDetails({ id })
  const transactionResp = await rawGet({ url: billingResp.transaction })
  const categoryResp = await rawGet({ url: transactionResp.category })
  const modeResp = await clientServices.getModeOfShipments()
  const type = transactionResp.type === 'import' ? 'IM' : 'EX'

  if (
    new Date(transactionResp.datetime_created).getTime() !==
    new Date(transactionResp.datetime_updated).getTime()
  ) {
    tempRates = await billServices.getRates({ id })
  } else {
    const clientId = getIdFromURL(transactionResp.consignee)
    tempRates = await clientServices.getRates({ id: clientId })
  }
  if (tempRates.next) {
    const nextRates = await fetchAll(tempRates.next)
    tempRates.results = [...tempRates.results, ...nextRates]
  }

  billing.value = billingResp
  transaction.value = transactionResp
  category.value = categoryResp.category
  medium.value = categoryResp.medium
  modeOfShipment.value = modeResp.filter(mode => {
    return mode.code === `${type}-${categoryResp.category.toLocaleUpperCase()}`
  })[0].url
  rates.value = tempRates.results.filter(
    rate => rate.mode_of_shipment === modeOfShipment.value
  )
})

</script>

<style scoped>
</style>
