<template>
  <LayoutComponent
    :user-name="userName"
    :user-groups="userGroups"
  >
    <div class="container">
      <PageTitle title="Client - Create" />
      <ClientForm :rates="rates" mode="create" />
    </div>
  </LayoutComponent>
</template>

<script setup>
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";

import ClientForm from "@/components/ClientForm.vue";
import LayoutComponent from "@/components/LayoutComponent.vue";
import PageTitle from "@/components/PageTitle.vue";

import { userAccess } from "@/utils/constants";
import { userServices } from "@/services";
import { getUserId } from "@/utils/helpers";

const router = useRouter();

const rates = [{
  id: 0,
  modeOfShipment: "",
  description: "",
  rate: 0,
  unit: "",
  taxable: false
}]
const userName = ref(null);
const userGroups = ref([])

onMounted(async() => {
  const userId = getUserId()
  const resp = await userServices.getDetails(userId)
  userName.value = resp.last_name

  userGroups.value = resp.groups
  if (!resp.groups.some(role => userAccess.hasAccessToMemberModify.includes(role))) {
    router.go(-1)
  }
})
</script>

<style scoped>
</style>
