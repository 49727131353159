import { baseAuth } from "./baseRequests";

// TODO: throw error instead of logging
// handle the error in the pages
export const AuthServices = {
  login: async ({ username, password }) => {
    const clientID = process.env.VUE_APP_CLIENT_ID;
    const data = {
      client_id: clientID,
      grant_type: "password",
      username,
      password,
    };

    const resp = await baseAuth({ url: "token/", data });

    document.cookie = `BearerToken=${resp.data.access_token}; path=/;`;
    document.cookie = `RefreshToken=${resp.data.refresh_token}; path=/;`;
    document.cookie = `User=${resp.data.user}; path=/;`;
  },
};
